import React, { useEffect } from 'react';
import { useParams, Link, Outlet } from 'react-router-dom'; 
import { useWorkshop } from '../context/WorkshopContext'; // Import the context for workshops

// Import the components
import WorkshopSidebar from '../component/sidebar/WorkshopSidebar'; // Sidebar for workshop
import Topbar from '../component/utils/Topbar';

const WorkshopDashboard = () => {
  const { workshop_unique_id } = useParams(); // Get the workshop ID from the URL
  const { setWorkshopId } = useWorkshop(); // Access the setWorkshopId function from the context

  // Update the workshopId in context when the component mounts or URL changes
  useEffect(() => {
    if (workshop_unique_id) {
      setWorkshopId(workshop_unique_id); // Set the workshopId in the context
    }
  }, [workshop_unique_id, setWorkshopId]);
  
  return (
    <div>
        <WorkshopSidebar /> 

        <div className="content" style={{ flexGrow: 1, padding: "20px" }}>
          <Topbar /> {/* Topbar for the workshop dashboard */}
          <Outlet /> {/* Render nested routes for the workshop dashboard */}
        </div>
    </div>
  );
};

export default WorkshopDashboard;
