import React from "react"; 
import logo from "../../component/img/logo.png";
const BadgeTwo = () => {
  return (
    <>
    <div className="row justify-content-center align-items-center m-0">
      <div className="badge-two-container ">
      {/* Header Section */}
      <div className="badge-two-header">
        <h1 className="event-title">COSMOPROF</h1>
        <p className="event-location">INDIA MUMBAI</p>
      </div>
      <hr className="my-2"/>

      {/* Event Details */}
      <div className="badge-two-event-details">
        <p className="event-dates">5 - 7 DECEMBER 2024, MUMBAI</p>
        <p className="event-venue">JIO WORLD CONVENTION CENTRE</p>
      </div>
      <hr />
      {/* Organizer Section */}
      <div className="badge-two-organizers">
        <p className="organizer-label">ORGANISED BY</p>
        <div className="organizers-list">
         
          <img
            src={logo}
            alt="Informa Markets"
            className="organizer-logo"
          />
          <img
            src={logo}
            alt="King Vish"
            className="organizer-logo"
          />
        </div>
      </div>

      {/* Participant Details */}
      <div className="badge-two-participant">
        <h2 className="participant-name">APARNA S</h2>
        <p className="participant-industry">LIFESTYLE</p>
        <p className="participant-title">DIRECTOR</p>
      </div>

      {/* QR Code Section */}
      <div className="badge-two-qr-section">
        <img
          src="https://via.placeholder.com/100x100" // Replace with actual QR code
          alt="QR Code"
          className="qr-code"
        />
        <p className="qr-code-id">CPVI5599</p>
      </div>

      {/* Footer Section */}
      <div className="badge-two-footer">
        <p className="badge-role">VISITOR</p>
      </div>
    </div>
    </div>
    </>
  );
};

export default BadgeTwo;
