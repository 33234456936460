import React, { useState, useEffect } from "react";
import { useConference } from "../../context/ConferenceContext"; // Import the conference context hook
import { useNavigate } from "react-router-dom"; // Import navigate hook
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConferenceContentNavbar from "../utils/ConferenceContentNavbar";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone"; // Import Dropzone for drag-and-drop
import axios from "axios"; // Import axios
import { baseurl, imageUrl } from "../../component/Baseurl";
import { ArrowRight, ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";

const ConferenceImageDetails = () => {
  // Destructure the context to get the conference ID
  const { conferenceId } = useConference();
  const navigate = useNavigate(); // For navigation after submission

  const org_id = localStorage.getItem("orgId");

  const [conferenceBanner, setConferenceBanner] = useState(null);
  const [conferenceFullBanner, setConferenceFullBanner] = useState(null);
  const [fetchedBanner, setFetchedBanner] = useState(null); // State for fetched banner
  const [fetchedFullBanner, setFetchedFullBanner] = useState(null); // State for fetched full banner

  // Fetch conference file details on component mount
  useEffect(() => {
    const fetchConferenceFileDetails = async () => {
      try {
        // Send the conferenceId in the URL path
        const response = await axios.get(`${baseurl}/getConferenceFileDetails/${conferenceId}`);
  
        // Log the response to the console
        console.log("Response from API:", response);
  
        if (response.data) {
          const { eventbanner, eventfullbanner } = response.data;
          setFetchedBanner(eventbanner);  // Set fetched banner
          setFetchedFullBanner(eventfullbanner);  // Set fetched full banner
        }
      } catch (error) {
        console.error("Error fetching conference file details:", error);
        // toast.error("Failed to fetch file details.");
      }
    };
  
    if (conferenceId) {
      fetchConferenceFileDetails();
    }
  }, [conferenceId]);
  
  

  // Handle file drop for conference banners
  const onDropConferenceBanner = (acceptedFiles) => {
    // Check if there are files in the acceptedFiles array
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0]; // Get the first file (in case of multiple files)
      if (file.size > 500000) {
        // Show toastr error message if file size exceeds 500KB
        toast.error("Conference Banner file size exceeds 500KB!");
      } else {
        setConferenceBanner(file); // Set the file if it's within the size limit
      }
    }
  };

  // Handle file drop for conference full banners
  const onDropConferenceFullBanner = (acceptedFiles) => {
    // Check if there are files in the acceptedFiles array
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0]; // Get the first file (in case of multiple files)
      if (file.size > 500000) {
        // Show toastr error message if file size exceeds 500KB
        toast.error("Conference Full Banner file size exceeds 500KB!");
      } else {
        setConferenceFullBanner(file); // Set the file if it's within the size limit
      }
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Ensure at least one banner is uploaded before proceeding
    if (!conferenceBanner && !conferenceFullBanner) {
      toast.error("Please upload at least one banner.");
      return;
    }
  
    // Create FormData to hold the files and other data
    const formData = new FormData();
    if (conferenceBanner) formData.append("conferenceBanner", conferenceBanner);
    if (conferenceFullBanner) formData.append("conferenceFullBanner", conferenceFullBanner);
    formData.append("conferenceId", conferenceId); // Ensure you have this variable from your form/state
    formData.append("orgId", org_id); // Same here for org_id
  
    // Log the FormData content
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  
    try {
      // Make the API call to upload banners
      const response = await axios.post(`${baseurl}/insertConferenceFileDetails`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure correct content type for file uploads
        },
      });
  
      // Handle the response from the server
      if (response.status === 200) {

        navigate(`/conference-dashboard/${conferenceId}/conference-speaker-details`);


        // Swal.fire("Success", "Banners uploaded successfully!", "success");
        // You can redirect or do something else here after successful upload
      } else {
        toast.error("Failed to upload banners.");
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error during API call:", error);
      toast.error("Something went wrong. Please try again.");
    }
  };
  

  // Drag-and-drop configuration for banners
  const { getRootProps: getRootPropsConferenceBanner, getInputProps: getInputPropsConferenceBanner } = useDropzone({
    onDrop: onDropConferenceBanner,
    accept: "image/*",
    maxSize: 500000,  // Limit the file size to 500KB
  });

  const { getRootProps: getRootPropsConferenceFullBanner, getInputProps: getInputPropsConferenceFullBanner } = useDropzone({
    onDrop: onDropConferenceFullBanner,
    accept: "image/*",
    maxSize: 500000,  // Limit the file size to 500KB
  });

  return (
    <div>
      <ConferenceContentNavbar />
      <h1 className="mt-3">Upload Conference Banners</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-4 shadow-lg rounded-lg">
            <form onSubmit={handleSubmit}>
              <div className="row g-3">
                {/* Conference Banner Upload */}
                <div className="col-md-6">
                  <label className="form-label">
                    Conference Banner <small className="udrl">[Max Size : 500kb, File Type : JPEG, JPG, WEBP, PNG ONLY]</small><span className="text-danger">*</span>
                  </label>
                  <div
                    {...getRootPropsConferenceBanner()}
                    className="dropzone-container border border-primary p-3 rounded-lg"
                  >
                    <input {...getInputPropsConferenceBanner()} />
                    <p className="text-center text-muted">
                      Drag & drop your banner here, or click to select
                    </p>
                    {conferenceBanner && (
  <div className="mt-2 text-center">
    <img
      src={URL.createObjectURL(conferenceBanner)}
      alt="Conference Banner"
      style={{ width: "200px", borderRadius: "8px" }}
    />
  </div>
)}
                    {fetchedBanner && !conferenceBanner && (
  <div className="mt-2 text-center">
    <img
      src={`${imageUrl}conferenceFiles/${fetchedBanner}`}
      alt="Fetched Conference Banner"
      style={{ width: "200px", borderRadius: "8px" }}
    />
  </div>
)}
                  </div>
                </div>

                {/* Conference Full Banner Upload */}
                <div className="col-md-12">
                  <label className="form-label">
                    Conference Full Banner <small className="udrl">[Max Size : 500kb, File Type : JPEG, JPG, WEBP, PNG ONLY]</small><span className="text-danger">*</span>
                  </label>
                  <div
                    {...getRootPropsConferenceFullBanner()}
                    className="dropzone-container border border-primary p-3 rounded-lg"
                  >
                    <input {...getInputPropsConferenceFullBanner()} />
                    <p className="text-center text-muted">
                      Drag & drop your full banner here, or click to select
                    </p>
                    {conferenceFullBanner && (
  <div className="mt-2 text-center">
    <img
      src={URL.createObjectURL(conferenceFullBanner)}
      alt="Conference Full Banner"
      style={{ width: "200px", borderRadius: "8px" }}
    />
  </div>
)}
{fetchedFullBanner && !conferenceFullBanner && (
  <div className="mt-2 text-center">
    <img
      src={`${imageUrl}conferenceFiles/${fetchedFullBanner}`}
      alt="Fetched Full Conference Banner"
      style={{ width: "200px", borderRadius: "8px" }}
    />
  </div>
)}
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3 text-end">
                {/* <button type="submit" className="btnn">
                  Submit
                </button> */}



                
              </div>


<div className="col-md-12 mt-5 d-flex justify-content-between align-items-center">
                <span className="">
                  <Link
                    to={`/conference-dashboard/${conferenceId}/conference-time-details`}
                    className="btnn mybtn"
                  >
                    <ArrowLeft className="ic text-white" />
                    Back
                  </Link>
                </span>
                {!fetchedFullBanner && !fetchedBanner ? (
                                  <button type="submit" className="btnn mt-0">
                                    Next <ArrowRight className="ic text-white" />
                                  </button>
                                ) : (
                                  <Link
                                    to={`/conference-dashboard/${conferenceId}/conference-speaker-details`}
                                    className="btnn mybtn"
                                  >
                                    Next <ArrowRight className="ic text-white" />
                                  </Link>
                                )}
              </div>



            </form>
          </div>
        </div>
      </div>

      <ToastContainer /> {/* Toast container for notifications */}
    </div>
  );
};

export default ConferenceImageDetails;
