import React, { useState, useEffect } from "react"; 
import { useExhibition } from "../../context/ExhibitionContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";  
import axios from "axios";  
import { baseurl } from "../../component/Baseurl";

const AddExhibitionSession = () => {
    // const { conferenceId } = useConference();
  const { exhibitionId } = useExhibition();
    // State for form fields
    const [sessionDetails, setSessionDetails] = useState({
      event_date: "",
      session_name: "",
    });
  
    // State for date range dropdown
    const [dateRange, setDateRange] = useState([]);
  
    const navigate = useNavigate();
    const handleGoBack = () => {
      navigate(-1); // Go back to the previous page in history
    };


    // Fetch conference dates and populate the dropdown
    const fetchConferenceDates = async () => {
      if (!exhibitionId) {
        // console.error("Conference ID is null or undefined.");
        return;
      }
  
      try {
        const response = await axios.get(`${baseurl}/getExhibitionDatesconf/${exhibitionId}`);
  
        // console.log("Full Response Object:", response);
        // console.log("Response Data:", response.data);
  
        const { conference_start_date, conference_end_date } = response.data;
  
        const startDate = new Date(conference_start_date);
        const endDate = new Date(conference_end_date);
        const range = [];
  
        while (startDate <= endDate) {
          range.push(new Date(startDate).toISOString().split("T")[0]); // Format as YYYY-MM-DD
          startDate.setDate(startDate.getDate() + 1);
        }
  
        setDateRange(range);
      } catch (error) {
        console.error("Error fetching conference dates:", error);
        toast.error("Failed to fetch conference dates.");
      }
    };
  
    // Fetch conference dates when conferenceId changes
    useEffect(() => {
    //   console.log("Conference ID:", exhibitionId); 
      if (exhibitionId) {
        fetchConferenceDates();
      }
    }, [exhibitionId]);
  
    // Handle input changes
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setSessionDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    };
  
    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const data = {
            eventDate: sessionDetails.event_date,
            sessionName: sessionDetails.session_name,
          eventId: exhibitionId,
          pretable: "Exhibition"
        };
    
        // console.log("Data Sent:", data);
    
        try {
          const response = await axios.post(`${baseurl}insertSessionDetails`, data);
    
          if (response.data.success) {
            Swal.fire({
              title: "Success",
              text: "The session has been added successfully.",
              icon: "success",
            });
            toast.success("Session added successfully!");
    
            setSessionDetails({
              event_date: "",
              session_name: "",
            });
    // Reload the page after success
    window.location.reload(); 
          } else {
            toast.error(`Failed to add session: ${response.data.error || "Unknown error"}`);
          }
        } catch (error) {
          console.error("Error adding session:", error);
          toast.error("Something went wrong. Please try again later.");
        }
    
        // Close the modal
        window.bootstrap.Modal.getInstance(document.getElementById("addSessionModalconf")).hide();
      };

  // Fetch conference dates on component mount
  useEffect(() => {
    fetchConferenceDates();
  }, []);

  return (
    <div>
      {/* Button to open the modal */}
      <div className="d-flex justify-content-between">
        <span>
          <h1 className="mt-3">Session & Agenda</h1>
        </span>
        <span>

        <span className="btnn bg-danger" onClick={handleGoBack}>
          Go Back
        </span>


        <button
          type="button"
          className="btnn"
          data-bs-toggle="modal"
          data-bs-target="#addSessionModalconf"
        >
          Add Session
        </button>

        
        </span>
        
      </div>

      {/* Modal to add Session */}
      <div
        className="modal fade"
        id="addSessionModalconf"
        tabIndex="-1"
        aria-labelledby="addSessionModalconfLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addSessionModalconfLabel">
                Add Session
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="event_date">
                    Event Date<span className="text-danger">*</span>
                  </label>
                  <select
                    name="event_date"
                    className="form-control"
                    value={sessionDetails.event_date}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      Select Event Date
                    </option>
                    {dateRange.map((date) => (
                      <option key={date} value={date}>
                        {date}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="session_name">
                    Session Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="session_name"
                    className="form-control"
                    value={sessionDetails.session_name}
                    onChange={handleInputChange}
                    placeholder="Enter Session Name"
                    required
                  />
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btnn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default AddExhibitionSession;
