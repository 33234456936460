import React, { useState, useEffect } from "react";
import { baseurl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link } from "react-router-dom";
import { Edit, Trash2, UserPlus, Download } from "react-feather";
import { useExhibition } from "../../context/ExhibitionContext";
import * as XLSX from "xlsx"; // Import the xlsx library
import ExhibitionLeadsNavbar from "../utils/ExhibitionLeadsNavbar";

const ExhibitorLeads = () => {
  const [currentPage, setCurrentPage] = useState(1); 
  const [recordsPerPage, setRecordsPerPage] = useState(5);  
  const [formData, setFormData] = useState([]);  
  const [labels, setLabels] = useState([]);  
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows
  const [selectAll, setSelectAll] = useState(false); // Track "Select All" checkbox state
  const { exhibitionId } = useExhibition();

  // Handle change in records per page
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); 
  };

  // Fetch form data when component mounts
  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}fetchFormDataByEventId/${exhibitionId}/Exhibitor`
        );
        
        if (response.data.success) {
          const data = response.data.data;
          // Extract labels from the first submission's fields (assuming all have the same fields)
          const fieldLabels = data.length > 0 ? data[0].fields.map(field => field.label) : [];
          setLabels(fieldLabels);
  
          // Format the data for table rows
          setFormData(data.map((submission) => ({
            submission_id: submission.submission_id,
            submitted_at: submission.submitted_at,
            visitor_details: submission.visitor_details, // Add visitor_details to the data
            fields: submission.fields
          })));
        }
      } catch (error) {
        // console.error("Error fetching form data:", error);
      }
    };

    fetchFormData();
  }, [exhibitionId]);

  // Function to format the date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options); // "12 Jan, 2024"
  };

  // Function to handle row selection
  const handleRowSelection = (submissionId) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(submissionId)) {
        return prevSelectedRows.filter(id => id !== submissionId);
      } else {
        return [...prevSelectedRows, submissionId];
      }
    });
  };

  // Function to handle select all checkbox
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]); // Deselect all
    } else {
      const allIds = formData.map(submission => submission.submission_id);
      setSelectedRows(allIds); // Select all
    }
    setSelectAll(!selectAll);
  };

  // Function to download selected rows as Excel
  const downloadExcel = () => {
    if (selectedRows.length === 0) {
      alert("Please select at least one row to download.");
      return;
    }
  
    const filteredData = formData.filter(submission =>
      selectedRows.includes(submission.submission_id)
    );
  
    const wsData = filteredData.map(submission => {
      // Prepare the data for each row
      const row = [
        submission.visitor_details.name,       // Name
        submission.visitor_details.email,      // Email
        submission.visitor_details.phone,      // Phone
        submission.visitor_details.designation, // Designation
        ...submission.fields.map(field => field.field_value),  // Field values
        formatDate(submission.submitted_at)    // Add formatted date
      ];
      return row;
    });
  
    // Prepare the headers (labels)
    const wsHeader = [
      "Name", "Email", "Phone", "Designation",  // Add the new columns
      ...labels,  // The dynamic field labels
      "Date"      // Add 'Date' as the last header
    ];
  
    const ws = XLSX.utils.aoa_to_sheet([wsHeader, ...wsData]);
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Exhibitor Leads");
  
    // Trigger download
    XLSX.writeFile(wb, "Exhibitor_leads.xlsx");
  };
  

  return (
    <div>
      <ExhibitionLeadsNavbar />
      <div className="d-flex justify-content-between">
        <span>
          <h1 className="">Exhibitor Leads</h1>
        </span>
      </div>

      <div className="row mb-3">
        <div className="col-md-3">
          <div className="crd1 px-3">
            <div className="d-flex justify-content-between">
              <div>
                <UserPlus className="ic2 cl1" size={45} />
              </div>
              <div>
                <div className="txtd1">Total Leads</div>
                <div className="txtd2">{formData.length}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="row">
          <div className="crd">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="hd-1">Exhibitor Leads</div>
              
              <div className="d-flex align-items-center">
                <div
                  className="downloadbtn me-3"
                  onClick={downloadExcel} // Trigger the download function on click
                >
                  <Download className="text-dark" size={20} />{" "}
                  Download
                </div>
                <div>
                  <select
                    className="form-control"
                    value={recordsPerPage}
                    onChange={handleRecordsPerPageChange}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                  </select>
                </div>
              </div>
            </div>

            <hr />

            {/* Table Headers */}
            <div className="row text-start tblrowhd">
              <div className="col">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </div>
              <div className="col">Name</div>
              <div className="col">Email</div>
              <div className="col">Phone</div>
              <div className="col">Designation</div>
              {labels.map((label, index) => (
                <div key={index} className="col">
                  {label}
                </div>
              ))}
              <div className="col text-end">Date</div>
            </div>
            <hr />

            {/* Table Data */}
            {formData.length > 0 ? (
              formData.map((submission) => (
                <div key={submission.submission_id} className="row user-table-data tblrowtd text-start">
                  <div className="col">
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(submission.submission_id)}
                      onChange={() => handleRowSelection(submission.submission_id)}
                    />
                  </div>
                  <div className="col">{submission.visitor_details.name}</div>
                  <div className="col">{submission.visitor_details.email}</div>
                  <div className="col">{submission.visitor_details.phone}</div>
                  <div className="col">{submission.visitor_details.designation}</div>
                  {submission.fields.map((field, index) => (
                    <div key={index} className="col">{field.field_value}</div>
                  ))}
                  <div className="col text-end">
                    {formatDate(submission.submitted_at)} {/* Formatted date */}
                  </div>
                </div>
              ))
            ) : (
              <div className="row text-center">
                <div className="col">No leads available</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitorLeads;
