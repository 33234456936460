import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Camera, Home, Users, Grid, FilePlus, Lock, Calendar, ArrowLeftCircle, Mail, MessageCircle } from "react-feather";
import logo from '../img/logo.png';

const WorkshopSidebar = () => { 
  const { workshop_unique_id } = useParams();
  const location = useLocation();

  // Function to check if the current path is active
  const isActive = (path) => location.pathname === path;

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <div>
          {/* <img src={logo} className="img-fluid logo" alt="Evventoz Logo" /> */}
          <Link to="/dashboard">
            <div className="upgrade"><ArrowLeftCircle className="ic text-white" />Go To Dashboard</div>
          </Link>
        </div>
      </div>

      <ul className="sidebar-menu chaintop">
        <li className={isActive(`/workshop-dashboard/${workshop_unique_id}/workshop-dashboard-stat`) ? "active2" : ""}>
          <Link to={`/workshop-dashboard/${workshop_unique_id}/workshop-dashboard-stat`} className="kp">
            <Home className="ic" />
            <span>Workshop Dashboard</span>
          </Link>
        </li>
        <li className={isActive(`/workshop-dashboard/${workshop_unique_id}/workshop-basic-details`) ? "active2" : ""}>
          <Link to={`/workshop-dashboard/${workshop_unique_id}/workshop-basic-details`} className="kp">
            <Grid className="ic" />
            <span>Content / Overview</span>
          </Link>
        </li>
        <li className={isActive(`/workshop-dashboard/${workshop_unique_id}/workshop-delegate-leads`) ? "active2" : ""}>
          <Link to={`/workshop-dashboard/${workshop_unique_id}/workshop-delegate-leads`} className="kp">
            <Grid className="ic" />
            <span>Workshop Leads</span>
          </Link>
        </li>
        <li className={isActive(`/workshop-dashboard/${workshop_unique_id}/workshop-deligate-custom-form`) ? "active2" : ""}>
          <Link to={`/workshop-dashboard/${workshop_unique_id}/workshop-deligate-custom-form`} className="kp">
            <FilePlus className="ic" />
            <span>Registration & Tickets</span>
          </Link>
        </li>
        <li className={isActive(`/workshop-dashboard/${workshop_unique_id}/workshop-web-analytics`) ? "active2" : ""}>
          <Link to={`/workshop-dashboard/${workshop_unique_id}/workshop-web-analytics`} className="kp">
            <Lock className="ic" />
            <span>Reports & Analytics</span>
          </Link>
        </li>
        <li className={isActive(`/workshop-dashboard/${workshop_unique_id}/conference-rating`) ? "active2" : ""}>
          <Link to={`/workshop-dashboard/${workshop_unique_id}/conference-rating`} className="kp">
            <Calendar className="ic" />
            <span>Communication</span>
          </Link>
        </li>

        <div className="">
           
            <span className="keyname"><strong className="me-2">Marketing Tools</strong>
              <span className="badge bg-warning text-dark">Comming Soon</span>
            </span>
           
        </div>

        <li className={isActive(`/workshop-dashboard/${workshop_unique_id}/conference-my-event-calendar`) ? "active2" : ""}>
          <Link to={``} className="kp">
            <MessageCircle className="ic" />
            <span>Whatsapp Marketing</span>
          </Link>
        </li>

        <li className={isActive(`/workshop-dashboard/${workshop_unique_id}/conference-my-event-calendar`) ? "active2" : ""}>
          <Link to={``} className="kp">
            <Mail className="ic" />
            <span>Email Marketing</span>
          </Link>
        </li>
      </ul>

      <div className="footer1 p-2">
        <Link to="/our-pricing" target="_blank">
        <div className="upgrade">Upgrade To Gold</div>
        </Link>
        
      </div>
    </div>
  );
};

export default WorkshopSidebar;
