import React, { useEffect, useState } from "react";
import Topbar from "../component/utils/Topbar";
import LineChart from "../component/utils/LineChart";
import BarChart from "../component/utils/BarChart";
import PieChart from "../component/utils/PieChart";
import { Heart, UserPlus, Edit } from "react-feather";
import FooterTwo from "../component/utils/FooterTwo";
import { Link } from "react-router-dom";
import OrgpackageModal from "./OrgpackageModal";
import { baseurl } from "../component/Baseurl";
import axios from "axios";

const Home = () => {

  const orgid = localStorage.getItem("orgId");

  // console.log("orgid",orgid);
  
  const [counts, setCounts] = useState({
    exhibition_count: 0,
    conference_count: 0,
    event_query: 0,
  });

  useEffect(() => {
    // Check if orgid exists (not null or empty string)
    if (orgid && orgid.trim() !== "") {
      axios
        .get(`${baseurl}/getExhibitionAndConferenceCount/${orgid}`)
        .then((response) => {
          // console.log("API Response:", response);  
          setCounts(response.data.counts);
        })
        .catch((error) => {
          console.error("Error fetching counts:", error);
        });
    } else {
      console.log("No valid orgid found, skipping API call.");
    }
  }, [orgid]);

  return (
    <>
      <div>
        <Topbar />


        <OrgpackageModal/>

        <div className="text-start">
          <h1 className="mt-3">Main Dashboard</h1>
          <p className="mb-2 text-start">Welcome to the Dashboard.</p>
        </div>

        <div className="">
          <div className="row mb-3">
            <div className="col-md-3 ">
              <Link to="/all-event" className="crd1 px-3 tooltip-container w-100"> 
        <div className="d-flex justify-content-between">
          <div>
            <UserPlus className="ic2 cl1" size={45} />
          </div>
          <div>
            <div className="txtd1">My Exhibition</div>
            <div className="txtd2">{counts.exhibition_count}</div>
          </div>
        </div>
        {/* Tooltip text */}
        <span className="tooltip-text">This is your exhibition count</span>
      </Link> 
            </div>




            <div className="col-md-3 ">
              <Link to="/all-event#conference" className="crd1 px-3 tooltip-container w-100"> 
        <div className="d-flex justify-content-between">
          <div>
            <UserPlus className="ic2 cl2" size={45} />
          </div>
          <div>
            <div className="txtd1">My Conference</div>
            <div className="txtd2">{counts.conference_count}</div>
          </div>
        </div>
        {/* Tooltip text */}
        <span className="tooltip-text">This is your Conference count</span>
      </Link> 
            </div>



            <div className="col-md-3 ">
              <Link to="/" className="crd1 px-3 tooltip-container w-100"> 
        <div className="d-flex justify-content-between">
          <div>
            <UserPlus className="ic2 cl3" size={45} />
          </div>
          <div>
            <div className="txtd1">Queries & Request</div>
            <div className="txtd2">{counts.event_query}</div>
          </div>
        </div>
        {/* Tooltip text */}
        <span className="tooltip-text">This is your Queries & Request count</span>
      </Link> 
            </div>



  

            {/* <div className="col-md-3">
              <div className="crd1 px-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <UserPlus className="ic2 cl4" size={45} />
                  </div>
                  <div>
                    <div className="txtd1">Users</div>
                    <div className="txtd2">89</div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="row g-3 mb-3">
            <div className="col-md-12">
              <div className=" crd1">
                <LineChart orgid={orgid} />
              </div>
            </div>

            <div className="col-md-4">
              <div className="crd1">
                <PieChart  orgid={orgid} />
              </div>
            </div>

         

            <div className="col-md-8">
              <div className="crd1">
                <BarChart />
              </div>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-md-9">
              <div className="crd1">
                <div className="hd-1">System Users List</div>

                <hr />

                {/* Table Headers */}
                <div className="row text-start tblrowhd m-0">
                  <div className="col">Name</div>
                  <div className="col">Email</div>
                  <div className="col">Department</div>
                  <div className="col">Designation</div>
                  <div className="col">User Role</div>

                  <div className="col text-end">Action</div>
                </div>
                <hr />

                <div className="row user-table-data tblrowtd text-start m-0">
                  <div className="col">Kunal Gupta</div>
                  <div className="col">test@email.com</div>
                  <div className="col">teastea</div>
                  <div className="col">Test Data</div>
                  <div className="col">test gags</div>
                  <div className="col text-end">
                    <Link to="/" className="editbg">
                      <Edit className="ic1" />
                    </Link>
                  </div>
                </div>
 
                 
              </div>
            </div> 

          </div>
        </div>

        <FooterTwo />
      </div>
    </>
  );
};

export default Home;
