import React, { useState } from "react";
import { useExhibition } from "../../context/ExhibitionContext"; // Import the context hook
import { useNavigate } from 'react-router-dom'; // Import navigate hook
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2'; // Import SweetAlert2 for the confirmation dialog
import axios from 'axios'; // Import axios for API calls
import { baseurl } from "../../component/Baseurl";

const AddSpeaker = () => {
  const { exhibitionId } = useExhibition(); // Assuming exhibitionId is available in context
  const navigate = useNavigate(); // For navigation after submission

  // State for form fields
  const [speakerDetails, setSpeakerDetails] = useState({
    speakerName: "",
    designation: "",
    // company: "",
    email: "",
    // phone: "",
    profileImage: null, // To store the selected profile image
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSpeakerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Handle file input (Profile Image)
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSpeakerDetails((prevDetails) => ({
      ...prevDetails,
      profileImage: file,
    }));
  };

  // Handle form submission with axios
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append("speakerName", speakerDetails.speakerName);
    formData.append("designation", speakerDetails.designation);
    // formData.append("company", speakerDetails.company);
    formData.append("email", speakerDetails.email);
    // formData.append("phone", speakerDetails.phone);
    if (speakerDetails.profileImage) {
      formData.append("profileImage", speakerDetails.profileImage);
    }
    formData.append("eventId", exhibitionId);
    formData.append("pretable", "Exhibition");
  
    // Log the data to be sent
    console.log("FormData entries:");
    for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  
    try {
      const response = await axios.post(`${baseurl}insertSpeakerDetails`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set proper header for form-data
        },
      });
  
      // Log the response from the API
      console.log("API Response:", response);
  
      // Check if the response is successful
      if (response.data.success) {
        Swal.fire({
          title: "Success",
          text: "The speaker has been added successfully.",
          icon: "success",
        });
        toast.success("Speaker added successfully!");
  
        // Reset the form
        setSpeakerDetails({
          speakerName: "",
          designation: "",
          company: "",
          email: "",
          phone: "",
          profileImage: null,
        });
        window.location.reload();
        // Navigate to speaker details page or another route if necessary

        // navigate(`/exhibition-dashboard/${exhibitionId}/speaker-details`);
      } else {
        toast.error(`Failed to add speaker: ${response.data.error || 'Unknown error'}`);
      }
    } catch (error) {
      console.error("Error adding speaker:", error);
      toast.error("Something went wrong. Please try again later.");
    }
  
    // Close the modal after form submission
    window.bootstrap.Modal.getInstance(document.getElementById('addExhibitorModal')).hide();
  };

  // Preview profile image
  const renderImagePreview = () => {
    if (speakerDetails.profileImage) {
      return (
        <div className="mt-3">
          <img
            src={URL.createObjectURL(speakerDetails.profileImage)}
            alt="Profile Preview"
            className="img-fluid"
            style={{ maxWidth: '150px' }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {/* Button to open the modal */}
      <div className="d-flex justify-content-between">
        <span><h1 className="mt-3">Speaker List</h1></span>
        <button
          type="button"
          className="btnn mt-0"
          data-bs-toggle="modal"
          data-bs-target="#addExhibitorModal"
        >
          Add Speaker
        </button>
      </div>

      {/* Modal to add Speaker */}
      <div
        className="modal fade"
        id="addExhibitorModal"
        tabIndex="-1"
        aria-labelledby="addExhibitorModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addExhibitorModalLabel">Add Speaker</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="speakerName">Speaker Name<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="speakerName"
                    className="form-control"
                    value={speakerDetails.speakerName}
                    onChange={handleInputChange}
                    placeholder="Enter Speaker Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="designation">Speaker Title<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="designation"
                    className="form-control"
                    value={speakerDetails.designation}
                    onChange={handleInputChange}
                    placeholder="Enter Speaker Title"
                    required
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="company">Company<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="company"
                    className="form-control"
                    value={speakerDetails.company}
                    onChange={handleInputChange}
                    placeholder="Enter Company Name"
                    required
                  />
                </div> */}
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={speakerDetails.email}
                    onChange={handleInputChange}
                    placeholder="Enter Email Address (Optional)" 
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    className="form-control"
                    value={speakerDetails.phone}
                    onChange={handleInputChange}
                    placeholder="Enter Phone Number"
                  />
                </div> */}
                <div className="form-group">
                  <label htmlFor="profileImage">Speaker Profile</label>
                  <input
                    type="file"
                    name="profileImage"
                    className="form-control"
                    onChange={handleFileChange}
                  />
                  {renderImagePreview()} {/* Display profile image preview */}
                </div>
                <div className="modal-footer">
                  {/* <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button> */}
                  <button type="submit" className="btnn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default AddSpeaker;
