import React, { useEffect, useState } from "react";
import axios from "axios"; // Import Axios
import { baseurl } from "../Baseurl";

const PieChart = ({ orgid }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [visitorData, setVisitorData] = useState([]);

  // Fetch visitor count data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}getVisitorCountbyOrgId/${orgid}`);
        // console.log("API Response:", response.data); 
        setVisitorData(response.data.visitorCounts); // Set visitor data from API
        setIsLoaded(true);
      } catch (error) {
        console.error("Failed to fetch visitor data:", error);
      }
    };

    fetchData();
  }, [orgid]);

  useEffect(() => {
    if (isLoaded && window.JSC && visitorData.length > 0) {
      // Debug: Log the visitor data being processed
      // console.log("Processed Visitor Data:", visitorData);

      // Map the data to points suitable for the donut chart
      const points = visitorData.map((data) => ({
        name: data.exhibition_name,  // Set event name as label
        y: parseInt(data.count),     // Set visitor count as value
        color: "#" + ((1 << 24) * Math.random() | 0).toString(16),  // Ensuring valid color format
      }));

      // Debug: Log the points before rendering the chart
      // console.log("Chart Points:", points);

      // Render donut chart
      window.JSC.chart("pie-chart-container", {
        type: "donut", // Set the chart type to 'donut'
        title_label_text: "Visitors by Event (Donut Chart)",
        title_label_style_fontSize: "14px",
        title_label_style_fontWeight: "600",
        title_label_style_color: "#333", // Dark color for a professional look
        legend_visible: true,
        legend_position: "right", // Move the legend to the right
        donut_inner_radius: "50%", // Turn it into a donut chart
        series: [
          {
            points: points,  // Use points mapped from the API data
            border_width: 2, // Adding borders to each slice for better visibility
            border_color: "#ffffff", // White border for contrast
            label_text: "%name: %percent", // Display event name and percentage
            label_style_fontSize: "14px",
            label_style_fontWeight: "bold",
            label_style_color: "#fff", // White text for labels for contrast
            tooltip: {
              enabled: true,
              text: "%name: %percent%", // Tooltip displaying event name and percentage
              style_fontSize: "14px",
            },
            animation: {
              duration: 1000, // Smooth animation
            },
          },
        ],
        plot_area_background: "#f8f8f8", // Light background for the chart area
      });
    }
  }, [isLoaded, visitorData]);

  return <div id="pie-chart-container" style={{ height: "300px", width: "100%" }}></div>;
};

export default PieChart;
