import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { baseurl } from "../component/Baseurl";  // Assuming you have a base URL defined
import axios from "axios";
import logo from '../component/img/logo.png';
import { Link } from "react-router-dom";

const OTP = () => {
  // State to hold OTP values
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [decryptedEmail, setDecryptedEmail] = useState(""); // State to hold decrypted email
  const [loading, setLoading] = useState(false); // State to track loading status
  const inputRefs = useRef([]); // Ref to focus on OTP inputs
  const navigate = useNavigate(); // Hook for navigation

  // Access the query parameters using useLocation
  const location = useLocation();

  useEffect(() => {
    // Get the query parameter 'email' from the URL
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');  

    if (email) {
      try {
        // Decode the email from base64
        const decodedEmail = atob(email); // Decode using atob (base64 decoding)
        setDecryptedEmail(decodedEmail); // Set decrypted email state
      } catch (error) {
        console.error("Error decoding the email:", error);
      }
    }
  }, [location.search]); // Run this effect whenever the query string changes

  // Handle OTP input change
  const handleChange = (e, index) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value;

    // Update OTP state
    setOtp(newOtp);

    // Focus on the next input if current input has a value
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  // Handle form submit (verify OTP)
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Combine OTP array to send as a single string
    const otpString = otp.join("");
  
    console.log("OTP Submitted: ", otpString);
  
    // Set loading state to true to show the spinner
    setLoading(true);
  
    try {
      // Make an API request to verify OTP
      const response = await axios.post(`${baseurl}verify-orgotp`, {
        otp: otpString,               // Pass the OTP string
        email: decryptedEmail,        // Pass the decrypted email
      });
  
      // Log the entire response object to see its contents
      console.log("API Response: ", response);
  
      // Handle the response as needed
      if (response.status === 200) {
        // Extract id, name, and phone from the response
        const { id, name, phone } = response.data;  // Assuming response contains id, name, and phone
  
        // Log the success response with the id
        console.log(`OTP Verified Successfully: { org_id: ${id}, name: ${name}, phone: ${phone} }`);
  
        // Store the org_id in localStorage
        localStorage.setItem("orgId", id);
  
        // Log the stored values to confirm they are correctly saved
        console.log("Stored orgId in localStorage:", localStorage.getItem("orgId"));
  
        // Redirect based on name and phone values
        if (!name || !phone) {
          // If name or phone is empty or null, redirect to /my-account
          navigate(`/my-account`);
        } else {
          // Else, redirect to /dashboard
          navigate(`/dashboard`);
        }
      } else {
        console.error("OTP verification failed");
      }
    } catch (error) {
      console.error("There was an error!", error);
  
      // Log specific error details if available
      if (error.response) {
        console.error("Error response:", error.response.data);
      }
    } finally {
      // Reset loading state after 1 second (to keep spinner visible for a moment)
      setTimeout(() => setLoading(false), 1000);
    }
  };
  

  return (
    <section className="bg-signup d-flex align-items-center bglogin">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="my-card p-5">

              <div className="text-center">
                <div>
                  <img src={logo} className="img-fluid logo" alt="Eventoz Logo" />
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="mt-4">
                  <h3 className="header-title">Enter OTP</h3>
                </div>
              </div>

              <div className="register mt-3">
                {/* Display the decrypted email */}
                {decryptedEmail && (
                  <p className="text-dark">OTP sent to: <strong>{decryptedEmail}</strong></p>
                )}

                <form onSubmit={handleSubmit}>
                  <div className="otp-inputs">
                    {/* Create 6 OTP input fields */}
                    {otp.map((value, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={value}
                        onChange={(e) => handleChange(e, index)}
                        ref={(el) => (inputRefs.current[index] = el)}
                        className="otp-input"
                        required
                      />
                    ))}
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btnn">
                      {loading ? (
                        <>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Submitting...
                        </>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>

                <hr className="mt-4 mb-4" />
                <p className="text-muted ptxt">
                  We’re committed to your privacy. For more information, check
                  out our <Link to="/privacy-policy">Privacy Policy</Link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OTP;
