import React, { useState, useEffect, useRef } from "react";
import axios from "axios"; // Import axios
import { baseurl } from "../../component/Baseurl"; // Import baseurl
import GoToDash from "../../component/utils/GoToDash";
import { PlusSquare } from "react-feather";
import ReactDatePicker from "react-datepicker"; // Import React Datepicker
import "react-datepicker/dist/react-datepicker.css"; // Import Datepicker styles
import { useNavigate } from 'react-router-dom';
import Multiselect from "multiselect-react-dropdown";


const CreateExhibition = () => {
  const [selectedIndustries, setSelectedIndustries] = useState([]); // Selected industries
  const [industries, setIndustries] = useState([]); // All industries
  const [startDate, setStartDate] = useState(null); // Start date
  const [endDate, setEndDate] = useState(null); // End date
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    exhibitionName: "",
    organisedBy: "",
    frequency: "",
  }); // Form data state

  const [loading, setLoading] = useState(false); // State to track loading
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to track dropdown visibility
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  useEffect(() => {
    // Fetch industries when the component mounts
    const fetchIndustries = async () => {
      try {
        const response = await axios.get(`${baseurl}getAllIndustry`);
        if (response.status === 200) {
          setIndustries(response.data.industries); // Assuming the response contains an array of industries
        }
      } catch (error) {
        console.error("Error fetching industries:", error);
      }
    };

    fetchIndustries();
  }, []);

  useEffect(() => {
    // Close the dropdown if clicked outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleIndustryToggle = (industry) => {
    if (selectedIndustries.includes(industry)) {
      setSelectedIndustries(selectedIndustries.filter(item => item !== industry));
    } else {
      setSelectedIndustries([...selectedIndustries, industry]);
    }
  };

  const handleEndDateChange = (date) => {
    if (startDate && date < startDate) {
      setEndDate(null); // Clear the end date if validation fails
    } else {
      setEndDate(date);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!formData.exhibitionName || !selectedIndustries.length || !formData.organisedBy || !startDate || !endDate || !formData.frequency) {
      return;
    }
  
    const payload = {
      exhibitionName: formData.exhibitionName,
      organisedBy: formData.organisedBy,
      industries: selectedIndustries,
      startDate,
      endDate,
      frequency: formData.frequency,
      org_id: localStorage.getItem("orgId"), // Include org_id from localStorage
    };
  
    console.log("Payload being sent:", payload);
  
    try {
      setLoading(true); // Set loading to true before API request

      const response = await axios.post(`${baseurl}create-exhibition`, payload);
  
      console.log("Response received:", response);
  
      if (response.status === 201) {
        setFormData({ exhibitionName: "", organisedBy: "", frequency: "" });
        setSelectedIndustries([]);
        setStartDate(null);
        setEndDate(null);
  
        setTimeout(() => {
          const exhibitionUniqueId = response.data.exhibition_unique_id;
          navigate(`/exhibition-dashboard/${exhibitionUniqueId}/basic-details`);
        }, 1000);
      } else {
        console.error(response.data.message || "Failed to create exhibition.");
      }
    } catch (error) {
      console.error("Error creating exhibition:", error);
    } finally {
      setLoading(false); // Reset loading state after request is complete
    }
  };

  return (
    <div className="">
      <div className="d-flex align-items-center justify-content-between sticky-top px-4 py-3 bg-1">
        <div className="tp-h-tl">Create Exhibition</div>
        <div>
          <GoToDash />
        </div>
      </div>

      <div className="container mt-4 mb-5">
        <div className="row">
          <div className="col-md-12">
            <div className="crd1">
              <h1 className="text-capitalize d-flex align-items-center">
                <span>
                  <PlusSquare className="ic3" size={50} />
                </span>
                <span>Complete the below Form</span>
              </h1>

              <hr />

              <div className="p-3">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-12">
                      <label className="form-label">
                        Enter Exhibition Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="exhibitionName"
                        className="form-control"
                        placeholder="Enter Exhibition Name"
                        value={formData.exhibitionName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

<div className="col-md-6">
                          <label className="form-label">
                            Select Industry <span className="text-danger">*</span>
                          </label>
                          <Multiselect
                            options={industries} // Data to populate the dropdown
                            displayValue="industry_name" // Key to display in the dropdown 
                            // className="form-control"
                           
                            selectedValues={industries.filter((industry) =>
                              selectedIndustries.includes(industry.industry_name)
                            )} // Pre-select based on selectedIndustries
                            onSelect={(selectedList, selectedItem) => {
                              handleIndustryToggle(selectedItem.industry_name); // Add item
                            }}
                            onRemove={(selectedList, removedItem) => {
                              handleIndustryToggle(removedItem.industry_name); // Remove item
                            }}
                            placeholder="Select Industries"
                            style={{
                              multiselectContainer: { width: "100%" }, // Adjust the container style
                            }}
                          />
                        </div>


                    <div className="col-md-6">
                      <label className="form-label">
                        Organised By <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="organisedBy"
                        className="form-control"
                        placeholder="Enter Organiser Name"
                        value={formData.organisedBy}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">
                        Exhibition Start Date <span className="text-danger">*</span>
                      </label>
                      <ReactDatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Select Start Date"
                        required
                        minDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">
                        Exhibition End Date <span className="text-danger">*</span>
                      </label>
                      <ReactDatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        placeholderText="Select End Date"
                        required
                        minDate={startDate || new Date()}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">
                        Exhibition Frequency <span className="text-danger">*</span>
                      </label>
                      <select
                        name="frequency"
                        className="form-control"
                        value={formData.frequency}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Frequency</option>
                        <option value="One Time Event">One Time Event</option>
                        <option value="Every Week">Every Week</option>
                        <option value="Every Month">Every Month</option>
                        <option value="Once In 3 Month">Once In 3 Month</option>
                        <option value="Once In 6 Month">Once In 6 Month</option>
                        <option value="Every Year">Every Year</option>
                      </select>
                    </div>

                    <div className="col-md-12 text-end">
                      <button type="submit" className="btnn" disabled={loading}>
                        {loading ? "Submitting..." : "Create Exhibition"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default CreateExhibition;
