import React, { useState, useEffect } from "react";
import { baseurl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link } from "react-router-dom";
import { Eye } from "react-feather"; // Eye icon for viewing details
import { useConference } from "../../context/ConferenceContext";  
import ConferenceCommunicationNavbar from "../utils/ConferenceCommunicationNavbar";

const ConferenceRequestQuery = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [queries, setQueries] = useState([]); // State to store event queries
  const [loading, setLoading] = useState(true); // State to manage loading
  const [selectedQuery, setSelectedQuery] = useState(null); // State to store selected query for modal
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility
  const { conferenceId } = useConference(); // Get conferenceId from context

  useEffect(() => {
    // Fetch event queries when the component mounts
    const fetchEventQueries = async () => {
      try {
        const response = await axios.get(`${baseurl}getallquery/${conferenceId}`);
        
        if (response.data.success) {
          setQueries(response.data.data); // Set queries in the state
        }
      } catch (error) {
        console.error("Error fetching event queries:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchEventQueries();
  }, [conferenceId]); // Re-run the effect if conferenceId changes

  // Calculate the index of the first and last records for the current page
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  // Slice the queries array to get only the records for the current page
  const currentRecords = queries.slice(indexOfFirstRecord, indexOfLastRecord);

  // Handle pagination button click
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle opening the modal with selected query details
  const handleViewDetails = (query) => {
    setSelectedQuery(query); // Set the selected query
    setModalOpen(true); // Open the modal
  };

  // Handle closing the modal
  const closeModal = () => {
    setModalOpen(false); // Close the modal
    setSelectedQuery(null); // Clear the selected query
  };

  return (
    <div> 
      <ConferenceCommunicationNavbar/>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="mt-3">Guest Request/Query</h1>
        </div>

        <div className="row">
          <div className="crd py-4">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Company</th>
                    <th>Message</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRecords.map((query) => (
                    <tr key={query.id}>
                      <td>{query.name}</td>
                      <td>{query.email}</td>
                      <td>{query.mobile}</td>
                      <td>{query.company}</td>
                      <td>{query.message}</td>
                      <td>{new Date(query.created_at).toLocaleString()}</td>
                      <td>
                        {/* Eye icon for viewing details */}
                        <Eye
                          color="blue"
                          className="ms-2"
                          onClick={() => handleViewDetails(query)}
                          style={{ cursor: 'pointer' }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {/* Pagination */}
            <nav>
              <ul className="pagination">
                {[...Array(Math.ceil(queries.length / recordsPerPage))].map((_, index) => (
                  <li key={index} className="page-item">
                    <button
                      className="page-link"
                      onClick={() => paginate(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      {/* Modal to show query details */}
      {modalOpen && selectedQuery && (
        <div className="modal fade show" style={{ display: "block" }} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Query Details</h5>
                <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
              </div>
              <div className="modal-body text-start">
                <p className="text-start"><strong>Name:</strong> {selectedQuery.name}</p>
                <p className="text-start"><strong>Email:</strong> {selectedQuery.email}</p>
                <p className="text-start"><strong>Mobile:</strong> {selectedQuery.mobile}</p>
                <p className="text-start"><strong>Company:</strong> {selectedQuery.company}</p>
                <p className="text-start"><strong>Message:</strong> {selectedQuery.message}</p>
                <p className="text-start"><strong>Created At:</strong> {new Date(selectedQuery.created_at).toLocaleString()}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConferenceRequestQuery;
