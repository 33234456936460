import React, { useState } from "react";
import { useExhibition } from "../../context/ExhibitionContext"; // Import the context hook
import { useNavigate } from 'react-router-dom'; // Import navigate hook
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2'; // Import SweetAlert2 for the confirmation dialog
import axios from 'axios'; // Import axios for API calls
import { baseurl } from "../../component/Baseurl";

const AddTicket = () => {
  const { exhibitionId } = useExhibition(); // Assuming exhibitionId is available in context
  const navigate = useNavigate(); // For navigation after submission

  // State for form fields
  const [ticketDetails, setTicketDetails] = useState({
    ticketName: "",
    eventType: "Free", // Default event type
    ticketType: "Visitor", // Default ticket type
    description: "",
    currency: "",
    price: "",
    quantity: "",
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTicketDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Handle form submission with axios
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      ticketName: ticketDetails.ticketName,
      eventType: ticketDetails.eventType,
      ticketType: ticketDetails.ticketType,
      description: ticketDetails.description,
      quantity: ticketDetails.quantity,
    };

    if (ticketDetails.eventType === "Paid") {
      formData.currency = ticketDetails.currency;
      formData.price = ticketDetails.price;
    }
    
    formData.eventId = exhibitionId;

    // Log the data to be sent
    console.log("FormData:", formData);

    try {
      const response = await axios.post(`${baseurl}createTickets`, formData, {
        headers: {
          'Content-Type': 'application/json', // Set header for JSON data
        },
      });

      // Log the response from the API
      console.log("API Response:", response);

      if (response.status === 201) {  // Check if the status is 201 (Created)
        Swal.fire({
          title: "Success",
          text: "The ticket has been added successfully.",
          icon: "success",
        }).then(() => {
          // Optional: You can perform further actions here, like redirecting the user
          // Example: navigate("/some-other-page");
        });

        // Reset the form
        setTicketDetails({
          ticketName: "",
          eventType: "Free",
          ticketType: "Visitor",
          description: "",
          currency: "",
          price: "",
          quantity: "",
        });

        // Refresh the page if needed or update state as necessary
        window.location.reload();
      } else {
        toast.error(`Failed to add ticket: ${response.data.error || 'Unknown error'}`);
      }
    } catch (error) {
      console.error("Error adding ticket:", error);
      toast.error("Something went wrong. Please try again later.");
    }

    window.bootstrap.Modal.getInstance(document.getElementById('addTicketModal')).hide();
};

  

  return (
    <div>
      <div className="d-flex justify-content-between">
        <span><h1 className="mt-3">Tickets</h1></span>
        <button
          type="button"
          className="btnn"
          data-bs-toggle="modal"
          data-bs-target="#addTicketModal"
        >
          Add Ticket
        </button>
      </div>

      <div
        className="modal fade"
        id="addTicketModal"
        tabIndex="-1"
        aria-labelledby="addTicketModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addTicketModalLabel">Add Ticket</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="ticketName">Ticket Name<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="ticketName"
                    className="form-control"
                    value={ticketDetails.ticketName}
                    onChange={handleInputChange}
                    placeholder="Enter Ticket Name"
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="eventType">Event Type<span className="text-danger">*</span></label>
                  <select
                    name="eventType"
                    className="form-control"
                    value={ticketDetails.eventType}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="Free">Free</option>
                    <option value="Paid">Paid</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="ticketType">Ticket Type<span className="text-danger">*</span></label>
                  <select
                    name="ticketType"
                    className="form-control"
                    value={ticketDetails.ticketType}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="Visitor">Visitor</option>
                    <option value="Delegate">Delegate</option>
                    <option value="VIP">VIP</option>
                    <option value="Speaker">Speaker</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <textarea
                    name="description"
                    className="form-control"
                    value={ticketDetails.description}
                    onChange={handleInputChange}
                    placeholder="Enter Ticket Description"
                  ></textarea>
                </div>

                {ticketDetails.eventType === "Paid" && (
                  <>
                    <div className="form-group">
                      <label htmlFor="currency">Currency</label>
                      <select
                        name="currency"
                        className="form-control"
                        value={ticketDetails.currency}
                        onChange={handleInputChange}
                      >
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value="INR">INR</option>
                        {/* Add more currencies as needed */}
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="price">Price<span className="text-danger">*</span></label>
                      <input
                        type="number"
                        name="price"
                        className="form-control"
                        value={ticketDetails.price}
                        onChange={handleInputChange}
                        placeholder="Enter Ticket Price"
                        required
                      />
                    </div>
                  </>
                )}

                <div className="form-group">
                  <label htmlFor="quantity">Quantity<span className="text-danger">*</span></label>
                  <input
                    type="number"
                    name="quantity"
                    className="form-control"
                    value={ticketDetails.quantity}
                    onChange={handleInputChange}
                    placeholder="Enter Ticket Quantity"
                    required
                  />
                </div>

                <div className="modal-footer">
                  <button type="submit" className="btnn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default AddTicket;
