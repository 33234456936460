import React, { useState, useEffect } from "react";
import { useExhibition } from "../../context/ExhibitionContext";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { baseurl } from "../../component/Baseurl";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import { ArrowRight } from "react-feather";
import ExhiibitionBreadcrum from "../ExhibitionComponent/ExhibitionBreadcrum";

const ExhibitionBasicDetails = () => {
  const { exhibitionId } = useExhibition();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    exhibitionName: "",
    organisedBy: "",
    frequency: "",
  });

  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const org_id = localStorage.getItem("orgId");
  // const [activeToggle, setActiveToggle] = useState(null);
  const [activeToggle, setActiveToggle] = useState("inPerson");
  
const [industries, setIndustries] = useState([]);

 
// Handle toggle change
const handleToggleChange = (mode) => {
  // Prevent turning off the only active toggle
  if (activeToggle === mode) return;

  // Set the active toggle and update form data
  setActiveToggle(mode);
  setFormData({
    ...formData,
    eventMode: mode,
  });
};



  useEffect(() => {
    // Fetch industries when the component mounts
    const fetchIndustries = async () => {
      try {
        const response = await axios.get(`${baseurl}getAllIndustry`);
        if (response.status === 200) {
          setIndustries(response.data.industries); // Assuming the response contains an array of industries
        }
      } catch (error) {
        console.error("Error fetching industries:", error);
      }
    };

    fetchIndustries();
  }, []);


  useEffect(() => {
    if (!org_id || !exhibitionId) {
      toast.error("orgId or exhibitionId is missing!");
      return;
    }

    const fetchExhibitionData = async () => {
      try { 
        const response = await axios.get(
          `${baseurl}exhibitionBasicDetails/${org_id}/${exhibitionId}`
        );

        // console.log("Fetched Exhibition Data:", response.data);

        const { exhibition, industries, venue } = response.data;

        setFormData({
          exhibitionName: exhibition.exhibition_name,
          organisedBy: exhibition.organised_by,
          frequency: exhibition.exhibition_frequency,
          venueName: venue.venue_name,
          venueAddress: venue.venue_address,
          venueMap: venue.venue_map,
          timezone: venue.timezone || "",
          joiningLink: venue.joining_link || "",
          eventMode: venue.event_mode || "",
        });

        if (venue.event_mode === null) {
          setActiveToggle("inPerson");
        }else{
          setActiveToggle(venue.event_mode);
        }


        

        setSelectedIndustries(industries.map((industry) => industry.industry));
        setStartDate(new Date(exhibition.exhibition_start_date));
        setEndDate(new Date(exhibition.exhibition_end_date));
      } catch (error) {
        toast.error("Error fetching exhibition details!");
        // console.error(error);
      }
    };

    fetchExhibitionData();
  }, [exhibitionId]);

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleIndustryToggle = (industry) => {
    if (selectedIndustries.includes(industry)) {
      setSelectedIndustries(selectedIndustries.filter(item => item !== industry));
    } else {
      setSelectedIndustries([...selectedIndustries, industry]);
    }
  };

  const handleIndustryClick = (industry) => {
    setSelectedIndustries((prev) => prev.filter((item) => item !== industry));
  };
  const handleEndDateChange = (date) => {
    if (startDate && date < startDate) {
      toast.error("End date must be after the start date.");
      setEndDate(null);
    } else {
      setEndDate(date);
    }
  };
  const handleSubmit = async (e) => {
    const {
      venueName,
      venueAddress,
      venueMap,
      timezone,
      joiningLink,
      eventMode,
    } = formData;

    // Validate form data (basic validation)
    if (
      !venueName ||
      !venueAddress ||
      !venueMap ||
      ((activeToggle === "virtual" || activeToggle === "hybrid") &&
        (!timezone || !joiningLink))
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    e.preventDefault();
     
    try {
      const response = await axios.post(`${baseurl}/update-exhibition`, {
        org_id,
        exhibitionId,
        ...formData,
        selectedIndustries,
        startDate,
        endDate,
        venueName,
        venueAddress,
        venueMap,
        timezone,
        joiningLink,
        eventMode,
      });

      if (response.status === 200) {
        navigate(`/exhibition-dashboard/${exhibitionId}/additional-details`);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  return (
    <div>
      <ExhiibitionBreadcrum />

      <ExhibitionContentNavbar />
      <h1 className="mt-3">Basic Details</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-3">
            <form onSubmit={handleSubmit}>
              <div className="row g-2">
                <div className="col-md-12">
                  <label className="form-label">
                    Enter Exhibition Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="exhibitionName"
                    className="form-control"
                    placeholder="Enter Exhibition Name"
                    value={formData.exhibitionName}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                {/* <div className="col-md-6">
                  <label className="form-label">
                    Exhibition Industry (Select From Dropdown)
                  </label>
                  <textarea
                    className="txtarea"
                    rows="4"
                    placeholder="Select Industry From Dropdown"
                    value={selectedIndustries.join(", ")}
                    readOnly
                  ></textarea>
                </div> */}

                <div className="col-md-12">
                  <label className="form-label">
                    Exhibition Industry (Select Industries)
                  </label>
                  <div className="industry-multiselect">
                    {industries.map((industry, index) => (
                      <label key={index} className="industry-label">
                        <input
                          type="checkbox"
                          value={industry.industry_name}
                          checked={selectedIndustries.includes(
                            industry.industry_name
                          )}
                          onChange={() =>
                            handleIndustryToggle(industry.industry_name)
                          }
                        />
                        {industry.industry_name}
                      </label>
                    ))}
                  </div>
                  {/* 
  <div className="selected-industries mt-2">
    {selectedIndustries.map((industry, index) => (
      <span
        key={index}
        className="industry-item"
        onClick={() => handleIndustryClick(industry)}
        style={{
          textDecoration: "line-through",
          cursor: "pointer",
          marginRight: "10px",
        }}
      >
        {industry}
      </span>
    ))}
  </div> */}
                </div>

                <div className="col-md-12">
                  <label className="form-label">
                    Organised By <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="organisedBy"
                    className="form-control"
                    placeholder="Enter Organiser Name"
                    value={formData.organisedBy}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="col-md-4">
                  <label className="form-label">
                    Exhibition Start Date <span className="text-danger">*</span>
                  </label>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Select Start Date"
                    required
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </div>

                <div className="col-md-4">
                  <label className="form-label">
                    Exhibition End Date <span className="text-danger">*</span>
                  </label>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    placeholderText="Select End Date"
                    required
                    minDate={startDate || new Date()}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </div>

                <div className="col-md-4">
                  <label className="form-label">
                    Exhibition Frequency <span className="text-danger">*</span>
                  </label>
                  <select
                    name="frequency"
                    className="form-control"
                    value={formData.frequency}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Frequency</option>
                    <option value="One Time Event">One Time Event</option>
                    <option value="Every Week">Every Week</option>
                    <option value="Every Month">Every Month</option>
                    <option value="Once In 3 Month">Once In 3 Month</option>
                    <option value="Once In 6 Month">Once In 6 Month</option>
                    <option value="Every Year">Every Year</option>
                  </select>
                </div>
 
                
                <div className="col-md-12 industry-multiselect">
                <label className="form-label mb-0">
                    Select Exhibition Mode{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="industry-multiselect">
                    <label className="industry-label">
                      <input
                        type="checkbox"
                        checked={activeToggle === "inPerson"}
                        onChange={() => handleToggleChange("inPerson")}
                      />
                      InPerson
                    </label>
                  </div>


                  <div className="industry-multiselect">
                    <label className="industry-label">
                      <input
                        type="checkbox"
                        checked={activeToggle === "virtual"}
                        onChange={() => handleToggleChange("virtual")}
                      />
                      Virtual
                    </label>
                  </div>

                  <div className="industry-multiselect">
                    <label className="industry-label">
                      <input
                        type="checkbox"
                        checked={activeToggle === "hybrid"}
                        onChange={() => handleToggleChange("hybrid")}
                      />
                      Hybrid
                    </label>
                  </div>
                    
 

                   
                </div>

                {/* Conditionally render inputs based on active toggle */}
                {activeToggle === "inPerson" ||
                activeToggle === "hybrid" ||
                activeToggle === "" ? (
                  <>
                    <div className="col-md-12">
                      <label className="form-label">
                        Venue Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="venueName"
                        className="form-control"
                        placeholder="Enter Venue Name"
                        value={formData.venueName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Venue Address <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Venue Address"
                        rows="3"
                        name="venueAddress"
                        value={formData.venueAddress}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Venue Map <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Map Iframe Code"
                        rows="3"
                        name="venueMap"
                        value={formData.venueMap}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>
                  </>
                ) : null}

                {activeToggle === "virtual" || activeToggle === "hybrid" ? (
                  <>
                    <div className="col-md-12">
                      <label className="form-label">
                        Select TimeZone <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Timezone"
                        rows="3"
                        name="timezone"
                        value={formData.timezone}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Joining Link <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Joining Link"
                        rows="3"
                        name="joiningLink"
                        value={formData.joiningLink}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="col-md-12 mt-3 text-end">
                <button type="submit" className="btnn">
                  Next <ArrowRight className="ic text-white" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionBasicDetails;
