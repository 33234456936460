import React, { useState, useEffect } from "react";
import { useExhibition } from "../../context/ExhibitionContext";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseurl } from "../../component/Baseurl";
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import ExhiibitionBreadcrum from "../ExhibitionComponent/ExhibitionBreadcrum";
import { ArrowRight, ArrowLeft } from "react-feather";

const ExhibitionBadgeSetting = () => {
  const { exhibitionId } = useExhibition();
  const [formData, setFormData] = useState({
    prefix: "",
    startRange: "",
    endRange: "",
  });

  const navigate = useNavigate();

  // Fetch badge details on load only if exhibitionId exists
  useEffect(() => {
    if (exhibitionId) {
      const fetchBadgeDetails = async () => {
        try {
          const response = await axios.get(`${baseurl}fetchBadgeDetails/${exhibitionId}`);
          if (response.data.success && response.data.data.length > 0) {
            const { prefix, start_from, end_to } = response.data.data[0];
            setFormData({
              prefix,
              startRange: start_from,
              endRange: end_to,
            });
          }
        } catch (error) {
          console.error("Error fetching badge details:", error);
          Swal.fire({
            title: "Error!",
            text: "Failed to fetch badge details. You can manually enter the details.",
            icon: "warning",
          });
        }
      };

      fetchBadgeDetails();
    }
  }, [exhibitionId]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSend = {
      ...formData,
      eventUniqueId: exhibitionId,
    };

    console.log("Data to be sent to backend:", dataToSend);

    try {
      const response = await axios.post(`${baseurl}insertBadgeDetails`, dataToSend);
      if (response.data.success) {
        Swal.fire({
          title: "Success!",
          text: "Details submitted successfully.",
          icon: "success",
          confirmButtonText: "Go To Dashboard",
        }).then(() => {
          navigate(`/exhibition-dashboard/${exhibitionId}/stats`);
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: response.data.message || "Something went wrong.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to submit the details. Please try again.",
        icon: "error",
      });
    }
  };

  return (
    <div>
      <ExhiibitionBreadcrum />
      <ExhibitionContentNavbar />
      <h1 className="mt-3">Basic Details</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-3">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <label className="form-label">
                    Prefix<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="prefix"
                    className="form-control"
                    placeholder="Enter Prefix"
                    value={formData.prefix}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="col-md-4">
                  <label className="form-label">
                    Start Range from <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="startRange"
                    className="form-control"
                    placeholder="Enter Start Range"
                    value={formData.startRange}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="col-md-4">
                  <label className="form-label">
                    End Range To <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="endRange"
                    className="form-control"
                    placeholder="Enter End Range"
                    value={formData.endRange}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="col-md-12 mt-5 mb-3 d-flex justify-content-between align-items-center">
              <span>
                <Link
                  to={`/exhibition-dashboard/${exhibitionId}/exhibition-extra-details`}
                  className="btnn mybtn"
                >
                  <ArrowLeft className="ic text-white" />
                  Back
                </Link>
              </span>
              <span>
              <div className="col-md-12 mt-0 text-end">
                <button type="submit" className="btnn m-0">
                Submit <ArrowRight className="ic text-white" />
                </button>
              </div>
              </span>
            </div>


 
            </form>

            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionBadgeSetting;
