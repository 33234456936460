import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { baseurl } from "../../component/Baseurl";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useWorkshop } from "../../context/WorkshopContext"; 
// import workshopContentNavbar from "../utils/workshopContentNavbar";
import Multiselect from "multiselect-react-dropdown";


const WorkshopBasicDetails = () => {
  const { workshopId } = useWorkshop();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    workshopName: "",
    organisedBy: "",
    rangeAttendees: "",
    frequency: "",
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleSelect = (selectedList) => {
    setSelectedIndustries(selectedList.map(item => item.industry_name));
  };

  const handleRemove = (selectedList) => {
    setSelectedIndustries(selectedList.map(item => item.industry_name));
  };
  
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const org_id = localStorage.getItem("orgId");

const [industries, setIndustries] = useState([]);

 const [activeToggle, setActiveToggle] = useState("inPerson");


// Handle toggle change
const handleToggleChange = (mode) => {
  // Prevent turning off the only active toggle
  if (activeToggle === mode) return;

  // Set the active toggle and update form data
  setActiveToggle(mode);
  setFormData({
    ...formData,
    eventMode: mode,
  });
};


  useEffect(() => {
    // Fetch industries when the component mounts
    const fetchIndustries = async () => {
      try {
        const response = await axios.get(`${baseurl}getAllIndustry`);
        if (response.status === 200) {
          setIndustries(response.data.industries); // Assuming the response contains an array of industries
        }
      } catch (error) {
        console.error("Error fetching industries:", error);
      }
    };

    fetchIndustries();
  }, []);


  useEffect(() => {
    if (!org_id || !workshopId) {
      toast.error("Organization ID or workshop ID is missing!");
      return;
    }
  
    const fetchworkshopData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}workshopBasicDetails/${org_id}/${workshopId}`
        );
        const { workshop, industries, venue } = response.data;
  
        console.log("response", response);
  
        // Check if venue is null or undefined
        const venueData = venue || {
          venue_name: null,
          venue_address: null,
          venue_map: null,
          timezone: null,
          joining_link: null,
          event_mode: null,
        };
  
        // Update form data based on API response
        setFormData({
          workshopName: workshop.workshop_name,
          organisedBy: workshop.organised_by,
          attendees: workshop.range_attendees,
          frequency: workshop.workshop_frequency,
          venueName: venueData.venue_name || "",
          venueAddress: venueData.venue_address || "",
          venueMap: venueData.venue_map || "",
          timezone: venueData.timezone || "",
          joiningLink: venueData.joining_link || "",
          eventMode: venueData.event_mode || "",
        });
  
        setSelectedIndustries(industries.map((industry) => industry.industry));
        setStartDate(new Date(workshop.workshop_start_date));
        setEndDate(new Date(workshop.workshop_end_date));
      } catch (error) {
        toast.error("Error fetching workshop details!");
        console.error(error);
      }
    };
  
    fetchworkshopData();
  }, [workshopId]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleIndustryToggle = (industry) => {
    if (selectedIndustries.includes(industry)) {
      setSelectedIndustries(selectedIndustries.filter(item => item !== industry));
    } else {
      setSelectedIndustries([...selectedIndustries, industry]);
    }
  };


  const handleIndustryClick = (industry) => {
    setSelectedIndustries((prev) => prev.filter((item) => item !== industry));
  };

  const handleEndDateChange = (date) => {
    if (startDate && date < startDate) {
      toast.error("End date must be after the start date.");
      setEndDate(null);
    } else {
      setEndDate(date);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Log the data being sent
    console.log("Data to be sent:", {
      org_id,
      workshopId,
      ...formData,
      selectedIndustries,
      startDate,
      endDate,
    });
  
    try {
      const response = await axios.post(`${baseurl}update-workshop`, {
        org_id,
        workshopId,
        ...formData,
        selectedIndustries,
        startDate,
        endDate,
      });
  
      if (response.status === 200) {
        // Navigate on successful response

        navigate(`/workshop-dashboard/${workshopId}/workshop-additional-details`);
 
      }
    } catch (error) {
      // Show Swal on error
      console.error("API Error:", error);
      Swal.fire("Error", "Error updating workshop details!", "error");
    }
  };
  
  
  

  return (
    <div>
      {/* <workshopContentNavbar /> */}
      <h1 className="mt-3">workshop Basic Details</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-3">
            <form onSubmit={handleSubmit}>
              <div className="row g-2 mb-3">
                <div className="col-md-12">
                  <label className="form-label">
                    Workshop Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="workshopName"
                    className="form-control"
                    placeholder="Enter workshop Name"
                    value={formData.workshopName || ''}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="col-md-12">
                  <label className="form-label">
                    Organised By <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="organisedBy"
                    className="form-control"
                    placeholder="Enter Organiser Name"
                    value={formData.organisedBy || ''}
                    onChange={handleInputChange}
                    required
                  />
                </div>


                <div className="col-md-6">
      <label className="form-label">
        Select Industry <span className="text-danger">*</span>
      </label>
      <div className="custom-industry-dropdown">
        <Multiselect
          options={industries} // List of options to display
          selectedValues={industries.filter(industry =>
            selectedIndustries.includes(industry.industry_name)
          )} // Set selected items
          onSelect={handleSelect} // Handle item selection
          onRemove={handleRemove} // Handle item removal
          displayValue="industry_name" // Display value from the industry object
          placeholder="Select Industries"
          showCheckbox
        />
      </div>
    </div>

                


                <div className="col-md-4">
                      <label className="form-label">
                        Range of Attendees <span className="text-danger">*</span>
                      </label>
                      <select
                        name="attendees"
                        className="form-control"
                        value={formData.attendees  || ''}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Attendee Range</option>
                        <option value="0-50">0-50</option>
                        <option value="50-100">50-100</option>
                        <option value="100-200">100-200</option>
                        <option value="200-500">200-500</option>
                        <option value="500+">500+</option>
                      </select>
                    </div>

                <div className="col-md-4">
                  <label className="form-label">
                    Start Date <span className="text-danger">*</span>
                  </label>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Select Start Date"
                    required
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </div>

                <div className="col-md-4">
                  <label className="form-label">
                    End Date <span className="text-danger">*</span>
                  </label>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    placeholderText="Select End Date"
                    required
                    minDate={startDate || new Date()}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </div>

                <div className="col-md-4">
                  <label className="form-label">
                    Frequency <span className="text-danger">*</span>
                  </label>
                  <select
                    name="frequency"
                    className="form-control"
                    value={formData.frequency || ''}
                    onChange={handleInputChange}
                    required
                  > 
                        <option value="One Time Event">One Time Event</option>
                        <option value="Every Week">Every Week</option>
                        <option value="Every Month">Every Month</option>
                        <option value="Once In 3 Month">Once In 3 Month</option>
                        <option value="Once In 6 Month">Once In 6 Month</option>
                        <option value="Every Year">Every Year</option>
                  </select>
                </div>
              </div>




              <div className="row g-2">


              <div className="col-md-12 industry-multiselect">
                <label className="form-label mb-0">
                    Select Exhibition Mode{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="industry-multiselect">
                    <label className="industry-label">
                      <input
                        type="checkbox"
                        checked={activeToggle === "inPerson"}
                        onChange={() => handleToggleChange("inPerson")}
                      />
                      InPerson
                    </label>
                  </div>


                  <div className="industry-multiselect">
                    <label className="industry-label">
                      <input
                        type="checkbox"
                        checked={activeToggle === "virtual"}
                        onChange={() => handleToggleChange("virtual")}
                      />
                      Virtual
                    </label>
                  </div>

                  <div className="industry-multiselect">
                    <label className="industry-label">
                      <input
                        type="checkbox"
                        checked={activeToggle === "hybrid"}
                        onChange={() => handleToggleChange("hybrid")}
                      />
                      Hybrid
                    </label>
                  </div> 
                </div>



 
                {/* Conditionally render inputs based on active toggle for workshop */}
                {activeToggle === 'inPerson' || activeToggle === 'hybrid' ? (
                  <>
                    <div className="col-md-12">
                      <label className="form-label">
                        Venue Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="venueName"
                        className="form-control"
                        placeholder="Enter Venue Name"
                        value={formData.venueName || ''}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Venue Address <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Venue Address"
                        rows="3"
                        name="venueAddress"
                        value={formData.venueAddress || ''}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                      </div>

                      <div className="col-md-12">
                        <label className="form-label">
                          Venue Map <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Enter Map Iframe Code"
                          rows="3"
                          name="venueMap"
                          value={formData.venueMap || ''}
                          onChange={handleInputChange}
                          required
                        ></textarea>
                      </div>
                    </>
                  ) : null}

                  {activeToggle === 'virtual' || activeToggle === 'hybrid' ? (
                    <>
                      <div className="col-md-12">
                        <label className="form-label">
                          Select TimeZone <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Enter Timezone"
                          rows="3"
                          name="timezone"
                          value={formData.timezone || ''}
                          onChange={handleInputChange}
                          required
                        ></textarea>
                      </div>

                      <div className="col-md-12">
                        <label className="form-label">
                          Joining Link <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Enter Joining Link"
                          rows="3"
                          name="joiningLink"
                          value={formData.joiningLink || ''}
                          onChange={handleInputChange}
                          required
                        ></textarea>
                      </div>
                    </>
                  ) : null}

                </div>




              <div className="col-md-12 mt-3 text-end">
                <button type="submit" className="btnn">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopBasicDetails;
