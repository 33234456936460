import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { baseurl } from "../component/Baseurl";
import logo from "../component/img/logo.png";

const Invoice = () => {
  const [searchParams] = useSearchParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const razorpayOrderId = searchParams.get("order_id");

    if (razorpayOrderId) {
      axios
        .get(`${baseurl}/getPaymentMasterByOrderId/${razorpayOrderId}`)
        .then((response) => {
          console.log("API Response:", response.data);
          setInvoiceData(response.data.data[0]);
          setError(null);
        })
        .catch((error) => {
          console.error("Error fetching payment details:", error.message);
          setError("Unable to fetch invoice details. Please try again later.");
        });
    } else {
      console.warn("Razorpay Order ID not found in query parameters.");
    }
  }, [searchParams]);

  const downloadPDF = () => {
    const invoice = document.getElementById("invoice-content");
    html2canvas(invoice, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(`Invoice_${invoiceData?.order_id || "Download"}.pdf`);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  return (
    <div className="container my-2">
      <div className="row justify-content-center">
      <div className="text-end my-2">
                  <button className="btnn" onClick={downloadPDF}>
                    Download as PDF
                  </button>
                </div>


        <div className="col-md-10 col-12 col-lg-9">
          <div className="card p-5 shadow" id="invoice-content">
            {error && <p style={{ color: "red" }}>{error}</p>}

            {invoiceData ? (
              <div>
                <div >
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                      <img src={logo} className="img-fluid logo" alt="logo" />
                    </div>
                    <div className="text-start">
                      <h4 className="fw-bold">INVOICE</h4>
                      <p className="m-0">Invoice # {invoiceData.order_id}</p>
                      <p className="m-0">
                        Invoice Date:{" "}
                        {new Date(invoiceData.created_at).toLocaleString()}
                      </p>
                      <p className="m-0">
                        Invoice Amount: {invoiceData.currency}.{" "}
                        {invoiceData.amount}
                      </p>
                      <p className="m-0">Billing Period: Aug 13 to Aug 13, 2024</p>
                      <h5 className="text-success fw-bold">PAID</h5>
                    </div>
                  </div>

{/* Addresses */}
<div className="row">
              <div className=" text-start">
                <h6 className="fw-bold">Everconic Tech System Private Limited</h6>
                <div className="text-start tct1">{invoiceData.amount}</div>
                <div className="text-start tct1">INDRA NAGAR, Udham Singh Nagar</div>
                <div className="text-start tct1"> </div>
                <div className="text-start tct1"> </div>
                {/* <div className="text-start">HSN / SAC: 998313</div> */}
                <div className="text-start tct1">GSTIN: SDSJF999993G12C</div>
              </div>
              <div className=" ">
                <h6 className="fw-bold">BILLED TO</h6>
                <div className="text-start tct1">{invoiceData.name}</div>
                <div className="text-start tct1">Everconic Tech System Private Limited</div>
                <div className="text-start tct1">
                {invoiceData.city}, {invoiceData.country} {invoiceData.pincode}
                </div>
                <div className="text-start tct1">{invoiceData.city}, {invoiceData.pincode}</div>
                <div className="text-start tct1">{invoiceData.org_email}</div>
                <div className="text-start tct1">{invoiceData.country_code} {invoiceData.phone}</div>
                <div className="text-start tct1">GSTIN: 27AAECE7599H124</div>
              </div>
            </div>

                  <hr />

                  <div className="mt-3">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Units</th>
                          <th>Unit Price</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{invoiceData.package_name}</td>
                          <td>1</td>
                          <td>
                            {invoiceData.currency}.{" "}
                            {(invoiceData.amount / (1 + 18 / 100)).toFixed(2)}
                          </td>
                          <td>
                            {invoiceData.currency}.{" "}
                            {(invoiceData.amount / (1 + 18 / 100)).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="d-flex justify-content-end mt-3">
                    <table className="table w-auto">
                      <tbody>
                        <tr>
                          <td>Sub Total:</td>
                          <td>
                            {invoiceData.currency}.{" "}
                            {(invoiceData.amount / (1 + 18 / 100)).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td>IGST @ 18%:</td>
                          <td>
                            {invoiceData.currency}.{" "}
                            {(
                              invoiceData.amount -
                              invoiceData.amount / (1 + 18 / 100)
                            ).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Total:</td>
                          <td className="fw-bold">
                            {invoiceData.currency}. {invoiceData.amount}
                          </td>
                        </tr>
                        <tr>
                          <td>Payments:</td>
                          <td>
                            -{invoiceData.currency}. {invoiceData.amount}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                
              </div>
            ) : (
              !error && <p>Loading invoice details...</p>
            )}
          </div>


          


        </div>
      </div>
    </div>
  );
};

export default Invoice;
