import React from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import { Search, LogOut as UsersIcon } from "react-feather";  
import { useConference } from "../../context/ConferenceContext";

const ConferenceLeadsNavbar = () => {
    const { conferenceId } = useConference(); 
    const location = useLocation(); // Get the current location

    // Function to check if the current path is the same as the given path
    const isActive = (path) => location.pathname === path;

    return (
        <div className="fixedtp30 mt-3">
            {/* Search Box */}
            <div className="chaintop">
                <span>
                    <Link 
                        to={`/conference-dashboard/${conferenceId}/conference-delegate-leads`} 
                        className={`spanli ${isActive(`/conference-dashboard/${conferenceId}/conference-delegate-leads`) ? "active1" : ""}`}
                    >
                        Delegate Leads
                    </Link>
                </span>
                <span>
                    <Link 
                        to={`/conference-dashboard/${conferenceId}/conference-speaker-leads`} 
                        className={`spanli ${isActive(`/conference-dashboard/${conferenceId}/conference-speaker-leads`) ? "active1" : ""}`}
                    >
                        Speaker Leads
                    </Link>
                </span>
                <span>
                    <Link 
                        to={`/conference-dashboard/${conferenceId}/conference-sponsor-leads`} 
                        className={`spanli ${isActive(`/conference-dashboard/${conferenceId}/conference-sponsor-leads`) ? "active1" : ""}`}
                    >
                        Sponsor Leads
                    </Link>
                </span>
                <span>
                    <Link 
                        to={`/conference-dashboard/${conferenceId}/conference-partner-leads`} 
                        className={`spanli ${isActive(`/conference-dashboard/${conferenceId}/conference-partner-leads`) ? "active1" : ""}`}
                    >
                        Partner Leads
                    </Link>
                </span>
                 
            </div>
        </div>
    );
};

export default ConferenceLeadsNavbar;
