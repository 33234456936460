import React, { useState, useEffect } from "react";
import LogoutBtn from "../component/LogoutBtn";
import { baseurl, imageUrl } from "../component/Baseurl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import defaulrevent from "../component/img/defaultevent.avif";
import "../component/css/commingsoon.css";

const UserAccess = () => {
  const [events, setEvents] = useState([]); // Exhibitions
  const [conferences, setConferences] = useState([]); // Conferences
  const [loadingExhibitions, setLoadingExhibitions] = useState(true);
  const [loadingConferences, setLoadingConferences] = useState(true);
  const [errorExhibitions, setErrorExhibitions] = useState(null);
  const [errorConferences, setErrorConferences] = useState(null);

  const orgId = localStorage.getItem("orgId");
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch exhibitions
    const fetchExhibitions = async () => {
      try {
        setLoadingExhibitions(true);
        const eventResponse = await axios.get(
          `${baseurl}AllExhibitions/${orgId}`
        );
        setEvents(eventResponse.data.exhibitions || []);
      } catch (err) {
        setErrorExhibitions(err.message || "Failed to fetch exhibitions");
      } finally {
        setLoadingExhibitions(false);
      }
    };

    // Fetch conferences
    const fetchConferences = async () => {
      try {
        setLoadingConferences(true);
        const conferenceResponse = await axios.get(
          `${baseurl}AllConferences/${orgId}`
        );
        setConferences(conferenceResponse.data.conferences || []);
      } catch (err) {
        // setErrorConferences(err.message || "Failed to fetch conferences");
      } finally {
        setLoadingConferences(false);
      }
    };

    fetchExhibitions();
    fetchConferences();
  }, [orgId]);

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  const handleEmptyState = () => {
    if (
      !loadingExhibitions &&
      !loadingConferences &&
      events.length === 0 &&
      conferences.length === 0
    ) {
      Swal.fire({
        icon: "info",
        title: "No Events Found",
        text: "Create Event or Conference Now",
        confirmButtonText: "Okay",
      }).then(() => {
        navigate("/event-format");
      });
    }
  };

  useEffect(() => {
    handleEmptyState();
  }, [loadingExhibitions, loadingConferences, events, conferences]);

  return (
    <div>
      <div className="d-flex tp-at align-items-center justify-content-between sticky-top">
        <div className="tp-h-tl">User Access</div>
        <div>
          <LogoutBtn />
        </div>
      </div>

      <div className="text-start">
        <h1 className="mt-3">User Access</h1>
      </div>

      <div className="container mt-4">
        <div className="row">
          <div className="crd">
            <div className="row g-2 mt-2">
              <div class="  asas">
                <h1 className="sdas">
                  coming soon<span class="dot">.</span>
                </h1>
                <p>User Access Module will Update Soon.</p>
                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAccess;
