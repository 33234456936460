import React, { useEffect, useState } from "react";
import axios from "axios";
import CountUp from "react-countup";
import { AlertCircle } from "react-feather";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { baseurl } from "../../component/Baseurl";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const LeadGenerated = ({ exhibitionId }) => {
  const [chartData, setChartData] = useState(null);
  const [chartType, setChartType] = useState("line");
  const [totalLeads, setTotalLeads] = useState(0);
  const [maxLead, setMaxLead] = useState({ date: "", count: 0 });
  const [minLead, setMinLead] = useState({ date: "", count: Infinity });
  const [isLoading, setIsLoading] = useState(true);

  const fetchLeadData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${baseurl}fetchFormSubmitCount/${exhibitionId}`);
      if (response.data.success && response.data.date_wise_counts) {
        const leadData = response.data.date_wise_counts;

        let labels = [];
        let data = [];
        let total = 0;
        let max = { date: "", count: 0 };
        let min = { date: "", count: Infinity };

        leadData.forEach((item, index) => {
          labels.push(item.date);
          data.push(item.count);
          total += item.count;
          if (item.count > max.count) max = { date: item.date, count: item.count };
          if (item.count < min.count) min = { date: item.date, count: item.count };
        });

        setTotalLeads(total);
        setMaxLead(max);
        setMinLead(min);

        setChartData({
          labels,
          datasets: [
            {
              label: "Leads Generated",
              data,
              backgroundColor: data.map((_, index) =>
                index % 2 === 0 ? "#435ebe" : "#ff416a"
              ),
              borderColor: "#fff",
              borderWidth: 2,
              pointRadius: 5,
              pointHoverRadius: 7,
              fill: true,
            },
          ],
        });
      } else {
        setChartData({
          labels: [],
          datasets: [],
        });
      }
    } catch (error) {
      console.error("Error fetching lead data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (exhibitionId) {
      fetchLeadData();
    }
  }, [exhibitionId]);

  if (isLoading) {
    return <div className="text-center my-5">Loading...</div>;
  }

  const chartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const leadCount = tooltipItem.raw;
            const percentage = ((leadCount / totalLeads) * 100).toFixed(2);
            return [
              `Leads on ${tooltipItem.label}: ${leadCount}`,
              `Percentage of Total: ${percentage}%`,
            ];
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Leads",
        },
        ticks: {
          stepSize: 50,
        },
      },
    },
  };

  return (
    <div className="container lead-generated-container">
    

      <div className="row">
        {/* Metrics Section */}
        <div className="col-md-4">
          <div style={{ marginBottom: "10px" }}>
            <div className="d-flex justify-content-between align-items-center">
              <label htmlFor="chartType" className="oneline">
                Select Chart Type : &nbsp;
              </label>
              <select
                id="chartType"
                value={chartType}
                className="form-control"
                onChange={(e) => setChartType(e.target.value)}
              >
                <option value="line">Line Chart</option>
                <option value="column">Bar Chart</option>
              </select>
            </div>
          </div>

          <div className="border rounded p-2 mb-1">
            <div className="d1">Total Leads:</div>
            <div>
              <span className="txtd2 txtcl1">
                <CountUp end={totalLeads} duration={2} />
              </span>
            </div>
          </div>

          <div className="border rounded p-2 mb-1">
            <div className="d1">Max Leads:</div>
            <div>
              <span className="txtd2 txtcl2">{maxLead.count || "N/A"}</span>
              &nbsp; <span className="d2"> on {maxLead.date}</span>
            </div>
          </div>

          <div className="border rounded p-2 mb-1">
            <div className="d1">Min Leads:</div>
            <div>
              <span className="txtd2 txtcl3">
                {minLead.count < Infinity ? minLead.count : "N/A"}
              </span>
              &nbsp; <span className="d3"> on {minLead.date}</span>
            </div>
          </div>
        </div>

        {/* Chart Section */}
        <div className="col-md-8">
          <div className="card">
            <div className="card-body">
              {chartData && chartData.labels.length > 0 ? (
                chartType === "line" ? (
                  <Line data={chartData} options={chartOptions} />
                ) : (
                  <Bar data={chartData} options={chartOptions} />
                )
              ) : (
                <div className="text-center">No data available for the selected exhibition.</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadGenerated;
