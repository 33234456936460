import React, { createContext, useContext, useState } from 'react';

// Create the WorkshopContext
const WorkshopContext = createContext();

export const WorkshopProvider = ({ children }) => {
  const [workshopId, setWorkshopId] = useState(null);

  return (
    <WorkshopContext.Provider value={{ workshopId, setWorkshopId }}>
      {children}
    </WorkshopContext.Provider>
  );
};

export const useWorkshop = () => {
  return useContext(WorkshopContext);
};
