import React, { useEffect, useState } from "react";
import Topbar from "../component/utils/Topbar";
import { Edit } from "react-feather";
import FooterTwo from "../component/utils/FooterTwo";
import { Link } from "react-router-dom";
import { baseurl } from "../component/Baseurl"; // Assume baseurl is your API base URL
import axios from "axios";

const AllBills = () => {
  const [bills, setBills] = useState([]); // State to store API data
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [recordsPerPage] = useState(10); // Number of records per page
  const orgid = localStorage.getItem("orgId");

  useEffect(() => {
    // Function to fetch bills by organization ID
    const fetchBills = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/getAllBillsByOrgId/${orgid}`
        );
        if (response.data.success) {
          setBills(response.data.data); // Populate bills from API
        } else {
          console.error("Failed to fetch bills");
        }
      } catch (error) {
        console.error("Error fetching bills:", error);
      }
    };

    fetchBills(); // Call the function to fetch data on component mount
  }, [orgid]);

  // Calculate the index of the first and last record on the current page
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  // Slice the bills array to show only the records for the current page
  const currentBills = bills.slice(indexOfFirstRecord, indexOfLastRecord);

  // Handle pagination page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate the total number of pages
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(bills.length / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <div>
        <Topbar />

        <div className="text-start">
          <h1 className="mt-3">All Bills</h1>
        </div>

        <div className="">
          <div className="row g-3">
            <div className="col-md-12">
              <div className="crd1">
                <div className="hd-1">Bills</div>
                <hr />

                {/* Table Headers */}
                <div className="row text-start tblrowhd m-0">
                  <div className="col">Order ID</div>
                  <div className="col">Created At</div>
                  <div className="col">Amount</div>
                  <div className="col">Package Name</div>
                  <div className="col">Billing Cycle</div>
                  <div className="col text-end">Action</div>
                </div>
                <hr />

                {/* Loop through the bills and display them */}
                {currentBills.length > 0 ? (
                  currentBills.map((bill, index) => (
                    <div
                      key={index}
                      className="row user-table-data tblrowtd text-start m-0"
                    >
                      <div className="col">{bill.order_id}</div>
                      <div className="col">
                        {new Date(bill.created_at)
                          .toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "2-digit",
                          })
                          .replace(",", "'")}
                      </div>

                      <div className="col">
                        {bill.currency}. {bill.amount}
                      </div>
                      <div className="col">{bill.package_name}</div>
                      <div className="col">{bill.billing_cycle}</div>
                      <div className="col text-end">
                        <Link
                          to={`/invoice?order_id=${bill.order_id}`}
                          target="_blank"
                          className="editbg"
                        >
                          <Edit className="ic1" />
                        </Link>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">No bills found</div>
                )}

                {/* Pagination Controls */}
                <div className="pagination-container">
                  <ul className="pagination">
                    {pageNumbers.map((number) => (
                      <li
                        key={number}
                        onClick={() => paginate(number)}
                        className={`page-item ${
                          currentPage === number ? "active" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(number)}
                          className={`pagi1 ${
                            currentPage === number ? "active" : ""
                          }`}
                        >
                          {number}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterTwo />
      </div>
    </>
  );
};

export default AllBills;
