import React, { useState, useEffect } from "react"; 
import { baseurl, imageUrl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link } from 'react-router-dom'; 
import { Edit, Trash2 } from "react-feather";  
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import { useExhibition } from "../../context/ExhibitionContext";
import Swal from 'sweetalert2'; // Import SweetAlert2 
import AddPartner from "../ExhibitionComponent/AddPartner";
import { ArrowRight, ArrowLeft } from "react-feather";
import ExhiibitionBreadcrum from "../ExhibitionComponent/ExhibitionBreadcrum";

const ExhibitionPartnerDetails = () => {
  const [partners, setPartners] = useState([]); // State to store partner details 
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [recordsPerPage, setRecordsPerPage] = useState(5); // State for records per page
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [exhibitionFormat, setExhibitionFormat] = useState("");   
  const { exhibitionId } = useExhibition(); 

  // Fetch partner details when the component mounts
  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get(`${baseurl}getPartners/${exhibitionId}`);
        if (response.data.success) {
          setPartners(response.data.partners); // Update state with fetched data
        } else {
          console.error("Failed to fetch partners");
        }
      } catch (error) {
        console.error("Error fetching partners:", error);
      }
    };

    fetchPartners();
  }, [exhibitionId]);

  // Fetch exhibition name and slug
  useEffect(() => {
    // Run the API call only if exhibitionId is available
    if (exhibitionId) {
      const fetchExhibitionName = async () => {
        try {
          const response = await axios.get(`${baseurl}getExhibitionName/${exhibitionId}`);
          if (response.data.success) {
            setExhibitionFormat(response.data.data.format);    
          } else {
            console.error("Failed to fetch exhibition name");
          }
        } catch (error) {
          console.error("Error fetching exhibition name:", error);
        }
      };

      fetchExhibitionName();
    }
  }, [exhibitionId]);

  // Handle partner deletion
  const handleDelete = (partnerId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          console.log("Sending delete request for partnerId:", partnerId); // Log the partnerId being sent
          const response = await axios.delete(`${baseurl}deletePartner/${partnerId}`);
          console.log("Delete response:", response); // Log the response from the server
          
          if (response.data.success) {
            setPartners((prevPartners) =>
              prevPartners.filter((partner) => partner.id !== partnerId)
            );
            Swal.fire('Deleted!', 'The partner has been deleted.', 'success');
          } else {
            Swal.fire('Error!', 'There was an issue deleting the partner.', 'error');
          }
        } catch (error) {
          console.error('Error deleting partner:', error);
          Swal.fire('Error!', 'There was an issue deleting the partner.', 'error');
        }
      }
    });
  };

  // Handle change in records per page
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when records per page changes
  };

  // Filter partners based on search term
  const filteredPartners = partners.filter((partner) =>
    partner.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    partner.type.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate the current partners to display
  const indexOfLastPartner = currentPage * recordsPerPage;
  const indexOfFirstPartner = indexOfLastPartner - recordsPerPage;
  const currentPartners = filteredPartners.slice(indexOfFirstPartner, indexOfLastPartner);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredPartners.length / recordsPerPage);

  return (
    <div>
      <ExhiibitionBreadcrum />
      <ExhibitionContentNavbar />
      <AddPartner />

      <div className="container mt-4">
        {/* Partner List Section */}
        <div className="row">
          <div className="crd">
            <div className="d-flex justify-content-between align-items-center mb-0 py-2">
              <div>
                <input
                  type="text"
                  className="form-control w-100 min550"
                  placeholder="Search by Name, Partner Type..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div>
                <select
                  className="form-control"
                  value={recordsPerPage}
                  onChange={handleRecordsPerPageChange}
                >
                  <option value={10}>10 Records</option>
                  <option value={20}>20 Records</option>
                  <option value={50}>50 Records</option>
                  <option value={100}>100 Records</option>
                  <option value={filteredPartners.length}>All Records</option>
                </select>
              </div>
            </div>
            
            <hr />

            {/* Table Structure */}
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Logo</th>
                  <th>Name</th>
                  <th>Partner Type</th>
                  <th className="text-end">Action</th>
                </tr>
              </thead>
              <tbody>
                {/* Table Data */}
                {currentPartners.length > 0 ? (
                  currentPartners.map((partner) => (
                    <tr key={partner.id}>
                      <td>
                        {partner.profile_image ? (
                          <img src={`${imageUrl}/partnerFiles/${partner.profile_image}`} alt={partner.name} width="50" />
                        ) : (
                          <span>No Logo</span>
                        )}
                      </td>
                      <td>{partner.name}</td>
                      <td>{partner.type}</td>
                      <td className="text-end">
                        <span onClick={() => handleDelete(partner.id)}>
                          <Trash2 className="ic1" />
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">No partners found.</td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination and Records Per Page */}
            <div className="row">
              <div className="col d-flex justify-content-end align-items-center">
                <div>
                  {[...Array(totalPages).keys()].map((pageNumber) => (
                    <span
                      key={pageNumber}
                      className={`pagi1 me-1 ${currentPage === pageNumber + 1 ? 'active' : ''}`}
                      onClick={() => paginate(pageNumber + 1)}
                      style={{ cursor: 'pointer' }}
                    >
                      {pageNumber + 1}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3 mb-3 d-flex justify-content-between align-items-center">
              <span className="">
                <Link
                  to={`/exhibition-dashboard/${exhibitionId}/sponser-details`}
                  className="btnn mybtn"
                >
                  <ArrowLeft className="ic text-white" />
                  Back
                </Link>
              </span>
              <span className="">
              <Link
                  to={`/exhibition-dashboard/${exhibitionId}/${exhibitionFormat === "ExhibitionConference" ? "conference-details" : "speaker-details"}`}
                  className="btnn mybtn"
                >
                  Next <ArrowRight className="ic text-white" />
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionPartnerDetails;
