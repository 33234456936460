import React from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import { Search, LogOut as UsersIcon } from "react-feather";  
import { useConference } from "../../context/ConferenceContext"; 
const ConferenceCommunicationNavbar = () => { 

    const { conferenceId } = useConference();
    const location = useLocation(); // Get the current location

    // Function to check if the current path is the same as the given path
    const isActive = (path) => location.pathname === path;

    return (
        <div className="fixedtp30 mt-3">
            {/* Search Box */}
            <div className="chaintop">
                <span>
                    <Link 
                        to={`/conference-dashboard/${conferenceId}/conference-rating`} 
                        className={`spanli ${isActive(`/conference-dashboard/${conferenceId}/conference-rating`) ? "active1" : ""}`}
                    >
                        Rating & Reviews
                    </Link>
                </span>
                <span>
                    <Link 
                        to={`/conference-dashboard/${conferenceId}/conference-followers`} 
                        className={`spanli ${isActive(`/conference-dashboard/${conferenceId}/conference-followers`) ? "active1" : ""}`}
                    >
                        Event Followers
                    </Link>
                </span>


                <span>
                    <Link 
                        to={`/conference-dashboard/${conferenceId}/conference-request-query`} 
                        className={`spanli ${isActive(`/conference-dashboard/${conferenceId}/conference-request-query`) ? "active1" : ""}`}
                    >
                        Guest Request/Query
                    </Link>
                </span>


                 
                 
            </div>
        </div>
    );
};

export default ConferenceCommunicationNavbar;
