import React, { useEffect, useState } from "react";
import axios from "axios"; // Import Axios
import { baseurl } from "../Baseurl";

const LineChart = ({ orgid }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [visitorData, setVisitorData] = useState([]);

  // Fetch visitor count data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/getVisitorCountbyOrgId/${orgid}`);
        setVisitorData(response.data.visitorCounts); // Set visitor data from API
        setIsLoaded(true);
      } catch (error) {
        console.error("Failed to fetch visitor data:", error);
      }
    };

    fetchData();
  }, [orgid]);

  useEffect(() => {
    if (isLoaded && window.JSC) {
      const points = visitorData.map((data) => ({
        x: data.exhibition_name, // Use exhibition name as x-axis label
        y: parseInt(data.count),  // Convert the count to an integer for y-axis
      }));

      window.JSC.chart("line-chart-container", {
        type: "line",
        title_label_text: "Visitors Over Time (Line Chart)",
        xAxis: {
          label_text: "Event", // Label x-axis as 'Exhibition Name'
        },
        yAxis: {
          label_text: "Number of Visitors",
        },
        series: [
          {
            name: "Visitors",
            points: points,
          },
        ],
      });
    }
  }, [isLoaded, visitorData]);

  return <div id="line-chart-container" style={{ height: "300px", width: "100%" }}></div>;
};

export default LineChart;
