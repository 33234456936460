import React, { useState, useEffect } from "react";
import { baseurl, imageUrl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link } from "react-router-dom";
import { Edit, Trash2 } from "react-feather";
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import AddExhibitor from "../ExhibitionComponent/AddExhibitor";
import { useExhibition } from "../../context/ExhibitionContext";
import Swal from "sweetalert2"; // Import SweetAlert2
import { ArrowRight, ArrowLeft } from "react-feather";
import ExhiibitionBreadcrum from "../ExhibitionComponent/ExhibitionBreadcrum";

const ExhibitionExhibitorDetails = () => {
  const [exhibitors, setExhibitors] = useState([]); // State to store exhibitor details
  const [filteredExhibitors, setFilteredExhibitors] = useState([]); // State for filtered exhibitors
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [recordsPerPage, setRecordsPerPage] = useState(5); // State for records per page
  const { exhibitionId } = useExhibition();

  // Fetch exhibitor details when the component mounts
  useEffect(() => {
    // Only run the API call if exhibitionId is valid (not null or undefined)
    if (!exhibitionId) {
      // console.error("Exhibition ID is missing");
      return; // Exit early if exhibitionId is not available
    }
  
    const fetchExhibitors = async () => {
      try {
        const response = await axios.get(`${baseurl}getExhibitors/${exhibitionId}`);
        if (response.data.success) {
          setExhibitors(response.data.exhibitors); // Update state with fetched data
          setFilteredExhibitors(response.data.exhibitors); // Set filtered exhibitors initially to all exhibitors
        } else {
          console.error("Failed to fetch exhibitors");
        }
      } catch (error) {
        console.error("Error fetching exhibitors:", error);
      }
    };
  
    // Call fetchExhibitors only if exhibitionId is available
    fetchExhibitors();
  }, [exhibitionId]); // Dependency array ensures it runs only when exhibitionId changes
  

  // Handle exhibitor deletion
  const handleDelete = (exhibitorId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(
            `${baseurl}deleteExhibitor/${exhibitorId}`
          );

          // console.log("Delete response:", response);  

          if (response.data.success) {
            setExhibitors((prevExhibitors) =>
              prevExhibitors.filter((exhibitor) => exhibitor.id !== exhibitorId)
            );
            setFilteredExhibitors((prevFilteredExhibitors) =>
              prevFilteredExhibitors.filter(
                (exhibitor) => exhibitor.id !== exhibitorId
              )
            );
            Swal.fire("Deleted!", "The exhibitor has been deleted.", "success");
          } else {
            Swal.fire(
              "Error!",
              "There was an issue deleting the exhibitor.",
              "error"
            );
          }
        } catch (error) {
          console.error("Error deleting exhibitor:", error);
          Swal.fire(
            "Error!",
            "There was an issue deleting the exhibitor.",
            "error"
          );
        }
      }
    });
  };

  // Handle change in records per page
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when records per page changes
  };

  // Filter exhibitors based on search term
  useEffect(() => {
    const filtered = exhibitors.filter(
      (exhibitor) =>
        exhibitor.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
        exhibitor.website.toLowerCase().includes(searchTerm.toLowerCase()) ||
        exhibitor.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        exhibitor.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
        exhibitor.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
        exhibitor.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        exhibitor.booth_no.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredExhibitors(filtered);
    setCurrentPage(1); // Reset to the first page when search term changes
  }, [searchTerm, exhibitors]);

  // Calculate the current exhibitors to display
  const indexOfLastExhibitor = currentPage * recordsPerPage;
  const indexOfFirstExhibitor = indexOfLastExhibitor - recordsPerPage;
  const currentExhibitors = filteredExhibitors.slice(
    indexOfFirstExhibitor,
    indexOfLastExhibitor
  );

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredExhibitors.length / recordsPerPage);

  return (
    <div>
      <ExhiibitionBreadcrum />
      <ExhibitionContentNavbar />
      <AddExhibitor />

      <div className="container mt-4">
        {/* Exhibitor List Section */}
        <div className="row">
          <div className="crd">
            <div className="d-flex justify-content-between align-items-center mb-0 py-2">
              <div className="">
                <input
                  type="text"
                  name="company"
                  className="form-control w-100 min550"
                  placeholder="Search by company, website, category, location, Email, Booth No."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div>
                <select
                  className="form-control"
                  value={recordsPerPage}
                  onChange={handleRecordsPerPageChange}
                > 
                  {/* <option value={1}>1 Records</option> */}
                  <option value={10}>10 Records</option>
                  <option value={20}>20 Records</option>
                  <option value={50}>50 Records</option>
                  <option value={100}>100 Records</option>
                  <option value={500}>500 Records</option>
                  <option value={filteredExhibitors.length}>All Records</option>
                </select>
              </div>
            </div>

            <hr />

            {/* Table Structure */}
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="text-start">
                  <th>Logo</th>
                  <th>Company</th>
                  <th>Website</th>
                  <th>Category</th>
                  <th>Location</th>
                  <th>Email</th>
                  <th>Booth No</th>
                  <th className="text-end">Action</th>
                </tr>
              </thead>
              <tbody>
                {/* Table Data */}
                {currentExhibitors.length > 0 ? (
                  currentExhibitors.map((exhibitor) => (
                    <tr key={exhibitor.id} className="user-table-data text-start">
                      <td>
                        {exhibitor.logo ? (
                          <img
                            src={`${imageUrl}exhibitorFiles/${exhibitor.logo}`}
                            alt={exhibitor.company}
                            width="50"
                          />
                        ) : (
                          <span>No Image</span>
                        )}
                      </td>
                      <td>{exhibitor.company}</td>
                      <td>{exhibitor.website}</td>
                      <td>{exhibitor.category}</td>
                      <td>
                        {exhibitor.address || "N/A"}, {exhibitor.city || "N/A"},
                        {exhibitor.country || "N/A"}
                      </td>
                      <td>{exhibitor.email || "N/A"}</td>
                      <td>{exhibitor.booth_no || "N/A"}</td>
                      <td className="text-end">
                        {/* <Link
                          to={`/exhibition-dashboard/${exhibitionId}/edit-exhibitor`}
                          className="editbg"
                        >
                          <Edit className="ic1" />
                        </Link> */}
                        <span onClick={() => handleDelete(exhibitor.id)}>
                          <Trash2 className="ic1" />
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No exhibitors found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination and Records Per Page */}
<div className="row">
  <div className="col d-flex justify-content-end align-items-center">
    {/* Pagination Controls */}
    <div>
      {/* <span
        className={`pagi1 ${currentPage === 1 ? 'disabled' : ''}`}
        onClick={() => paginate(currentPage - 1)}
        style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
      >
        Previous
      </span> */}
      {[...Array(totalPages).keys()].map((pageNumber) => (
        <span
          key={pageNumber}
          className={`pagi1 me-1 ${currentPage === pageNumber + 1 ? 'active' : ''}`}
          onClick={() => paginate(pageNumber + 1)}
          style={{ cursor: 'pointer' }}
        >
          {pageNumber + 1}
        </span>
      ))}
      {/* <span
        className={`pagi1 ${currentPage === totalPages ? 'disabled' : ''}`}
        onClick={() => paginate(currentPage + 1)}
        style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
      >
        Next
      </span> */}
    </div>
  </div>
</div>


            <div className="col-md-12 mt-3 mb-5 d-flex justify-content-between align-items-center">
              <span className="">
                <Link
                  to={`/exhibition-dashboard/${exhibitionId}/exhibition-brochure`}
                  className="btnn mybtn"
                >
                  <ArrowLeft className="ic text-white" />
                  Back
                </Link>
              </span>
              <span className="">
                <Link
                  to={`/exhibition-dashboard/${exhibitionId}/sponser-details`}
                  className="btnn mybtn"
                >
                  Next <ArrowRight className="ic text-white" />
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionExhibitorDetails;
