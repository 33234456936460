import React from "react"; 

const BadgeOne = () => {
  return (
    <div className="badge-container">
      <div className="badge-header">
        <img
          src="https://via.placeholder.com/150x50" // Replace with the actual logo URL
          alt="IEW Logo"
          className="badge-logo"
        />
        <div className="badge-details">
          <h3>India Energy Week 2024</h3>
          <p>6-9 February 2024 | ONGC, Goa | India</p>
          <p>Exhibition | Conference</p>
        </div>
      </div>
      <div className="badge-subtext">
        <p>Growth. Collaboration. Transition.</p>
      </div>
      <div className="badge-body">
        <h2 className="badge-name">ANUP HANMANTE</h2>
        <p className="badge-title">District Service Manager</p>
      </div>
      <div className="badge-footer">
        <div className="badge-qr">
          <img
            src="https://via.placeholder.com/50x50" // Replace with actual QR code URL
            alt="QR Code"
          />
        </div>
        <div className="badge-role">Technical Delegate</div>
      </div>
    </div>
  );
};

export default BadgeOne;
