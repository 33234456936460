import React, { useState, useEffect } from "react";
import { baseurl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { Eye } from "react-feather"; // Eye icon for viewing details
import { useExhibition } from "../../context/ExhibitionContext";
import ExhibitionCommunicationNavbar from "../utils/ExhibitionCommunicationNavbar";
import Swal from "sweetalert2";

const ExhibitionGuestRequest = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [queries, setQueries] = useState([]); // State to store event queries
  const [loading, setLoading] = useState(true); // State to manage loading
  const [selectedQuery, setSelectedQuery] = useState(null); // State to store selected query for modal
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility
  const [filter, setFilter] = useState("all"); // Filter state
  const { exhibitionId } = useExhibition(); // Get exhibitionId from context

  const location = useLocation();
  // Function to check if the current path is the same as the given path
  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    // Fetch event queries when the component mounts
    const fetchEventQueries = async () => {
      try {
        const response = await axios.get(
          `${baseurl}getallquery/${exhibitionId}`
        );

        if (response.data.success) {
          setQueries(response.data.data); // Set queries in the state
        }
      } catch (error) {
        // console.error("Error fetching event queries:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchEventQueries();
  }, [exhibitionId]); // Re-run the effect if exhibitionId changes

  // Filter the records based on the selected filter
  const filteredRecords = queries.filter((query) => {
    if (filter === "unread") return query.query_status === "unread";
    if (filter === "read") return query.query_status === "read";
    return true; // Show all if filter is "all"
  });

  // Calculate the index of the first and last records for the current page
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  // Slice the filtered records array to get only the records for the current page
  const currentRecords = filteredRecords.slice(indexOfFirstRecord, indexOfLastRecord);

  // Handle pagination button click
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle opening the modal with selected query details
  const handleViewDetails = (query) => {
    setSelectedQuery(query); // Set the selected query
    setModalOpen(true); // Open the modal
  };

  // Handle closing the modal
  const closeModal = () => {
    setModalOpen(false); // Close the modal
    setSelectedQuery(null); // Clear the selected query
  };

  const markAsRead = async (queryId) => {
    try {
      const response = await axios.post(`${baseurl}updateQueryStatusById`, { id: queryId });
      if (response.data.success) {
        setQueries((prevQueries) =>
          prevQueries.map((query) =>
            query.id === queryId ? { ...query, query_status: "read" } : query
          )
        );

        // Show SweetAlert2 success message
        Swal.fire({
          title: "Success!",
          text: "Marked as read successfully.",
          icon: "success",
          confirmButtonText: "OK",
          timer: 2000, // Auto-close after 2 seconds
        });
      } else {
        console.error("Failed to mark as read");
      }
    } catch (error) {
      console.error("Error marking as read:", error);
    }
  };

  return (
    <div>
      {!isActive(`/exhibition-dashboard/${exhibitionId}/stats`) && (
        <ExhibitionCommunicationNavbar />
      )}

      <div className="container">
        <div className="hd-1">
          Guest Request/Query
          <span className="tooltip-text">This is your Speaker</span>
        </div>

        <div className="row">
          <div className="crd py-1">
            <div className="d-flex justify-content-end align-items-center mb-2">
              <div className="me-2">
                <label htmlFor="chartType">Filter: </label>
              </div>
              <div className="me-1">
                <select
                  id="chartType"
                  className="form-control"
                  onChange={(e) => setFilter(e.target.value)} // Update filter state
                >
                  <option value="all">Show All Request</option>
                  <option value="unread">Show Unread Request</option>
                  <option value="read">Show Read Request</option>
                </select>
              </div>
            </div>

            {loading ? (
              <div>Loading...</div>
            ) : (
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Company</th> 
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRecords.map((query) => (
                    <tr
                      key={query.id}
                      className={query.query_status === "read" ? "faded-row" : ""}
                    >
                      <td>{query.name}</td>
                      <td>{query.email}</td>
                      <td>{query.mobile}</td>
                      <td>{query.company}</td> 
                      <td>{new Date(query.created_at).toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" })}</td>

                      <td>
                        <span
                          className="ms-2 mybt1"
                          onClick={() => handleViewDetails(query)}
                        >
                          <Eye size={17} />
                          &nbsp;View
                        </span>

                        {query.query_status === "unread" && (
                          <span
                            className="ms-2 mybt1"
                            onClick={() => markAsRead(query.id)}
                          >
                            Mark As Read
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {!isActive(`/exhibition-dashboard/${exhibitionId}/stats`) && (
              <nav>
                <ul className="pagination">
                  {[...Array(Math.ceil(filteredRecords.length / recordsPerPage))].map(
                    (_, index) => (
                      <li key={index} className="page-item">
                        <button
                          className="page-link"
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    )
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>

      {modalOpen && selectedQuery && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Query Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body text-start">
                <p className="text-start">
                  <strong>Name:</strong> {selectedQuery.name}
                </p>
                <p className="text-start">
                  <strong>Email:</strong> {selectedQuery.email}
                </p>
                <p className="text-start">
                  <strong>Mobile:</strong> {selectedQuery.mobile}
                </p>
                <p className="text-start">
                  <strong>Company:</strong> {selectedQuery.company}
                </p>
                <p className="text-start">
                  <strong>Message:</strong> {selectedQuery.message}
                </p>
                <p className="text-start">
                  <strong>Created At:</strong>{" "}
                  {new Date(selectedQuery.created_at).toLocaleString()}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExhibitionGuestRequest;
