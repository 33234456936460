import React, { useState } from "react";
import { useExhibition } from "../../context/ExhibitionContext"; // Import the context hook
import { useNavigate } from 'react-router-dom'; // Import navigate hook
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2'; // Import SweetAlert2 for the confirmation dialog
import { baseurl, imageUrl } from "../../component/Baseurl";

const AddExhibitor = () => {
  const { exhibitionId } = useExhibition(); // Assuming org_id is also available in context
  const navigate = useNavigate(); // For navigation after submission

  // State for form fields
  const [exhibitorDetails, setExhibitorDetails] = useState({
    company: "",
    website: "",
    category: "",
    address: "",
    city: "",
    country: "",
    email: "",
    boothNo: "",
    logo: null, // To store the selected logo
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExhibitorDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Handle file input (Logo)
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setExhibitorDetails((prevDetails) => ({
      ...prevDetails,
      logo: file,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append("company", exhibitorDetails.company);
    formData.append("website", exhibitorDetails.website);
    formData.append("category", exhibitorDetails.category);
    formData.append("address", exhibitorDetails.address);
    formData.append("city", exhibitorDetails.city);
    formData.append("country", exhibitorDetails.country);
    formData.append("email", exhibitorDetails.email);
    formData.append("boothNo", exhibitorDetails.boothNo);
    if (exhibitorDetails.logo) {
      formData.append("logo", exhibitorDetails.logo);
    }
    formData.append("exhibitionId", exhibitionId);
  
    try {
      const response = await fetch(`${baseurl}/insertExhibitorDetails`, {
        method: "POST",
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          Swal.fire({
            title: "Success",
            text: "The exhibitor has been added successfully.",
            icon: "success",
          });
          toast.success("Exhibitor added successfully!");
  
          // Reset the form
          setExhibitorDetails({
            company: "",
            website: "",
            category: "",
            address: "",
            city: "",
            country: "",
            email: "",
            boothNo: "",
            logo: null,
          });
  
          // Reload the page after successful submission
          window.location.reload();
        } else {
          toast.error(`Failed to add exhibitor: ${data.error || 'Unknown error'}`);
        }
      } else {
        const error = await response.text();
        toast.error(`Failed to add exhibitor: ${error}`);
      }
    } catch (error) {
      console.error("Error adding exhibitor:", error);
      toast.error("Something went wrong. Please try again later.");
    }
  
    // Close the modal after form submission
    window.bootstrap.Modal.getInstance(document.getElementById('addExhibitorModal')).hide();
  };
  

  // Preview logo
  const renderImagePreview = () => {
    if (exhibitorDetails.logo) {
      return (
        <div className="mt-3">
          <img
            src={URL.createObjectURL(exhibitorDetails.logo)}
            alt="Logo Preview"
            className="img-fluid"
            style={{ maxWidth: '150px' }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {/* Button to open the modal */}
      <div className="d-flex justify-content-between">
        <span><h1 className="mt-3">Exhibitor List</h1></span>
        <button
          type="button"
          className="btnn mt-0"
          data-bs-toggle="modal"
          data-bs-target="#addExhibitorModal"
        >
          Add Exhibitor
        </button>
      </div>

      {/* Modal to add Exhibitor */}
      <div
        className="modal fade"
        id="addExhibitorModal"
        tabIndex="-1"
        aria-labelledby="addExhibitorModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addExhibitorModalLabel">Add Exhibitor</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="company">Company<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="company"
                    className="form-control"
                    value={exhibitorDetails.company}
                    onChange={handleInputChange}
                    placeholder="Enter Company Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="website">Website<span className="text-danger">*</span></label>
                  <input
                    type="url"
                    name="website"
                    className="form-control"
                    value={exhibitorDetails.website}
                    onChange={handleInputChange}
                    placeholder="Enter Website URL"
                    required
                  />
                  <small className="text-danger smallsize mb-2">*Website Link Must Contains http:// or https://*</small>

                </div>
                <div className="form-group">
                  <label htmlFor="category">Category<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="category"
                    className="form-control"
                    value={exhibitorDetails.category}
                    onChange={handleInputChange}
                    placeholder="Enter Category"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address">Address<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    value={exhibitorDetails.address}
                    onChange={handleInputChange}
                    placeholder="Enter Address"
                    required
                  />
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="city">City<span className="text-danger">*</span></label>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      value={exhibitorDetails.city}
                      onChange={handleInputChange}
                      placeholder="Enter City"
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="country">Country<span className="text-danger">*</span></label>
                    <input
                      type="text"
                      name="country"
                      className="form-control"
                      value={exhibitorDetails.country}
                      onChange={handleInputChange}
                      placeholder="Enter Country"
                      required
                    />
                  </div>

                  <div className="form-group col-md-6">
                  <label htmlFor="email">Email<span className="text-danger">*</span></label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={exhibitorDetails.email}
                    onChange={handleInputChange}
                    placeholder="Enter Email Address"
                    required
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="boothNo">Booth No</label>
                  <input
                    type="text"
                    name="boothNo"
                    className="form-control"
                    value={exhibitorDetails.boothNo}
                    onChange={handleInputChange}
                    placeholder="Enter Booth No"
                  />
                </div>

                
                </div>
                
                <div className="form-group">
                  <label htmlFor="logo">Logo</label>
                  <input
                    type="file"
                    name="logo"
                    className="form-control"
                    onChange={handleFileChange}
                  />
                  {renderImagePreview()} {/* Display logo preview */}
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btnn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default AddExhibitor;
