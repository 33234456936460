import React, { useState, useEffect } from "react";
import { baseurl, imageUrl } from "../../component/Baseurl";
import axios from "axios";
import { Link } from 'react-router-dom';
import { Edit, Trash2 } from "react-feather";
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import { useExhibition } from "../../context/ExhibitionContext";
import Swal from 'sweetalert2';
import AddSponser from "../ExhibitionComponent/AddSponser";
import { ArrowRight, ArrowLeft } from "react-feather";
import ExhiibitionBreadcrum from "../ExhibitionComponent/ExhibitionBreadcrum";

const ExhibitionSponserDetails = () => {
  const [sponsors, setSponsors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const { exhibitionId } = useExhibition();

  useEffect(() => {
    const fetchSponsors = async () => {
      try {
        const response = await axios.get(`${baseurl}getSponsers/${exhibitionId}`);
        if (response.data.success) {
          setSponsors(response.data.sponsors);
        } else {
          console.error("Failed to fetch sponsors");
        }
      } catch (error) {
        console.error("Error fetching sponsors:", error);
      }
    };

    fetchSponsors();
  }, [exhibitionId]);

  const handleDelete = (sponsorId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`${baseurl}deleteSponser/${sponsorId}`);
          if (response.data.success) {
            setSponsors((prevSponsors) =>
              prevSponsors.filter((sponsor) => sponsor.id !== sponsorId)
            );
            Swal.fire('Deleted!', 'The sponsor has been deleted.', 'success');
          } else {
            Swal.fire('Error!', 'There was an issue deleting the sponsor.', 'error');
          }
        } catch (error) {
          console.error('Error deleting sponsor:', error);
          Swal.fire('Error!', 'There was an issue deleting the sponsor.', 'error');
        }
      }
    });
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const filteredSponsors = sponsors.filter((sponsor) =>
    sponsor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (sponsor.title && sponsor.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (sponsor.website && sponsor.website.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const indexOfLastSponsor = currentPage * recordsPerPage;
  const indexOfFirstSponsor = indexOfLastSponsor - recordsPerPage;
  const currentSponsors = filteredSponsors.slice(indexOfFirstSponsor, indexOfLastSponsor);

  const totalPages = Math.ceil(filteredSponsors.length / recordsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <ExhiibitionBreadcrum />
      <ExhibitionContentNavbar />
      <AddSponser />

      <div className="container mt-4">
        <div className="row">
          <div className="crd">
            <div className="d-flex justify-content-between align-items-center mb-0 py-2">
              <div>
                <input
                  type="text"
                  className="form-control w-100 min550"
                  placeholder="Search by Name, Title, Website..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div>
                <select
                  className="form-control"
                  value={recordsPerPage}
                  onChange={handleRecordsPerPageChange}
                >
                  <option value={10}>10 Records</option>
                  <option value={20}>20 Records</option>
                  <option value={50}>50 Records</option>
                  <option value={100}>100 Records</option>
                  <option value={filteredSponsors.length}>All Records</option>
                </select>
              </div>
            </div>
            <hr />

            {/* Table Layout */}
      <table className="table table-bordered">
        <thead>
          <tr className="tblrowhd">
            <th scope="col">Logo</th>
            <th scope="col">Name</th>
            <th scope="col">Title</th>
            <th scope="col">Website</th>
            <th scope="col" className="text-end">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentSponsors.length > 0 ? (
            currentSponsors.map((sponsor) => (
              <tr key={sponsor.id} className="tblrowtd">
                <td>
                  {sponsor.profile_image ? (
                    <img
                      src={`${imageUrl}/sponsorFiles/${sponsor.profile_image}`}
                      alt={sponsor.name}
                      width="50"
                    />
                  ) : (
                    <span>No Image</span>
                  )}
                </td>
                <td>{sponsor.name}</td>
                <td>{sponsor.title}</td>
                <td>
                  <a href={sponsor.website} target="_blank" rel="noopener noreferrer">
                    {sponsor.website}
                  </a>
                </td>
                <td className="text-end">
                  {/* <Link
                    to={`/exhibition-dashboard/${exhibitionId}/edit-sponsor`}
                    className="editbg"
                  >
                    <Edit className="ic1" />
                  </Link> */}
                  <span onClick={() => handleDelete(sponsor.id)}>
                    <Trash2 className="ic1" />
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No sponsors found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

            <div className="row">
              <div className="col d-flex justify-content-end align-items-center">
                <div>
                  {[...Array(totalPages).keys()].map((pageNumber) => (
                    <span
                      key={pageNumber}
                      className={`pagi1 me-1 ${currentPage === pageNumber + 1 ? 'active' : ''}`}
                      onClick={() => paginate(pageNumber + 1)}
                      style={{ cursor: 'pointer' }}
                    >
                      {pageNumber + 1}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-5 mb-3 d-flex justify-content-between align-items-center">
              <span>
                <Link
                  to={`/exhibition-dashboard/${exhibitionId}/exhibitor-details`}
                  className="btnn mybtn"
                >
                  <ArrowLeft className="ic text-white" />
                  Back
                </Link>
              </span>
              <span>
                <Link
                  to={`/exhibition-dashboard/${exhibitionId}/partner-details`}
                  className="btnn mybtn"
                >
                  Next <ArrowRight className="ic text-white" />
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionSponserDetails;
