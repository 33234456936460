import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseurl } from "../component/Baseurl";
import Swal from 'sweetalert2';

const PayButton = ({ packageId, currency, amount, packageName, billingCycle }) => {
  const [razorpayLoaded, setRazorpayLoaded] = useState(false);

  const organiserId = localStorage.getItem("orgId");
  console.log("Organiser ID:", organiserId);

  console.log("Package ID:", packageId);
  console.log("Currency:", currency);
  console.log("Amount:", amount); // This should be "400" (string)
  console.log("Package Name:", packageName);
  console.log("Billing Cycle:", billingCycle);

  currency = currency.replace(/^"|"$/g, '');
  console.log("Currency new:", currency);

  const numericAmount = parseInt(amount.replace(/^0+/, ''), 10);
  console.log("numericAmount:", numericAmount);

  // Load Razorpay script dynamically
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;

    script.onload = () => {
      setRazorpayLoaded(true);
      console.log("Razorpay script loaded successfully.");
    };

    script.onerror = () => {
      console.error("Failed to load Razorpay script.");
    };

    document.body.appendChild(script);

    // Clean up script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = async () => {
    if (!razorpayLoaded) {
      console.error("Razorpay script is not loaded yet.");
      return;
    }

    try {
      // Dummy request data for testing
      const requestData = {
        amount: numericAmount, // Amount in INR
        currency: currency,
        packageName: packageName,
        packageId: packageId,
        billingCycle: billingCycle,
        organiserId: organiserId,
      };

      console.log("Request Data:", requestData);

      // Create payment order on the server
      const { data } = await axios.post(`${baseurl}payment/order`, requestData);
      console.log("Order Response:", data);

      const { orderId, paymentMasterId } = data; // Extract orderId and paymentMasterId from server response

      // Razorpay options
      const options = {
        key: "rzp_test_doqilf4l91yMln", // Replace with your Razorpay key_id
        amount: numericAmount * 100, // Amount in paise (e.g., 500 INR = 50000 paise)
        currency: currency,
        name: "Test Company", // Dummy company name
        description: "Test Transaction for Razorpay",
        order_id: orderId, // Order ID from server
        handler: async function (response) {
          console.log("Payment successful:", response);

          // Prepare payment data to update status and verify payment
          const paymentData = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            paymentMasterId: paymentMasterId, // Include the paymentMasterId received from the server
          };


          console.log("payupadte", paymentData);

          // Step 1: Update the payment status
          try {
            const statusResponse = await axios.post(`${baseurl}payment/status`, paymentData);
            console.log("Payment Status Updated:", statusResponse.data);

            // Step 2: Verify payment
            Swal.fire({
                icon: 'success',
                title: 'Payment Successful',
                text: 'Your payment has been successfully verified!',
                confirmButtonText: 'Go to Invoice',
              }).then((result) => {
                if (result.isConfirmed) {
                  // Navigate to the invoice page with the razorpay_order_id as URL parameter
                  window.location.href = `/invoice?order_id=${response.razorpay_order_id}`;
                }
              });

          } catch (error) {
            console.error("Error while updating payment status or verifying payment:", error);
            alert("Payment Verification Failed.");
          }
        },
        prefill: {
          name: "John Doe", // Dummy name
          email: "johndoe@example.com", // Dummy email
          contact: "9876543210", // Dummy contact
        },
        theme: {
          color: "#3399cc",
        },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error("Payment Error:", error.response ? error.response.data : error.message);
    }
  };

  return <button onClick={handlePayment} className="btnn w-100">CheckOut</button>;
};

export default PayButton;
