// src/component/ExhibitionComp/ExhibitionStats.js
import React, { useState, useEffect } from "react";
import { RefreshCw } from "react-feather";
import PageView from "../ExhibitionComponent/PageView";
import LeadGenerated from "../ExhibitionComponent/LeadGenerated";
import ConferenceReportNavbar from "../utils/ConferenceReportNavbar"; 
import { useConference } from "../../context/ConferenceContext"; 
import ConferencePageView from "../ConferenceComponent/ConferencePageView";

const ConferenceAnalyticReport = () => { 
 const { conferenceId } = useConference(); 
  // State for loader
  const [loading, setLoading] = useState(true);

  // Function to reload the current page
  const reloadPage = () => {
    window.location.reload();
  };

  // Simulate a 1-second loading delay when the component mounts
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  return (
    <div style={{ position: "" }}>
      {loading && (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
      {!loading && (
        <>
          <ConferenceReportNavbar />

          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1 className="mt-3">Analytics Report</h1>
            </div>
            <div>
              <span className="downloadbtn cl4 text-white" onClick={reloadPage}>
                <RefreshCw size={20} /> Reload Data
              </span>
            </div>
          </div>

          <div className="row g-3 mb-3">
            <div className="col-md-12">
              <div className="crd1"> 
                <ConferencePageView exhibitionId={conferenceId}/>
              </div>
            </div>

            <div className="col-md-12">
              <div className="crd1">
                <LeadGenerated exhibitionId={conferenceId} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ConferenceAnalyticReport; 
