import React, { useState } from "react";
import { useExhibition } from "../../context/ExhibitionContext"; // Import the context hook
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2"; // Import SweetAlert2 for confirmation dialog
import axios from "axios"; // Import axios for API calls
import { baseurl } from "../../component/Baseurl";

const AddPartner = () => {
  const { exhibitionId } = useExhibition(); // Assuming exhibitionId is available in context

  // State for form fields
  const [partnerDetails, setPartnerDetails] = useState({
    partnerName: "",
    type: "", // New field for Type
    logo: null, // To store the selected logo image
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPartnerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Handle file input (Logo)
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPartnerDetails((prevDetails) => ({
      ...prevDetails,
      logo: file,
    }));
  };

  // Handle form submission with axios
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("partnerName", partnerDetails.partnerName);
    formData.append("type", partnerDetails.type);
    if (partnerDetails.logo) {
        formData.append("logo", partnerDetails.logo);
    }
    formData.append("eventId", exhibitionId);
 
    formData.append("pretable", "Exhibition");



    // Log the data being sent
    console.log("Form Data Sent:");
    for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
    }

    try {
        const response = await axios.post(
            `${baseurl}insertPartnerDetails`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data", // Set proper header for form-data
                },
            }
        );

        if (response.data.success) {
            Swal.fire({
                title: "Success",
                text: "The partner has been added successfully.",
                icon: "success",
            });
            toast.success("Partner added successfully!");

            // Reset the form
            setPartnerDetails({
                partnerName: "",
                type: "",
                logo: null,
            });
            window.location.reload();
        } else {
            toast.error(`Failed to add partner: ${response.data.error || "Unknown error"}`);
        }
    } catch (error) {
        console.error("Error adding partner:", error);
        toast.error("Something went wrong. Please try again later.");
    }

    // Close the modal after form submission
    window.bootstrap.Modal.getInstance(document.getElementById("addPartnerModal")).hide();
};


  // Preview logo image
  const renderImagePreview = () => {
    if (partnerDetails.logo) {
      return (
        <div className="mt-3">
          <img
            src={URL.createObjectURL(partnerDetails.logo)}
            alt="Logo Preview"
            className="img-fluid"
            style={{ maxWidth: "150px" }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {/* Button to open the modal */}
      <div className="d-flex justify-content-between">
        <span>
          <h1 className="mt-3">Partner List</h1>
        </span>
        <button
          type="button"
          className="btnn"
          data-bs-toggle="modal"
          data-bs-target="#addPartnerModal"
        >
          Add Partner
        </button>
      </div>

      {/* Modal to add Partner */}
      <div
        className="modal fade"
        id="addPartnerModal"
        tabIndex="-1"
        aria-labelledby="addPartnerModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addPartnerModalLabel">
                Add Partner
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="partnerName">
                    Partner Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="partnerName"
                    className="form-control"
                    value={partnerDetails.partnerName}
                    onChange={handleInputChange}
                    placeholder="Enter Partner Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="type">
                    Type<span className="text-danger">*</span>
                  </label>
                  <select
                    name="type"
                    className="form-control"
                    value={partnerDetails.type}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      Select Type
                    </option>
                    <option value="Brand Partner">Brand Partner</option>
                    <option value="Media Partner">Media Partner</option>
                    <option value="Marketing Partner">Marketing Partner</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="logo">Logo</label>
                  <input
                    type="file"
                    name="logo"
                    className="form-control"
                    onChange={handleFileChange}
                  />
                  {renderImagePreview()} {/* Display logo preview */}
                </div>
                <div className="modal-footer">
                  {/* <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button> */}
                  <button type="submit" className="btnn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default AddPartner;
