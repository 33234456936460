import React, { useState, useEffect } from "react"; 
import { baseurl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoToDash from "../../component/utils/GoToDash";
import { Link } from "react-router-dom";
import FooterOne from "../../component/utils/FooterOne";
import { Heart, Calendar} from "react-feather";



const SelectEventFormat = () => {
   

  return (
    <div className="">
      <div className="d-flex align-items-center justify-content-between sticky-top px-4 py-3 bg-1">
        <div className="tp-h-tl">Select Event Format</div>
        <div>
          <GoToDash/>
        </div>
      </div>

      <div className="container mt-4">
        <div className="row">
          <div className="crd1">
            <h1 className="text-capitalize d-flex align-items-center">
                <span><Calendar className="ic3" size={50} /></span>
                <span>Select Event Format To proceed</span>
                </h1>

                <div class="hd-1">Select Event Format To Proceed</div>

            
            
          </div>
        </div>

        <div className="mt-4">

                <div className="row g-4">
                    <div className="col-md-4">
                        <Link to="/create-exhibition">
                        <div className="text-center myselect p-2">
                        <div className="type1">Exhibition</div>
                        <p>Showcasing your Products, Services, Offerings, etc.</p>
                        </div>
                        </Link>
                        
                    </div>


                    <div className="col-md-4">
                    <Link to="/create-exhibition-conference">
                    <div className="text-center myselect p-2">
                        <div className="type1">Exhibition + Conference</div>
                        <p>Showcasing your Products, Services, Offerings, etc.</p>
                        </div>
                        </Link>
                        
                    </div>

                    <div className="col-md-4">
                    <Link to="/create-conference">
                    <div className="text-center myselect p-2">
                        <div className="type1">Conference</div>
                        <p>Showcasing your Products, Services, Offerings, etc.</p>
                        </div></Link>
                        
                    </div>

                    <div className="col-md-4">
                    <Link to="/create-workshop">
                    <div className="text-center myselect p-2">
                        <div className="type1">Workshop</div>
                        <p>Showcasing your Products, Services, Offerings, etc.</p>
                        </div>
                        </Link>
                        
                    </div>

                    <div className="col-md-4">
                    <Link>
                    <div className="text-center myselect p-2">
                        <div className="type1">Concerts</div>
                        <p>Showcasing your Products, Services, Offerings, etc.</p>
                        </div>
                        </Link>
                        
                    </div>

                    <div className="col-md-4">
                    <Link>
                    <div className="text-center myselect p-2">
                        <div className="type1">Sports</div>
                        <p>Showcasing your Products, Services, Offerings, etc.</p>
                        </div>
                        </Link>
                        
                    </div>

                     

                </div>
              


            </div>

      </div>
<FooterOne />
      <ToastContainer />
    </div>
  );
};

export default SelectEventFormat;
