import React, { useState, useEffect } from "react";
import { baseurl, imageUrl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Edit, Trash2 } from "react-feather";
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import { useExhibition } from "../../context/ExhibitionContext";
import Swal from "sweetalert2"; // Import SweetAlert2
import AddSpeaker from "../ExhibitionComponent/AddSpeaker";
import { ArrowRight, ArrowLeft } from "react-feather";
import ExhiibitionBreadcrum from "../ExhibitionComponent/ExhibitionBreadcrum";

const ExhibitionSpeakerDetails = () => {
  const [speakers, setSpeakers] = useState([]); // State to store speaker details
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [recordsPerPage, setRecordsPerPage] = useState(5); // State for records per page
  const { exhibitionId } = useExhibition();
  const navigate = useNavigate();
  const [exhibitionFormat, setExhibitionFormat] = useState("");

  // Fetch speaker details when the component mounts
  useEffect(() => {
    const fetchSpeakers = async () => {
      try {
        const response = await axios.get(
          `${baseurl}getSpeakers/${exhibitionId}`
        );
        console.log(response); // Log the full response object
        if (response.data.success) {
          setSpeakers(response.data.speakers); // Update state with fetched data
        } else {
          console.error("Failed to fetch speakers");
        }
      } catch (error) {
        // console.error("Error fetching speakers:", error);
      }
    };

    fetchSpeakers();
  }, [exhibitionId]);

  useEffect(() => {
    // Run the API call only if exhibitionId is available
    if (exhibitionId) {
      const fetchExhibitionName = async () => {
        try {
          const response = await axios.get(
            `${baseurl}getExhibitionName/${exhibitionId}`
          );
          if (response.data.success) {
            setExhibitionFormat(response.data.data.format);
          } else {
            console.error("Failed to fetch exhibition name");
          }
        } catch (error) {
          console.error("Error fetching exhibition name:", error);
        }
      };

      fetchExhibitionName();
    }
  }, [exhibitionId]);

  // Handle speaker deletion
  const handleDelete = (speakerId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          console.log("Sending delete request for speakerId:", speakerId); // Log the speakerId being sent
          const response = await axios.delete(
            `${baseurl}deleteSpeaker/${speakerId}`
          );

          console.log("Delete response:", response); // Log the response from the server

          if (response.data.success) {
            setSpeakers((prevSpeakers) =>
              prevSpeakers.filter((speaker) => speaker.id !== speakerId)
            );
            Swal.fire("Deleted!", "The speaker has been deleted.", "success");
          } else {
            Swal.fire(
              "Error!",
              "There was an issue deleting the speaker.",
              "error"
            );
          }
        } catch (error) {
          console.error("Error deleting speaker:", error);
          Swal.fire(
            "Error!",
            "There was an issue deleting the speaker.",
            "error"
          );
        }
      }
    });
  };

  // Handle change in records per page
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when records per page changes
  };

  // Filter speakers based on search term
  const filteredSpeakers = speakers.filter((speaker) => {
    return (
      speaker.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      speaker.designation.toLowerCase().includes(searchTerm.toLowerCase()) ||
      speaker.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Calculate the current speakers to display
  const indexOfLastSpeaker = currentPage * recordsPerPage;
  const indexOfFirstSpeaker = indexOfLastSpeaker - recordsPerPage;
  const currentSpeakers = filteredSpeakers.slice(
    indexOfFirstSpeaker,
    indexOfLastSpeaker
  );

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredSpeakers.length / recordsPerPage);

  // Handle next button click
  const handleNextClick = () => {
    Swal.fire({
      title: "Success!",
      text: "Details submitted for review successfully.",
      icon: "info",
      confirmButtonText: "Go To Dashboard",
    }).then(() => {
      navigate(`/exhibition-dashboard/${exhibitionId}/stats`);
    });
  };

  return (
    <div>
      <ExhiibitionBreadcrum />
      <ExhibitionContentNavbar />
      <AddSpeaker />

      <div className="container mt-4">
        {/* Speaker List Section */}
        <div className="row">
          <div className="crd">
            <div className="d-flex justify-content-between align-items-center mb-0 py-2">
              <div>
                <input
                  type="text"
                  className="form-control w-100 min550"
                  placeholder="Search by Name, Speaker Title, Email..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div>
                <select
                  className="form-control"
                  value={recordsPerPage}
                  onChange={handleRecordsPerPageChange}
                >
                  <option value={10}>10 Records</option>
                  <option value={20}>20 Records</option>
                  <option value={50}>50 Records</option>
                  <option value={100}>100 Records</option>
                  <option value={filteredSpeakers.length}>All Records</option>
                </select>
              </div>
            </div>
            <hr />

            {/* Table Data */}
            {currentSpeakers.length > 0 ? (
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Profile Image</th>
                    <th>Name</th>
                    <th>Speaker Title</th>
                    <th>Email</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentSpeakers.map((speaker) => (
                    <tr key={speaker.id}>
                      <td>
                        {speaker.profile_image ? (
                          <img
                            src={`${imageUrl}/speakerFiles/${speaker.profile_image}`}
                            alt={speaker.name}
                            width="50"
                          />
                        ) : (
                          <span>No Image</span>
                        )}
                      </td>
                      <td>{speaker.name}</td>
                      <td>{speaker.designation}</td>
                      <td>{speaker.email}</td>
                      <td className="text-end">
                        <span onClick={() => handleDelete(speaker.id)}>
                          <Trash2 className="ic1" />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="row">
                <div className="col text-center">No speakers found.</div>
              </div>
            )}

            {/* Pagination and Records Per Page */}
            <div className="row">
              <div className="col d-flex justify-content-end align-items-center">
                <div>
                  {[...Array(totalPages).keys()].map((pageNumber) => (
                    <span
                      key={pageNumber}
                      className={`pagi1 me-1 ${
                        currentPage === pageNumber + 1 ? "active" : ""
                      }`}
                      onClick={() => paginate(pageNumber + 1)}
                      style={{ cursor: "pointer" }}
                    >
                      {pageNumber + 1}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            {/* Next Button */}
            <div className="col-md-12 mt-5 mb-3 d-flex justify-content-between align-items-center">
              <span>
                <Link
                  to={`/exhibition-dashboard/${exhibitionId}/${
                    exhibitionFormat === "ExhibitionConference"
                      ? "conference-details"
                      : "partner-details"
                  }`}
                  className="btnn mybtn"
                >
                  <ArrowLeft className="ic text-white" />
                  Back
                </Link>
              </span>

              <span className="">
                <Link
                  to={`/exhibition-dashboard/${exhibitionId}/${
                    exhibitionFormat === "ExhibitionConference"
                      ? "exhibition-extra-details"
                      : "exhibition-extra-details"
                  }`}
                  className="btnn mybtn"
                >
                  Next <ArrowRight className="ic text-white" />
                </Link>
              </span>

              {/* <span onClick={handleNextClick}>
                <Link className="btnn mybtn">
                  Submit <ArrowRight className="ic text-white" />
                </Link>
              </span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionSpeakerDetails;
