import React from "react";
import logo from '../img/logo.png';
import { Link } from "react-router-dom";

function LoginNavbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
        <div className="container-fluid">
        <div>
                  <Link>
                  <img src={logo} className="img-fluid logo" alt="Eventoz Logo" />
                  </Link>
                </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0"> {/* Align menu to the right */}
              <li className="nav-item">
                <Link className="nav-link active sja" aria-current="page" href="#">
                  Features
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link sja"  href="#">
                  Our Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link sja" href="#">
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link sja" href="#">
                  Request Demo
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link sja" href="#">
                  Login / Signin
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default LoginNavbar;
