import React, { useEffect, useState } from "react";
import { Heart, UserPlus, Edit, Home, AlertCircle } from "react-feather";
import { Link } from "react-router-dom"; 
import { useConference } from "../../context/ConferenceContext";
import { baseurl } from "../../component/Baseurl";
import axios from "axios";
import PageView from "../ExhibitionComponent/PageView"; 
import LeadGenerated from "../ExhibitionComponent/LeadGenerated";
import ConferencePageView from "../ConferenceComponent/ConferencePageView";

const ConferenceDashboardStat = () => {
  const { conferenceId } = useConference();
  const [exhibitionName, setExhibitionName] = useState("");
  const [formSubmissionCounts, setFormSubmissionCounts] = useState({});
  const [loading, setLoading] = useState(true); // State to handle loading

  useEffect(() => {
    // Check if conferenceId is available
    if (!conferenceId) {
      setLoading(false);
      return;
    }

    // Function to fetch exhibition data and form submission counts
    const fetchExhibitionData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}getFormSubmissionCountsByConference/${conferenceId}`
        );
        if (response.data) {
          setExhibitionName(response.data.conference_name); // Update state with exhibition name
          setFormSubmissionCounts(response.data.form_submissions_counts); // Update form submission counts
        } else {
          console.error("Failed to fetch exhibition data");
        }
      } catch (error) {
        console.error("Error fetching exhibition data:", error);
      } finally {
        setLoading(false); // Stop loading when the request is finished
      }
    };

    // Call the fetchExhibitionData only once
    fetchExhibitionData();
  }, [conferenceId]);

  // Show loading state while fetching data
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="mt-3">
        {/* Exhibition Name */}
        <div className="d-flex justify-content-between">
          <h1>Dashboard : {exhibitionName || "No Exhibition Found"}</h1>
          <div className="text-muted brdcrm">
            <span>
              <Link to="/dashboard">
                <Home size={15} />
              </Link>
            </span>{" "}
            <span>/</span> <span>Dashboard</span>
          </div>
        </div>

        <div className="row g-1 ">
          {/* <div className="col-md-8">
            <div className="crd1">
              <div className="hd-1">Visitors Count</div>
              <VisitorChart />
            </div>
          </div> */}

          <div className="col-md-3">
            <div className="crd1 px-3 mb-1">
              <Link
                to={`/exhibition-dashboard/${conferenceId}/visitor-leads`}
                className=" tooltip-container w-100"
              >
                <div className="d-flex justify-content-between">
                  <div>
                    <UserPlus className="ic2 cl4" size={45} />
                  </div>
                  <div>
                    <div className="txtd1">Visitor</div>
                    <div className="txtd2 text-end">
                      {formSubmissionCounts.Visitor || 0}
                    </div>
                  </div>
                </div>
                {/* Tooltip text */}
                <span className="tooltip-text">
                  This is your Visitor Registration count for event "
                  {exhibitionName}"
                </span>
              </Link>
            </div>
          </div>

          {formSubmissionCounts.Delegate > 0 && (
            <div className="col-md-3">
              <div className="crd1 px-3 mb-1">
                <Link
                  to={`/exhibition-dashboard/${conferenceId}/delegate-leads`}
                  className=" tooltip-container w-100"
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <UserPlus className="ic2 cl4" size={45} />
                    </div>
                    <div>
                      <div className="txtd1">Delegate</div>
                      <div className="txtd2 text-end">
                        {formSubmissionCounts.Delegate || 0}
                      </div>
                    </div>
                  </div>
                  {/* Tooltip text */}
                  <span className="tooltip-text">
                    This is your Delegate Registration count for event "
                    {exhibitionName}"
                  </span>
                </Link>
              </div>
            </div>
          )}

          {formSubmissionCounts.Exhibitor > 0 && (
            <div className="col-md-3">
              <div className="crd1 px-3 mb-1">
                <Link
                  to={`/exhibition-dashboard/${conferenceId}/exhibitor-leads`}
                  className=" tooltip-container w-100"
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <UserPlus className="ic2 cl4" size={45} />
                    </div>
                    <div>
                      <div className="txtd1">Exhibitor</div>
                      <div className="txtd2 text-end">
                        {formSubmissionCounts.Exhibitor || 0}
                      </div>
                    </div>
                  </div>
                  {/* Tooltip text */}
                  <span className="tooltip-text">
                    This is your Exhibitor Registration count for event "
                    {exhibitionName}"
                  </span>
                </Link>
              </div>
            </div>
          )}

          {formSubmissionCounts.Sponsor > 0 && (
            <div className="col-md-3">
              <div className="crd1 px-3 mb-1">
                <Link
                  to={`/exhibition-dashboard/${conferenceId}/sponser-leads`}
                  className=" tooltip-container w-100"
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <UserPlus className="ic2 cl4" size={45} />
                    </div>
                    <div>
                      <div className="txtd1">Sponsor</div>
                      <div className="txtd2 text-end">
                        {formSubmissionCounts.Sponsor || 0}
                      </div>
                    </div>
                  </div>
                  {/* Tooltip text */}
                  <span className="tooltip-text">
                    This is your Sponsor Registration count for event "
                    {exhibitionName}"
                  </span>
                </Link>
              </div>
            </div>
          )}

          {formSubmissionCounts.Speaker > 0 && (
            <div className="col-md-3">
              <div className="crd1 px-3 mb-1">
                <Link
                  to={`/exhibition-dashboard/${conferenceId}/speaker-leads`}
                  className="tooltip-container w-100"
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <UserPlus className="ic2 cl4" size={45} />
                    </div>
                    <div>
                      <div className="txtd1">Speaker</div>
                      <div className="txtd2 text-end">
                        {formSubmissionCounts.Speaker || 0}
                      </div>
                    </div>
                  </div>
                  {/* Tooltip text */}
                  <span className="tooltip-text">
                    This is your Speaker Registration count for event "
                    {exhibitionName}"
                  </span>
                </Link>
              </div>
            </div>
          )}
        </div>

        <div className="row g-3">
          <div className="col-md-12">
            <div className="tooltip-container">
              <div className="hd-1">
                Visitor Count &nbsp;
                <AlertCircle size={18} />
                <span className="tooltip-text">
                  This is count of visitors who Visted the Exhibition detail
                  page.
                </span>
              </div>
            </div>
            <div className="crd1">
              <ConferencePageView exhibitionId={conferenceId}/> 
            </div>
          </div>



          <div className="col-md-12">
            <div className="tooltip-container">
              <div className="hd-1">
                Leads Count &nbsp;
                <AlertCircle size={18} />
                <span className="tooltip-text">
                  This is count of Leads Generated the Exhibition detail
                  page.
                </span>
              </div>
            </div>
            <div className="crd1">
              <LeadGenerated exhibitionId={conferenceId} /> 
            </div>
          </div>


          <div className="col-md-9">
            <div className="crd1">
              {/* <ExhibitionGuestRequest /> */}
            </div>
          </div>
        
        </div>
      </div> 
    </div>
  );
};

export default ConferenceDashboardStat;
