import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Search, LogOut as UsersIcon } from "react-feather"; 
import { useWorkshop } from "../../context/WorkshopContext"; 
const WorkshopContentNavbar = () => {
  const { workshopId } = useWorkshop();
  const location = useLocation();  

  // Function to check if the current path is the same as the given path
  const isActive = (path) => location.pathname === path;

  return (
    <div className="fixedtp30 mt-3">
      {/* Search Box */}
      <div className="chaintop">
        <span>
          <Link
            to={`/conference-dashboard/${workshopId}/conference-basic-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${workshopId}/conference-basic-details`)
                ? "active1"
                : ""
            }`}
          >
            Basic Details
          </Link>
        </span>
        <span>
          <Link
            to={`/conference-dashboard/${workshopId}/conference-additional-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${workshopId}/conference-additional-details`)
                ? "active1"
                : ""
            }`}
          >
            Additional Details
          </Link>
        </span>
        <span>
          <Link
            to={`/conference-dashboard/${workshopId}/conference-time-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${workshopId}/conference-time-details`)
                ? "active1"
                : ""
            }`}
          >
            Time Details
          </Link>
        </span>
        {/* <span>
          <Link
            to={`/conference-dashboard/${workshopId}/conference-venue-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${workshopId}/conference-venue-details`)
                ? "active1"
                : ""
            }`}
          >
            Mode & Venue Details
          </Link>
        </span> */}
        <span>
          <Link
            to={`/conference-dashboard/${workshopId}/conference-image-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${workshopId}/conference-image-details`)
                ? "active1"
                : ""
            }`}
          >
            Image Files
          </Link>
        </span>
        
        
        


         
        <span>
          <Link
            to={`/conference-dashboard/${workshopId}/conference-speaker-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${workshopId}/conference-speaker-details`)
                ? "active1"
                : ""
            }`}
          >
            Speakers
          </Link>
        </span>

        
        <span>
          <Link
            to={`/conference-dashboard/${workshopId}/conference-sponser-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${workshopId}/conference-sponser-details`)
                ? "active1"
                : ""
            }`}
          >
            Sponsers
          </Link>
        </span>
        <span>
          <Link
            to={`/conference-dashboard/${workshopId}/conference-partner-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${workshopId}/conference-partner-details`)
                ? "active1"
                : ""
            }`}
          >
            Partner
          </Link>
        </span>

        <span>
          <Link
            to={`/conference-dashboard/${workshopId}/conference-session-agenda`}
            className={`spanli ${
              isActive(`/conference-dashboard/${workshopId}/conference-session-agenda`)
                ? "active1"
                : ""
            }`}
          >
            Session & Agenda
          </Link>
        </span>
        
      </div>
    </div>
  );
};

export default WorkshopContentNavbar;
