import React, { useState, useEffect } from "react"; 
import { baseurl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { ArrowRight, ArrowLeft, Trash2 } from "react-feather";  
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import { useExhibition } from "../../context/ExhibitionContext";
import Swal from 'sweetalert2'; // Import SweetAlert2
import ExhiibitionBreadcrum from "../ExhibitionComponent/ExhibitionBreadcrum";
import AddTicket from "../ExhibitionComponent/AddTicket";

const ExhibitionPaidState = () => {
  const [tickets, setTickets] = useState([]); // State to store ticket details
  const { exhibitionId } = useExhibition(); 
  const navigate = useNavigate();
  const [exhibitionFormat, setExhibitionFormat] = useState(""); 

  // Fetch ticket details when the component mounts
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get(`${baseurl}getTicketsByEventId/${exhibitionId}`);
        console.log(response); // Log the full response object
        if (response.data.Tickets && response.data.Tickets.length > 0) {
          setTickets(response.data.Tickets); // Update state with fetched data
        } else {
          console.error("No tickets found");
        }
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };

    fetchTickets();
  }, [exhibitionId]);

  

  // Handle delete ticket
  const handleDeleteTicket = async (id) => {
    // Show SweetAlert2 confirmation before deletion
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Call delete API
          const response = await axios.delete(`${baseurl}deleteTicket`, { data: { id } });

          if (response.status === 200) {
            // On successful deletion, show success message
            Swal.fire(
              'Deleted!',
              'The ticket has been deleted.',
              'success'
            );
            // Remove the deleted ticket from the list
            setTickets(tickets.filter(ticket => ticket.id !== id));
          }
        } catch (error) {
          console.error('Error deleting ticket:', error);
          Swal.fire('Error!', 'There was a problem deleting the ticket.', 'error');
        }
      }
    });
  };

  return (
    <div>
      <ExhiibitionBreadcrum/>
      <ExhibitionContentNavbar />
      <AddTicket/>

      <div className="container mt-4">
        {/* Ticket List Section */}
        <div className="row">
          <div className="crd">

            <div className="hd-1 text-capitalize">Tickets</div>
            <hr />
            {/* Table Data */}

            <div className="table-responsive mt-2">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Ticket Name</th>
                    <th>Ticket Type</th>
                    <th>Description</th>
                    <th>Currency</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                {tickets.length > 0 ? (
                  <tbody>
                    {tickets.map((ticket) => (
                      <tr key={ticket.id}>
                        <td>{ticket.ticket_name}</td>
                        <td>{ticket.ticket_type}</td>
                        <td>{ticket.description}</td>
                        <td>{ticket.currency}</td>
                        <td>{ticket.price}</td>
                        <td>{ticket.quantity}</td>
                        <td className="text-end">
                          {/* Delete Action Button */}
                          <span
                            className=""
                            onClick={() => handleDeleteTicket(ticket.id)}
                          >
                            <Trash2 size={20}/>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tr>
                    <td colSpan={7} className="text-center">No tickets found.</td>
                  </tr>
                )}
              </table>
            </div>

            {/* Next Button */}
            <div className="col-md-12 mt-5 mb-3 d-flex justify-content-between align-items-center">
              <span>
                <Link
                  to={`/exhibition-dashboard/${exhibitionId}/${exhibitionFormat === "ExhibitionConference" ? "conference-details" : "speaker-details"}`}
                  className="btnn mybtn"
                >
                  <ArrowLeft className="ic text-white" />
                  Back
                </Link>
              </span>


              <span className="">
                              <Link
                                to={`/exhibition-dashboard/${exhibitionId}/exhibition-badge-setting`}
                                className="btnn mybtn"
                              >
                                Next <ArrowRight className="ic text-white" />
                              </Link>
                            </span>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionPaidState;
