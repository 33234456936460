import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios"; // Import axios
import { baseurl } from "../../component/Baseurl"; // Import baseurl
import GoToDash from "../../component/utils/GoToDash";
import { PlusSquare } from "react-feather";
import ReactDatePicker from "react-datepicker"; // Import React Datepicker
import "react-datepicker/dist/react-datepicker.css"; // Import Datepicker styles
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";


const CreateConference = () => {
  const [selectedIndustries, setSelectedIndustries] = useState([]);
   const [industries, setIndustries] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    conferenceName: "",
    frequency: "",
    attendees: "",
    organisedBy: "",
  });



  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  useEffect(() => {
    // Fetch industries when the component mounts
    const fetchIndustries = async () => {
      try {
        const response = await axios.get(`${baseurl}getAllIndustry`);
        if (response.status === 200) {
          setIndustries(response.data.industries); // Assuming the response contains an array of industries
        }
      } catch (error) {
        console.error("Error fetching industries:", error);
      }
    };

    fetchIndustries();
  }, []);

  const handleIndustryToggle = (industry) => {
    if (selectedIndustries.includes(industry)) {
      setSelectedIndustries(selectedIndustries.filter(item => item !== industry));
    } else {
      setSelectedIndustries([...selectedIndustries, industry]);
    }
  };

  const handleEndDateChange = (date) => {
    if (startDate && date < startDate) {
      toast.error("End date must be after the start date.");
      setEndDate(null);
    } else {
      setEndDate(date);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!formData.conferenceName || !formData.organisedBy || !selectedIndustries.length || !startDate || !endDate || !formData.frequency || !formData.attendees) {
      toast.error("Please fill in all required fields.");
      return;
    }
  
    // Manually format startDate and endDate to YYYY-MM-DD
    const formatDate = (date) => {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = (d.getMonth() + 1).toString().padStart(2, '0');
      const day = d.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
  
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
  
    const payload = {
      ...formData,
      industries: selectedIndustries,
      startDate: formattedStartDate,  // Send the formatted start date
      endDate: formattedEndDate,      // Send the formatted end date
      org_id: localStorage.getItem("orgId"),
    };
    console.log("Payload being sent to the API:", payload);
    
    try {
      setLoading(true);
  
      const response = await axios.post(`${baseurl}create-conference`, payload);
  
      if (response.status === 201) {
        toast.success("Conference created successfully!");
        setFormData({
          conferenceName: "",
          frequency: "",
          attendees: "",
          organisedBy: "",
        });
        setSelectedIndustries([]);
        setStartDate(null);
        setEndDate(null);
  
        const conferenceUniqueId = response.data.conference_unique_id;
        navigate(`/conference-dashboard/${conferenceUniqueId}/conference-basic-details`);
      } else {
        toast.error(response.data.message || "Failed to create conference.");
      }
    } catch (error) {
      console.error("Error creating conference:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between sticky-top px-4 py-3 bg-1">
        <div className="tp-h-tl">Create Conference</div>
        <div>
          <GoToDash />
        </div>
      </div>

      <div className="container mt-4 mb-5">
        <div className="row">
          <div className="col-md-12">
            <div className="crd1">
              <h1 className="text-capitalize d-flex align-items-center">
                <span>
                  <PlusSquare className="ic3" size={50} />
                </span>
                <span>Complete the below Form</span>
              </h1>

              <hr />

              <div className="p-3">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-12">
                      <label className="form-label">
                        Enter Conference Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="conferenceName"
                        className="form-control"
                        placeholder="Enter Conference Name"
                        value={formData.conferenceName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Organized By <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="organisedBy"
                        className="form-control"
                        placeholder="Enter Organizer Name"
                        value={formData.organisedBy}
                        onChange={handleInputChange}
                        required
                      />
                    </div>


                    
                    <div className="col-md-6">
      <label className="form-label">
        Select Industry <span className="text-danger">*</span>
      </label>
      <Multiselect
        options={industries} // Data to populate the dropdown
        displayValue="industry_name" // Key to display in the dropdown 
        // className="form-control"
       
        selectedValues={industries.filter((industry) =>
          selectedIndustries.includes(industry.industry_name)
        )} // Pre-select based on selectedIndustries
        onSelect={(selectedList, selectedItem) => {
          handleIndustryToggle(selectedItem.industry_name); // Add item
        }}
        onRemove={(selectedList, removedItem) => {
          handleIndustryToggle(removedItem.industry_name); // Remove item
        }}
        placeholder="Select Industries"
        style={{
          multiselectContainer: { width: "100%" }, // Adjust the container style
        }}
      />
    </div>



                    <div className="col-md-4">
                      <label className="form-label">
                        Range of Attendees <span className="text-danger">*</span>
                      </label>
                      <select
                        name="attendees"
                        className="form-control"
                        value={formData.attendees}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Attendee Range</option>
                        <option value="0-50">0-50</option>
                        <option value="50-100">50-100</option>
                        <option value="100-200">100-200</option>
                        <option value="200-500">200-500</option>
                        <option value="500+">500+</option>
                      </select>
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">
                        Conference Start Date <span className="text-danger">*</span>
                      </label>
                      <ReactDatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Select Start Date"
                        required
                        minDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">
                        Conference End Date <span className="text-danger">*</span>
                      </label>
                      <ReactDatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        placeholderText="Select End Date"
                        required
                        minDate={startDate || new Date()}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">
                        Conference Frequency <span className="text-danger">*</span>
                      </label>
                      <select
                        name="frequency"
                        className="form-control"
                        value={formData.frequency}
                        onChange={handleInputChange}
                        required
                      > 
                        <option value="">Select Frequency</option>
                        <option value="One Time Event">One Time Event</option>
                        <option value="Every Week">Every Week</option>
                        <option value="Every Month">Every Month</option>
                        <option value="Once In 3 Month">Once In 3 Month</option>
                        <option value="Once In 6 Month">Once In 6 Month</option>
                        <option value="Every Year">Every Year</option>
                      </select>
                    </div>

                    <div className="col-md-12 text-center mt-4">
                      <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? "Creating..." : "Create Conference"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateConference;
