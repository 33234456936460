import React, { useState, useEffect } from "react";
import { useConference } from "../../context/ConferenceContext";  
import ConferenceContentNavbar from "../utils/ConferenceContentNavbar"; // Rename the navbar to match conference
import axios from "axios"; // Import axios
import { baseurl } from "../../component/Baseurl"; // Import the base URL
import { Spinner } from "react-bootstrap"; // Import Bootstrap spinner
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom"; 
import { PlusSquare, Trash2, ArrowLeft, ArrowRight } from "react-feather"; 

const ConferenceAdditionalDetails = () => {
  const { conferenceId } = useConference(); // Use context to fetch the conferenceId
  const navigate = useNavigate();

  const [highlights, setHighlights] = useState([]); // Initialize as an empty array
  const [conferenceDescription, setConferenceDescription] = useState(""); // Initialize as an empty string
  const [conferenceWebsite, setConferenceWebsite] = useState(""); // Initialize as an empty string
  const [formErrors, setFormErrors] = useState({}); // State for form validation errors
  const [websiteChecked, setWebsiteChecked] = useState(false); // State to track checkbox checked status
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [websiteRequired, setWebsiteRequired] = useState(true); // State for website field requirement

  useEffect(() => {
    const fetchConferenceDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseurl}conferenceDetails/${conferenceId}`);
        const { conference_description, conference_website, highlights } = response.data;

        setConferenceDescription(conference_description || ""); // Default to empty string
        setConferenceWebsite(conference_website || ""); // Default to empty string
        setHighlights(highlights || []); // Default to empty array
      } catch (error) {
        console.error("Error fetching conference details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (conferenceId) {
      fetchConferenceDetails();
    }
  }, [conferenceId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    const errors = {};

    // Validate website if required
    if (!websiteChecked && !conferenceWebsite.trim()) {
      errors.conferenceWebsite = "Conference Website is required.";
    }

    // Validate description if not empty
    if (!conferenceDescription.trim()) {
      errors.conferenceDescription = "Conference Description is required.";
    }

    // Set errors only if necessary
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Prepare form data
    const formData = {
      conferenceWebsite,
      conferenceDescription,
      highlights: highlights.filter((item) => item.trim() !== ""), // Exclude empty highlights
      conferenceId,
    };

    try {
      const response = await axios.post(`${baseurl}Insert_Conference_Highlights`, formData);
    
      if (response.status === 200) {
        // Navigate directly upon successful submission
        navigate(`/conference-dashboard/${conferenceId}/conference-time-details`);
      }
    } catch (error) {
      console.error("Error sending data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error submitting the details. Please try again.",
      });
    } 
  };

  const handleAddHighlight = () => setHighlights([...highlights, ""]);

  const handleHighlightChange = (e, index) => {
    const updatedHighlights = [...highlights];
    updatedHighlights[index] = e.target.value;
    setHighlights(updatedHighlights);
  };

  const handleDeleteHighlight = (index) => {
    const updatedHighlights = highlights.filter((_, i) => i !== index);
    setHighlights(updatedHighlights);
  };

  const handleConferenceDescriptionChange = (e) => setConferenceDescription(e.target.value);

  const handleConferenceWebsiteChange = (e) => setConferenceWebsite(e.target.value);

  const handleWebsiteCheckboxChange = () => {
    setWebsiteChecked(!websiteChecked);
    setConferenceWebsite(""); // Clear website input when checkbox is checked
    setWebsiteRequired(!websiteChecked); // Toggle the required field based on checkbox status
  };

  return (
    <div>
      <ConferenceContentNavbar /> {/* Updated Navbar */}
      <h1 className="mt-3">Additional Details</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-3">
            <form onSubmit={handleSubmit}>
              {/* Loading spinner */}
              {loading && (
                <div className="d-flex justify-content-center my-3">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
              
              <div className="row g-2">
                {/* Conference Website Input */}
                <div className="col-md-12">
                  <label className="form-label">Conference Website</label>
                  <input
                    type="url"
                    name="conferenceWebsite"
                    className="form-control"
                    placeholder="Enter Conference Website"
                    value={conferenceWebsite}
                    onChange={handleConferenceWebsiteChange}
                    required={websiteRequired}
                    disabled={websiteChecked}
                  />
                  {formErrors.conferenceWebsite && (
                    <small className="text-danger">{formErrors.conferenceWebsite}</small>
                  )}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={websiteChecked}
                      id="flexCheckDefault"
                      onChange={handleWebsiteCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Don't Have Website
                    </label>
                  </div>
                </div>

                {/* Conference Description Textarea */}
                <div className="col-md-12">
                  <label className="form-label">
                    Conference Description <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Conference Description"
                    rows="6"
                    name="conferenceDescription"
                    value={conferenceDescription}
                    onChange={handleConferenceDescriptionChange}
                    maxLength="1000"
                    required
                  ></textarea>
                  {formErrors.conferenceDescription && (
                    <small className="text-danger">{formErrors.conferenceDescription}</small>
                  )}
                  <div className="text-end">
                    <small>{conferenceDescription.length} / 1000</small>
                  </div>
                </div>

                {/* Conference Highlights Input Fields */}
                <div className="col-md-12">
                  <label className="form-label">
                    Conference Highlights
                  </label>
                  <div>
                    {highlights.map((highlight, index) => (
                      <div key={index} className="d-flex mb-2">
                        <input
                          type="text"
                          name={`highlight-${index}`}
                          className="form-control w-75"
                          placeholder="Enter Conference Highlight"
                          value={highlight}
                          onChange={(e) => handleHighlightChange(e, index)}
                          maxLength="250"
                          required
                        />
                        <span
                          className="addrowdiv mx-2"
                          onClick={() => handleDeleteHighlight(index)}
                        >
                          <Trash2 className="ic text-dark" /> Delete
                        </span>
                        <div className="text-end">
                          <small>{highlight.length} / 250</small>
                        </div>
                      </div>
                    ))}
                    <div className="mt-4">
                      <span
                        className="addrowdiv mx-2 mt-4"
                        onClick={handleAddHighlight}
                      >
                        <PlusSquare className="ic text-dark" /> Add Highlight
                      </span>
                    </div>
                  </div>
                  {formErrors.highlights && (
                    <small className="text-danger">{formErrors.highlights}</small>
                  )}
                </div>
              </div>

              {/* Submit Button */}
              {/* <div className="col-md-12 mt-3 text-end">
                <button type="submit" className="btnn">
                  Next
                </button>
              </div> */}

              <div className="col-md-12 mt-5 d-flex justify-content-between align-items-center">
                              <span className="">
                                <Link to={`/conference-dashboard/${conferenceId }/conference-basic-details`} className="btnn mybtn">
                                <ArrowLeft className="ic text-white" />
                                Back</Link>
                                </span>
                              <button type="submit" className="btnn mt-0">
                                Next <ArrowRight className="ic text-white" />
                              </button>
                            </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConferenceAdditionalDetails;
