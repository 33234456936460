import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Search, LogOut as UsersIcon, User as UserIcon, PlusSquare } from "react-feather"; 

const Topbar = () => {
  // State to control the dropdown visibility
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Toggle dropdown visibility
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <div className="d-flex tp-at align-items-center justify-content-between sticky-top">
      {/* Search Box */}
      <div className="d-flex align-items-center search-box">
        {/* <Search className="search-icon" />
        <input
          type="text"
          className="form-control search-input mt-0"
          placeholder="Search..."
        /> */}
      </div>
      
      {/* User Icon with Dropdown */}
      <div className="dropdown">
         
        <div className="d-flex align-items-center">
          <span className="accmy1">
            <Link to="/event-format">
            <PlusSquare className="ic" />Create Event
            </Link></span>

        <span className="nav-item dropdown">
          <span className="nav-link dropdown-toggle accmy1" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <UserIcon className="ic" />
          <span>My Account</span>
          </span>
          <ul className="dropdown-menu m-0 rounded border" aria-labelledby="navbarDropdownMenuLink">
            <li>
            <Link className="dropdown-item" to="/my-account">
              My Account
            </Link>
            </li>
            <li><Link className="dropdown-item" to="/my-billing">
              My Billing
            </Link></li>
            <li>
            <Link className="dropdown-item" to="/logout">
              Logout
            </Link>
            </li>
          </ul>
        </span>
        </div>

       
      </div>
    </div>
  );
};

export default Topbar;
