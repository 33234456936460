import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseurl as BASE_URL } from "../component/Baseurl";
import logo from '../component/img/logo.png';
import { useNavigate } from "react-router-dom";
import LoginNavbar from "../component/utils/LoginNavbar";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

const Login = () => {
  const [emailOrPhone, setEmailOrPhone] = useState(""); // State to store user input
  const [error, setError] = useState(""); // For displaying errors
  const [isRedirecting, setRedirecting] = useState(false); // State to handle redirect after success

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Make API call to send OTP
      const response = await axios.post(`${BASE_URL}/send-otp`, {
        email: emailOrPhone,
      });
  
      if (response.status === 200) {
        toast.success("OTP sent successfully to your email!", { autoClose: 3000 });
        const encodedEmail = btoa(emailOrPhone); // Encode email to Base64
  
        // Redirect to OTP verification page with the email encoded in the URL
        setRedirecting(true);
        setTimeout(() => {
          navigate(`/verify-otp?email=${encodedEmail}`);
        }, 2000);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setError("There was an error sending OTP. Please try again later.");
      toast.error("Failed to send OTP. Please check your email and try again.", { autoClose: 3000 });
    }
  };
  
  const navigate = useNavigate();

  // Redirect to OTP verification page after OTP is sent
  if (isRedirecting) {
    return <Navigate to="/verify-otp" />;
  }

  // Function to handle Google Login success
// Function to handle Google Login success
const handleGoogleLoginSuccess = async (response) => {
  console.log("Google Login Success:", response);  // Log the entire response

  const googleToken = response.credential; // Get the Google token from the response
  console.log("Google Token:", googleToken); // Log the token

  try {
    // Send the token to the backend for further processing
    const backendResponse = await axios.post(`${BASE_URL}auth/google-login-org`, {
      tokenId: googleToken,
    });

    // Log the backend response
    console.log("Backend Response:", backendResponse); // Log the complete response

    if (backendResponse.status === 200) {
      const { organiser, message } = backendResponse.data;  // Destructure the response to get the organiser data

      // Store organiser_id in localStorage
      localStorage.setItem('orgId', organiser.organiser_id);

      // Check if organiser_phone is empty and navigate accordingly
      if (!organiser.organiser_phone) {
        toast.success("Google Login Successful! Redirecting to My Account...", { autoClose: 3000 });
        // Navigate to 'my-account' if phone is empty
        navigate("/my-account");
      } else {
        toast.success("Google Login Successful! Redirecting to Dashboard...", { autoClose: 3000 });
        // Navigate to 'dashboard' if phone is not empty
        navigate("/dashboard");
      }
    } else {
      toast.error("Error during backend processing. Please try again.", { autoClose: 3000 });
    }
  } catch (error) {
    console.error("Error sending token to backend:", error);
    toast.error("Google Login Failed. Please try again.", { autoClose: 3000 });
  }
};




  // Function to handle Google Login failure
  const handleGoogleLoginFailure = (error) => {
    console.error("Google Login Failed:", error);
    toast.error("Google Login Failed. Please try again.", { autoClose: 3000 });
  };

  return (
    <GoogleOAuthProvider clientId="1042334379144-b0egq7it48fmd0spedaliusqsiermqud.apps.googleusercontent.com">
      <LoginNavbar />
      <section className="bg-signup d-flex align-items-center bglogin">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="my-card p-5">
                <h3 className="header-title">Login / Signin</h3>
                <div className="register mt-3">


                  {/* Google Login Button */}
                  <div className="text-center mt-4">
                    <GoogleLogin
                      onSuccess={handleGoogleLoginSuccess}
                      onError={handleGoogleLoginFailure}
                      useOneTap
                    />
                  </div>


<div className="text-dark text-center my-3">
  Or
</div>




                  <form onSubmit={handleSubmit}>
                    <div>
                      <label htmlFor="emailOrPhone" className="form-label">
                        Enter Your Email<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="emailOrPhone"
                        className="form-control"
                        placeholder="Email Here.."
                        required
                        value={emailOrPhone}
                        onChange={(e) => setEmailOrPhone(e.target.value)}
                      />
                    </div>

                    <div className="text-center">
                      <button type="submit" className="btnn">
                        Continue
                      </button>
                    </div>
                  </form>

                  

                  {/* Display error message if any */}
                  {error && <div className="text-danger text-center mt-3">{error}</div>}

                  <hr className="mt-4 mb-4" />
                  <p className="text-muted ptxt">
                    We’re committed to your privacy. For more information, check
                    out our <Link to="/privacy-policy">Privacy Policy</Link>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </GoogleOAuthProvider>
  );
};

export default Login;
