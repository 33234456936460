import React, { useState, useEffect } from "react";
import { baseurl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";  
import defaultprofile from "../img/profile.webp";
import { useExhibition } from "../../context/ExhibitionContext";
import ExhibitionCommunicationNavbar from "../utils/ExhibitionCommunicationNavbar";

const ExhibitionFollowers = () => {
  const [followers, setFollowers] = useState([]); // State to store followers data
  const [loading, setLoading] = useState(true); // Loading state for API call
  const [error, setError] = useState(null);  
const { exhibitionId } = useExhibition();

  // Fetch followers on component mount
  useEffect(() => {
    const fetchFollowers = async () => {
      try {
        const response = await axios.get(`${baseurl}fetchEventFollowersForOrg/${exhibitionId}`);

        // console.log("Response:", response);
        // console.log("Response data:", response.data);

        if (response.data.message === "Followers' details fetched successfully") {
          setFollowers(response.data.followersDetails); // Set followers data to state
        } else if (response.data.message === "No followers found for this event") {
          setFollowers([]); // Set followers to empty array to display "No followers found"
        }
      } catch (err) {
        console.error("Error fetching followers:", err);

        // Check if the error is due to a 404 status
        if (err.response && err.response.status === 404) {
          setFollowers([]); // Treat 404 as no followers
        } else {
          setError("Error fetching followers");
        }
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    if (exhibitionId) {
      fetchFollowers(); // Call the fetchFollowers function if exhibitionId exists
    }
  }, [exhibitionId]); // Re-run the effect when exhibitionId changes

  if (loading) {
    return <div>Loading...</div>; // Show loading message while fetching data
  }

  if (error) {
    return <div>{error}</div>; // Show error message if API call fails
  }

  return (
    <div>  
      <ExhibitionCommunicationNavbar/>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h1 className="mt-3">Event Followers</h1>
          </div>
        </div>

        <div className="row">
          {followers.length > 0 ? (
            followers.map((follower, index) => (
              <div key={index} className="crd py-4">
                <div className="row g-2">
                  <div className="col-2">
                    <div className="border rounded text-center">
                      <div>
                        <img
                          src={defaultprofile}
                          alt="Profile"
                          className="img-fluid mx100"
                        />
                      </div>
                      <div className="nm1">{follower.name}</div>
                      <div className="nm1 text-muted">{follower.city}, {follower.state}</div>
                      <div className="nm1 text-muted">{follower.country}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="crd py-4">
                <div className="py-4 text-center">
              <div>No followers found for this event</div>  
            </div>
            </div>
            
          )}
        </div>
      </div>
    </div>
  );
};

export default ExhibitionFollowers;
