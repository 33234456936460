import React, { useState, useEffect } from "react";
import { baseurl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link } from "react-router-dom";
import { Edit, Trash2, CornerUpRight, Star } from "react-feather";
import { useExhibition } from "../../context/ExhibitionContext"; 
import defaultprofile from "../img/profile.webp";
import ExhibitionCommunicationNavbar from "../utils/ExhibitionCommunicationNavbar"; 
import Swal from "sweetalert2";


const ExhibitionRatingReview = () => {
  const [reviews, setReviews] = useState([]);
  const [showReplyBox, setShowReplyBox] = useState(null); // State for reply box visibility per review
  const [replies, setReplies] = useState({}); // State to store replies for each review
  const [editReplyId, setEditReplyId] = useState(null); // State to track the reply being edited
  const { exhibitionId } = useExhibition();

  // Function to generate a random hex color
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

 
// Fetch reviews
const fetchReviews = async () => {
  if (!exhibitionId) {
    return; // If exhibitionId is not available, exit early and don't run the API call.
  }
  try {
    const response = await axios.get(`${baseurl}fetchReviewsByEventIdForOrg/${exhibitionId}`);
    console.log("response", response);
    if (response.data.success && response.data.data) {
      setReviews(response.data.data);
    }
  } catch (error) {
    console.error("Error fetching reviews:", error);
  }
};

// Call fetchReviews on component mount
useEffect(() => {
  fetchReviews();
}, [exhibitionId]);

  // Function to toggle reply box for a specific review
  const toggleReplyBox = (reviewId) => {
    // Toggle the reply box visibility only for the clicked review
    setShowReplyBox((prev) => (prev === reviewId ? null : reviewId));
  };

  // Function to handle reply submission
  const handleReplySubmit = async (reviewId) => {
    const replyText = replies[reviewId]; // Get the reply text for the clicked review
  
    if (!replyText) {
      Swal.fire({
        icon: "warning",
        title: "Empty Reply",
        text: "Please enter a reply before submitting.",
      });
      return;
    }
  
    // Log the data being sent to the API
    console.log("Sending reply data:", {
      exhibitionId,
      reviewId,
      replyText,
    });
  
    try {
      const response = await axios.post(`${baseurl}submitReviewReply`, {
        exhibitionId,
        reviewId,
        replyText,
      });
  
      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Reply Submitted",
          text: "Your reply has been submitted successfully!",
        });
        setReplies((prev) => ({ ...prev, [reviewId]: "" })); // Clear the reply field after submission
        setShowReplyBox(null); // Close the reply box after submitting
        fetchReviews();

      } else {
        Swal.fire({
          icon: "error",
          title: "Submission Failed",
          text: "Failed to submit the reply. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error submitting reply:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting your reply. Please try again.",
      });
    }
  };
  

  // Function to handle edit reply
  const handleEditReply = (replyId, replyText) => {
    setEditReplyId(replyId); // Set the reply being edited
    setReplies((prev) => ({ ...prev, [replyId]: replyText })); // Pre-fill the reply text in the textarea
  };

  // Function to handle update reply
  const handleUpdateReply = async (reviewId, replyId) => {
    const updatedReplyText = replies[replyId];
    
    if (!updatedReplyText) {
      alert("Please enter a reply.");
      return;
    }
 
    try {
      const response = await axios.post(`${baseurl}updateReviewReply`, {
        exhibitionId,
        reviewId,
        replyId,
        updatedReplyText,
      });

      if (response.data.success) {
        alert("Reply updated successfully!");
        setEditReplyId(null);  
        setReplies((prev) => ({ ...prev, [replyId]: "" })); // Clear the reply field after update

        fetchReviews();


        
      } else {
        alert("Failed to update the reply.");
      }
    } catch (error) {
      console.error("Error updating reply:", error);
      alert("An error occurred while updating your reply.");
    }
  };



  // Function to handle deleting a reply
const handleDeleteReply = async (replyId) => {
  // Show confirmation dialog
  const result = await Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "Cancel",
  });

  if (result.isConfirmed) {
    try {
      // Make a DELETE or POST request to your API for deleting the reply
      const response = await axios.post(`${baseurl}deleteReviewReply`, {
        replyId, // Pass the reply ID to the API
      });

      if (response.data.success) {
        // Show success alert
        await Swal.fire({
          title: "Deleted!",
          text: "Your reply has been deleted.",
          icon: "success",
        });
        fetchReviews(); // Refresh the reviews after successful deletion
      } else {
        // Show error alert if the API fails
        Swal.fire({
          title: "Error!",
          text: "Failed to delete the reply. Please try again.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error deleting reply:", error);
      Swal.fire({
        title: "Error!",
        text: "An error occurred while deleting the reply.",
        icon: "error",
      });
    }
  }
};


  return (
    <div>
      <ExhibitionCommunicationNavbar />
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h1 className="mt-3">Ratings and Reviews</h1>
          </div>
        </div>

        <div className="row">
          <div className="crd py-4">
            {/* Reviews Section */}
            {reviews.length > 0 ? (
              reviews.map((review) => (
                <div key={review.user_id} className="mb-4">
                  <div className="d-flex align-items-center">
                    <div>
                      {review.user_name ? (
                        <div className="user-initial me-2" style={{ backgroundColor: getRandomColor() }}>
                          {/* Display the first letter of the user's name */}
                          {review.user_name.charAt(0).toUpperCase()}
                        </div>
                      ) : (
                        <img
                          src={defaultprofile}
                          alt="User profile"
                          className="img-fluid profilerate"
                        />
                      )}
                    </div>
                    <div className="nm1">{review.user_name || `User ID: ${review.user_id}`}</div>
                  </div>

                  <div className="text-muted mb-2">
                    {[...Array(5)].map((_, index) => (
                      <span key={index}>
                        <Star
                          size={13}
                          className={index < review.rating ? "text-warning" : "text-muted"}
                        />
                      </span>
                    ))}
                    <span className="nm2 text-muted">
                      &nbsp;{new Date(review.created_at).toLocaleDateString()}
                    </span>
                  </div>

                  <div className="nm3 mb-3">{review.review_text}</div>

                  {/* Displaying Replies */}
                  {review.replies && review.replies.length > 0 && (
                    <div className="mt-3">
                      <h6 className="nm4"><strong>Replies</strong>:</h6>
                      {review.replies.map((reply) => (
                        <div key={reply.reply_id} className="mb-2">
                          <div className="text-start nm3 text-muted rounded border p-2">
                            {/* Display reply */}
                            {editReplyId === reply.reply_id ? (
                              <div>
                                <textarea
                                  className="form-control"
                                  value={replies[reply.reply_id] || ""}
                                  onChange={(e) =>
                                    setReplies((prev) => ({
                                      ...prev,
                                      [reply.reply_id]: e.target.value,
                                    }))
                                  }
                                />
                                <div className="text-end mt-2">
                                  <button
                                    className="btnn"
                                    onClick={() => handleUpdateReply(review.user_id, reply.reply_id)}
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div>{reply.reply_text}</div>
                            )}
                          </div>

                          {/* Edit Button */}
                          <div className="text-end">
                          <button
                            onClick={() => handleEditReply(reply.reply_id, reply.reply_text)}
                            className="btn btn-sm btn-info mt-2 editbg me-2"
                          >
                            <Edit size={14} />
                          </button>

                          <button
                            onClick={() => handleDeleteReply(reply.reply_id)}
                            className="btn btn-sm btn-danger mt-2 editbg text-dark"
                          >
                            <Trash2 size={14} />
                          </button>
                          
                          </div>
                          
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Add Reply Button */}
                  <div className="nm4">
                    <span
                      onClick={() => toggleReplyBox(review.user_id)}
                      style={{ cursor: "pointer" }}
                      className="btnn mt-2"
                    >
                      <strong>Add Reply</strong> <CornerUpRight size={14} />
                    </span>

                    {/* Reply Box */}
                    {showReplyBox === review.user_id && (
                      <div>
                        <textarea
                          className="form-control mt-2"
                          placeholder="Write Your Reply Here..."
                          rows="3"
                          name="reply"
                          value={replies[review.id] || ""}
                          onChange={(e) =>
                            setReplies((prev) => ({
                              ...prev,
                              [review.id]: e.target.value,
                            }))
                          }
                          required
                        ></textarea>

                        <div className="text-end">
                          <button
                            className="btnn mt-1"
                            onClick={() => handleReplySubmit(review.id)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                    <hr className="mt-3" />
                  </div>
                </div>
              ))
            ) : (
              <p className="text-muted">No reviews available for this exhibition.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionRatingReview;
