import React, { useEffect, useState } from "react";
import axios from "axios"; 
import { baseurl } from "../../component/Baseurl";

const PackDetail = ({ packageId, packageName }) => {
  const [packageItems, setPackageItems] = useState([]); // State to store package list items
  const [loading, setLoading] = useState(true); // State to track loading status

  // Fetch package list items
  useEffect(() => {
    const fetchPackageItems = async () => {
      try {
        const response = await axios.get(`${baseurl}package/${packageId}`);
        console.log("API Response:", response.data);
        setPackageItems(response.data.data || []);
      } catch (error) {
        console.error("Error fetching package list items:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPackageItems();
  }, [packageId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1 className="mb-4">Package : {packageName}</h1>
      {packageItems.length > 0 ? (
        <div>
          {packageItems.map((item, index) => (
            <div key={item.id} className="hg1">
              {/* <strong>{index + 1}.</strong>  */}
              {item.package_list_item}
            </div>
          ))}
        </div>
      ) : (
        <p>No package list items found for this package.</p>
      )}
    </div>
  );
};

export default PackDetail;
