import React, { useEffect, useState } from "react"; 
import { useConference } from "../../context/ConferenceContext";
import CustomForm from "../ExhibitionComp/CustomForm";
import { baseurl } from "../Baseurl";
import axios from "axios";
import ConferenceCustomFormPreview from "../ConferenceComp/ConferenceCustomFormPreview"; 
import ConferenceCustomFormNavbar from "../utils/ConferenceCustomFormNavbar";

const CustomSpeakerFormConference = () => { 
    const { conferenceId } = useConference();
    const formFor = "Speaker";

    const [formDetails, setFormDetails] = useState(null); // State to hold the API response
    const [isLoading, setIsLoading] = useState(true); // State to track loading status

    console.log("conferenceId", conferenceId);

    useEffect(() => {
        // Define the API call
        const fetchFormDetails = async () => {
            try {
                const response = await axios.get(
                    `${baseurl}getCustomFormsByEvent/${conferenceId}/${formFor}`
                );
                console.log("API Response:", response.data);
                setFormDetails(response.data?.[0] || null); // Store the first form if it exists
            } catch (error) {
                console.error("Error fetching form details:", error);
                setFormDetails(null); // Reset form details on error
            } finally {
                setIsLoading(false); // Mark loading as complete
            }
        };

        // Call the API if exhibitionId is available
        if (conferenceId) {
            fetchFormDetails();
        }
    }, [conferenceId, formFor]);

    if (isLoading) {
        return <div>Loading...</div>; // Show a loading message while fetching data
    }

    return (
        <div> 
<ConferenceCustomFormNavbar/>
            {/* Render MyCustomForm if formDetails has data, otherwise render CustomForm */}
            {formDetails ? (
                <ConferenceCustomFormPreview formId={formDetails.form_id} />
            ) : (
                <CustomForm eventId={conferenceId} formFor={formFor} />
            )}
        </div>
    );
};

export default CustomSpeakerFormConference;
