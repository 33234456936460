import React, { useEffect, useState } from "react";
import axios from "axios";
import CountUp from "react-countup";
import { baseurl } from "../../component/Baseurl";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ConferencePageView = ({ exhibitionId }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [chartType, setChartType] = useState("line");
  const [analyticsData, setAnalyticsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [maxVisitor, setMaxVisitor] = useState({ date: null, count: 0 });
  const [leastVisitor, setLeastVisitor] = useState({ date: null, count: Infinity });

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseurl}getIpAnalyticsCount/${exhibitionId}`);
      const data = response.data.data;
      const count = response.data.count;
  
      let max = { date: null, count: 0 };
      let least = { date: null, count: Infinity };
  
      data.forEach((item) => {
        const leadCount = parseInt(item.lead_count, 10) || 0; // Parse lead_count as an integer
        if (leadCount > max.count) {
          max = { date: item.lead_date, count: leadCount };
        }
        if (leadCount > 0 && leadCount < least.count) {
          least = { date: item.lead_date, count: leadCount };
        }
      });
  
      setMaxVisitor(max);
      setLeastVisitor(least);
      setAnalyticsData(data.map(item => ({
        ...item,
        lead_count: parseInt(item.lead_count, 10) || 0, // Ensure all counts are numbers
      })));
      setTotalCount(count);
      setIsLoaded(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, [exhibitionId]);

  const chartData = {
    labels: analyticsData.map((item) => item.lead_date),
    datasets: [
      {
        label: "Visitors",
        data: analyticsData.map((item) => Math.max(0, item.lead_count)),
        backgroundColor: analyticsData.map((_, index) =>
          index % 2 === 0 ? "#435ebe" : "#ff416a"
        ),
        borderColor: "#fff",
        borderWidth: 1,
        pointRadius: 5,
        pointHoverRadius: 7,
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const leadCount = tooltipItem.raw;
            const percentage = ((leadCount / totalCount) * 100).toFixed(2);
            return [
              `Visitors on ${tooltipItem.label}: ${leadCount}`,
              `Percentage of Total: ${percentage}%`,
              `Total Visitors: ${totalCount}`,
            ];
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Visiting Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Visitors",
        },
        ticks: {
          stepSize: 40,
        },
      },
    },
  };

  return (
    <div className="chart-container">
      <div className="row">
        <div className="col-md-8">
         <div className="card">
         <div className="card-body">
         {isLoaded && (
            <div className="chart-wrapper">
              {chartType === "line" ? (
                <Line data={chartData} options={chartOptions} />
              ) : (
                <Bar data={chartData} options={chartOptions} />
              )}
            </div>
          )}
         </div>
         </div>
         
        </div>

        <div className="col-md-4">
          <div style={{ marginBottom: "10px" }}>
            <div className="d-flex justify-content-between align-items-center">
              <label htmlFor="chartType" className="oneline">Select Chart Type : &nbsp;</label>
              <select
                id="chartType"
                value={chartType}
                className="form-control"
                onChange={(e) => setChartType(e.target.value)}
              >
                <option value="line">Line Chart</option>
                <option value="column">Bar Chart</option>
              </select>
            </div>
          </div>

          <div className="border rounded p-2 mb-1">
            <div className="d1">Total Visitors:</div>
            <div className="txtd2 txtcl1">
              <CountUp start={0} end={totalCount} duration={2.5} />
            </div>
          </div>

          <div className="border rounded p-2 mb-1">
            <div className="d1">Max Visitors:</div>
            <div>
              <span className="txtd2 txtcl2">
                <CountUp start={0} end={maxVisitor.count} duration={2.5} />
              </span>
              &nbsp; <span className="d2"> on {maxVisitor.date}</span>
            </div>
          </div>

          <div className="border rounded p-2 mb-1">
            <div className="d1">Least Visitors:</div>
            <div>
              <span className="txtd2 txtcl3">
                <CountUp start={0} end={leastVisitor.count} duration={2.5} />
              </span>
              &nbsp; <span className="d3"> on {leastVisitor.date}</span> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConferencePageView;
