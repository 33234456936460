import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ChevronsRight } from "react-feather";
import { useExhibition } from "../../context/ExhibitionContext";
import axios from "axios";
import { baseurl } from "../../component/Baseurl";

const ExhibitionContentNavbar = () => {
  const { exhibitionId } = useExhibition();
  const location = useLocation(); // Get the current location
  const [format, setFormat] = useState(null); // State to hold the exhibition format

  // Function to check if the current path is the same as the given path
  const isActive = (path) => location.pathname === path;

  // console.log("exhibitionId", exhibitionId);

  // Fetch the exhibition format using API only if exhibitionId is valid
  useEffect(() => {
    const fetchFormat = async () => {
      if (!exhibitionId) return; // Stop execution if exhibitionId is null or undefined
      try {
        const response = await axios.get(`${baseurl}getExhibitionFormat/${exhibitionId}`);
        setFormat(response.data.format || null); // Set format, default to null if not found
      } catch (error) {
        console.error("Error fetching exhibition format:", error);
        setFormat(null); // Ensure format is null on error
      }
    };

    fetchFormat();
  }, [exhibitionId]);

  return (
    <div className="fixedtp30 mt-1">
      {/* Navigation Links */}
      <div className="chaintop">
        <span>
          <Link
            to={`/exhibition-dashboard/${exhibitionId}/basic-details`}
            className={`spanli ${
              isActive(`/exhibition-dashboard/${exhibitionId}/basic-details`)
                ? "active1"
                : ""
            }`}
          >
            Basic Details
          </Link>
        </span>

        <span>
          <Link
            to={`/exhibition-dashboard/${exhibitionId}/additional-details`}
            className={`spanli ${
              isActive(`/exhibition-dashboard/${exhibitionId}/additional-details`)
                ? "active1"
                : ""
            }`}
          >
            <ChevronsRight className="text-dark" size={20} /> Additional Details
          </Link>
        </span>

        <span>
          <Link
            to={`/exhibition-dashboard/${exhibitionId}/time-details`}
            className={`spanli ${
              isActive(`/exhibition-dashboard/${exhibitionId}/time-details`)
                ? "active1"
                : ""
            }`}
          >
            <ChevronsRight className="text-dark" size={20} /> Time Details
          </Link>
        </span>

        <span>
          <Link
            to={`/exhibition-dashboard/${exhibitionId}/image-files`}
            className={`spanli ${
              isActive(`/exhibition-dashboard/${exhibitionId}/image-files`)
                ? "active1"
                : ""
            }`}
          >
            <ChevronsRight className="text-dark" size={20} /> Image Files
          </Link>
        </span>
        <span>
          <Link
            to={`/exhibition-dashboard/${exhibitionId}/exhibition-brochure`}
            className={`spanli ${
              isActive(`/exhibition-dashboard/${exhibitionId}/exhibition-brochure`)
                ? "active1"
                : ""
            }`}
          >
            <ChevronsRight className="text-dark" size={20} /> Brochure
          </Link>
        </span>

        
        <span>
          <Link
            to={`/exhibition-dashboard/${exhibitionId}/exhibitor-details`}
            className={`spanli ${
              isActive(`/exhibition-dashboard/${exhibitionId}/exhibitor-details`)
                ? "active1"
                : ""
            }`}
          >
            <ChevronsRight className="text-dark" size={20} /> Exhibitors
          </Link>
        </span>

        <span>
          <Link
            to={`/exhibition-dashboard/${exhibitionId}/sponser-details`}
            className={`spanli ${
              isActive(`/exhibition-dashboard/${exhibitionId}/sponser-details`)
                ? "active1"
                : ""
            }`}
          >
            <ChevronsRight className="text-dark" size={20} /> Sponsors
          </Link>
        </span>

        <span>
          <Link
            to={`/exhibition-dashboard/${exhibitionId}/partner-details`}
            className={`spanli ${
              isActive(`/exhibition-dashboard/${exhibitionId}/partner-details`)
                ? "active1"
                : ""
            }`}
          >
            <ChevronsRight className="text-dark" size={20} /> Partner
          </Link>
        </span>

        {/* Conditional Rendering for Add Conference Details */}
        {format === "ExhibitionConference" && (
          <span>
            <Link
              to={`/exhibition-dashboard/${exhibitionId}/conference-details`}
              className={`spanli ${
                isActive(`/exhibition-dashboard/${exhibitionId}/conference-details`)
                  ? "active1"
                  : ""
              }`}
            >
              <ChevronsRight className="text-dark" size={20} /> Add Conference Details
            </Link>
          </span>
        )}

        <span>
          <Link
            to={`/exhibition-dashboard/${exhibitionId}/speaker-details`}
            className={`spanli ${
              isActive(`/exhibition-dashboard/${exhibitionId}/speaker-details`)
                ? "active1"
                : ""
            }`}
          >
            <ChevronsRight className="text-dark" size={20} /> Speakers
          </Link>
        </span>

        <span>
          <Link
            to={`/exhibition-dashboard/${exhibitionId}/exhibition-extra-details`}
            className={`spanli ${
              isActive(`/exhibition-dashboard/${exhibitionId}/exhibition-extra-details`)
                ? "active1"
                : ""
            }`}
          >
            <ChevronsRight className="text-dark" size={20} /> Ticket Details
          </Link>
        </span>



        <span>
          <Link
            to={`/exhibition-dashboard/${exhibitionId}/exhibition-badge-setting`}
            className={`spanli ${
              isActive(`/exhibition-dashboard/${exhibitionId}/exhibition-badge-setting`)
                ? "active1"
                : ""
            }`}
          >
            <ChevronsRight className="text-dark" size={20} /> Badge Settings
          </Link>
        </span>



        


      </div>
    </div>
  );
};

export default ExhibitionContentNavbar;
