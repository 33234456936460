import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
 
// import InternetConnectionStatus from "./InternetConnectionStatus.js";

import Home from "./pages/Home";
import About from "./pages/About";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Sidebar from "./component/Sidebar";
import "./component/css/common.css";
import "./component/css/style.css";
import "./App.css";
import Contact from "./pages/Contact";
import OTP from "./pages/OTP";
import Packages from "./pages/Packages";

import CreateBranch from "./pages/CreateBranch";
import CreateUser from "./pages/CreateUser";
import Users from "./pages/Users";
import EditUser from "./pages/EditUser";
import OrgLogin from "./pages/OrgLogin";
import AllEvents from "./pages/AllEvents";
import SelectEventFormat from "./pages/createEvent/SelectEventFormat";
import CreateExhibition from "./pages/createEvent/CreateExhibition";
import CreateExhibitionConference from "./pages/createEvent/CreateExhibitionConference";
import UserAccess from "./pages/UserAccess";
import Calander from "./pages/Calander";
import MyAccount from "./pages/MyAccount";

import BadgeOne from "./pages/Badge/BadgeOne";
import BadgeTwo from "./pages/Badge/BadgeTwo";

import Logout from "./pages/Logout";
// for Exhibition dashboard
import { ExhibitionProvider } from "./context/ExhibitionContext";
import ExhibitionDashboard from "./pages/ExhibitionDashboard";
import ExhibitionDetails from "./component/ExhibitionComp/ExhibitionDetails";
import ExhibitionSchedule from "./component/ExhibitionComp/ExhibitionSchedule";
import ExhibitionStats from "./component/ExhibitionComp/ExhibitionStats";
import DashboardComp from "./component/ExhibitionComp/DashboardComp";
import ExhibitionBasicDetails from "./component/ExhibitionComp/ExhibitionBasicDetails";
import ExhibitionTimeDetails from "./component/ExhibitionComp/ExhibitionTimeDetails";
import ExhibitionVenueDetails from "./component/ExhibitionComp/ExhibitionVenueDetails";
import ExhibitionAdditionalDetails from "./component/ExhibitionComp/ExhibitionAdditionalDetails";
import ExhibitionImageDetails from "./component/ExhibitionComp/ExhibitionImageDetails";
import ExhibitionExhibitorDetails from "./component/ExhibitionComp/ExbhibitionExhibitorDetails";
import EditExhibitor from "./component/ExhibitionComponent/EditExhibitor";
import ExhibitionSpeakerDetails from "./component/ExhibitionComp/ExhibitionSpeakerDetails";
import ExhibitionSponserDetails from "./component/ExhibitionComp/ExhibitionSponserDetails";
import ExhibitionPartnerDetails from "./component/ExhibitionComp/ExhibitionPartnerDetails";
import DelegateLeads from "./component/ExhibitionComp/DelegateLeads";
import SponserLeads from "./component/ExhibitionComp/SponserLeads";
import SpeakerLeads from "./component/ExhibitionComp/SpeakerLeads";
import VisitorLeads from "./component/ExhibitionComp/VisitorLeads";
import ExhibitorLeads from "./component/ExhibitionComp/ExhibitorLeads";
import WebAnalytics from "./component/ExhibitionComp/WebAnalytics";
import CreateConference from "./pages/createEvent/CreateConference";
import CustomForm from "./component/ExhibitionComp/CustomForm";
import GeoAnalytics from "./component/ExhibitionComp/GeoAnalytics";
import ExhibitionRatingReview from "./component/ExhibitionComp/ExhibitionRatingReview";
import ExhibitionFollowers from "./component/ExhibitionComp/ExhibitionFollowers";
import ExhibitionGuestRequest from "./component/ExhibitionComp/ExhibitionGuestRequest";
import ExhibitionConferenceSessionAgenda from "./component/ExhibitionComp/ExhibitionConferenceSessionAgenda";
 import ExhibitionPaidState from "./component/ExhibitionComp/ExhibitionPaidState";
import ExhibitionBrochure from "./component/ExhibitionComp/ExhibitionBrochure";
import ExhibitionBadgeSetting from "./component/ExhibitionComp/ExhibitionBadgeSetting";



import CustomDeligateForm from "./component/ExhibitionComp/CustomDeligateForm";
import CustomSpeakerForm from "./component/ExhibitionComp/CustomSpeakerForm";
import CustomSponserForm from "./component/ExhibitionComp/CustomSponserForm";
import CustomVisitorForm from "./component/ExhibitionComp/CustomVisitorForm";
import CustomExhibitorForm from "./component/ExhibitionComp/CustomExhibitorForm";

import ExhibitionConferenceDetails from "./component/ExhibitionComp/ExhibitionConferenceDetails";

// for conference
import { ConferenceProvider } from "./context/ConferenceContext";

import ConferenceDashboard from "./pages/ConferenceDashboard";
import ConferenceBasicDetails from "./component/ConferenceComp/ConferenceBasicDetails";
import ConferenceAdditionalDetails from "./component/ConferenceComp/ConferenceAdditionalDetails";
import ConferenceTimeDetails from "./component/ConferenceComp/ConferenceTimeDetails";
import ConferenceVenueDetails from "./component/ConferenceComp/ConferenceVenueDetails";
import ConferenceImageDetails from "./component/ConferenceComp/ConferenceImageDetails";
import ConferenceSpeakerDetails from "./component/ConferenceComp/ConferenceSpeakerDetails";
import ConferenceSponserDetails from "./component/ConferenceComp/ConferenceSponserDetails";
import ConferencePartnerDetails from "./component/ConferenceComp/ConferencePartnerDetails";
import ConferenceDelegateLeads from "./component/ConferenceComp/ConferenceDelegateLeads";
import ConferenceSessionAgenda from "./component/ConferenceComp/ConferenceSessionAgenda";
import CustomSpeakerFormConference from "./component/ConferenceComp/CustomSpeakerFormConference";
import CustomDeligateFormConference from "./component/ConferenceComp/CustomDeligateFormConference";
import CustomSponsorFormConference from "./component/ConferenceComp/CustomSponsorFormConference";
import CustomPartnerFormConference from "./component/ConferenceComp/CustomPartnerFormConference";
import ConferenceDashboardStat from "./component/ConferenceComp/ConferenceDashboardStat";
import ConferenceAnalyticReport from "./component/ConferenceComp/ConferenceAnalyticReport";
import ConferenceSpeakerLeads from "./component/ConferenceComp/ConferenceSpeakerLeads";
import ConferenceSponsorLeads from "./component/ConferenceComp/ConferenceSponsorLeads";
import ConferencePartnerLeads from "./component/ConferenceComp/ConferencePartnerLeads";
import ConferenceRating from "./component/ConferenceComp/ConferenceRating";
import ConferenceFollowers from "./component/ConferenceComp/ConferenceFollowers";
import ConferenceRequestQuery from "./component/ConferenceComp/ConferenceRequestQuery";

// for workshop 

import { WorkshopProvider } from "./context/WorkshopContext";
import CreateWorkshop from "./pages/createEvent/CreateWorkshop";
import WorkshopDashboard from "./pages/WorkshopDashboard";
import WorkshopBasicDetails from "./component/WorkshopComp/WorkshopBasicDetails";
import WorkshopAdditionalDetails from "./component/WorkshopComp/WorkshopAdditionalDetails";



import PackageDetail from "./pages/PackageDetail";
import Invoice from "./pages/Invoice";
import AllBills from "./pages/AllBills"; 

// Wrapper for the layout
const Layout = ({ children }) => {
  const location = useLocation(); // Get the current location

  // Check if the current page is one of the authentication-related pages
  const isAuthPage = ["/", "/signup", "/verify-otp", "/org-login"].includes(
    location.pathname
  );

  return (
    <div className="App" style={{ display: "flex" }}>
      {/* Conditionally render Sidebar only if not on auth pages */}
      {!isAuthPage && <Sidebar />}

      {/* Content area */}
      <div className="content" style={{ flexGrow: 1, padding: "20px" }}>
        {children}
      </div>
    </div>
  );
};

function App() {
  return (
    <Router>
      
      {/* <InternetConnectionStatus /> */}

      <Routes>
        {/* Dashboard and other pages requiring the layout */}
        <Route  path="/dashboard" element={ <Layout> <Home /> </Layout> } />
        <Route path="/about" element={ <Layout> <About /> </Layout> } />
        <Route path="/contact" element={ <Layout> <Contact /> </Layout> } />
        <Route path="/users" element={ <Layout> <Users /> </Layout> } />
        <Route path="/all-event" element={ <Layout> <AllEvents /> </Layout> } />
        <Route path="/edit-user/:id" element={ <Layout> <EditUser /> </Layout> } />

<Route path="/user-access" element={<Layout><UserAccess /></Layout>}/>
<Route path="/my-event-calendar" element={<Layout><Calander /></Layout>}/>
<Route path="/our-pricing" element={<Packages />}/>
<Route path="/my-account" element={<MyAccount />}/>
<Route path="/package-detail" element={<PackageDetail />}/>
<Route path="/invoice" element={<Invoice />}/> 
<Route path="/badge-format-one" element={<BadgeOne />}/> 
<Route path="/badge-format-two" element={<BadgeTwo />}/> 
<Route path="/my-billing" element={<Layout><AllBills /></Layout>}/>


        {/* Event creation and exhibition management */}
        <Route path="/event-format" element={<SelectEventFormat />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/create-exhibition" element={<CreateExhibition />} />
        <Route path="/create-conference" element={<CreateConference />} />
        <Route path="/create-exhibition-conference" element={<CreateExhibitionConference />} />
        <Route path="/create-workshop" element={<CreateWorkshop />} />

        <Route path="details" element={<ExhibitionDetails />} />
        <Route path="schedule" element={<ExhibitionSchedule />} />
        {/* <Route path="stats" element={<ExhibitionStats />} /> */}

        {/* Authentication and other independent pages */}
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/verify-otp" element={<OTP />} />
        <Route path="/setup-branch" element={<CreateBranch />} />
        <Route path="/org-login" element={<OrgLogin />} />
        <Route path="/create-user" element={<CreateUser />} />

        {/* Exhibition dashboard wrapped with ExhibitionProvider */}
        <Route path="/exhibition-dashboard/:exhibition_unique_id"
          element={
            <ExhibitionProvider>
              <ExhibitionDashboard />
            </ExhibitionProvider>
          }
        >
          {/* Nested routes for Exhibition Dashboard */}
          <Route path="dashboard" element={<DashboardComp />} />
          <Route path="basic-details" element={<ExhibitionBasicDetails />} />
          <Route path="time-details" element={<ExhibitionTimeDetails />} />
          <Route path="venue-mode-details" element={<ExhibitionVenueDetails />} />
          <Route path="additional-details" element={<ExhibitionAdditionalDetails />} />
          <Route path="image-files" element={<ExhibitionImageDetails />} />
          <Route path="exhibitor-details" element={<ExhibitionExhibitorDetails />} />
          <Route path="edit-exhibitor" element={<EditExhibitor />} />
          <Route path="speaker-details" element={<ExhibitionSpeakerDetails />} />
          <Route path="sponser-details" element={<ExhibitionSponserDetails />}  />
          <Route path="partner-details" element={<ExhibitionPartnerDetails />} />
          <Route path="delegate-leads" element={<DelegateLeads />} />
          <Route path="sponser-leads" element={<SponserLeads />} />
          <Route path="speaker-leads" element={<SpeakerLeads />} />
          <Route path="visitor-leads" element={<VisitorLeads />} />
          <Route path="exhibitor-leads" element={<ExhibitorLeads />} />
          <Route path="web-analytics" element={<WebAnalytics />} />
          <Route path="create-form" element={<CustomForm />} />
          
          
          <Route path="create-deligate-custom-form"  element={<CustomDeligateForm />} />
          <Route path="create-speaker-custom-form" element={<CustomSpeakerForm />} />
          <Route path="create-sponser-custom-form" element={<CustomSponserForm />} />
          <Route path="create-visitor-custom-form" element={<CustomVisitorForm />} />
          <Route path="create-exhibitor-custom-form" element={<CustomExhibitorForm />} />
          <Route path="conference-details" element={<ExhibitionConferenceDetails />} />
          <Route path="stats" element={<ExhibitionStats />} />
          <Route path="geo-analytics" element={<GeoAnalytics />} />
          <Route path="exhibition-ratings" element={<ExhibitionRatingReview />} />
          <Route path="exhibition-followers" element={<ExhibitionFollowers />} />
          <Route path="exhibition-request-query" element={<ExhibitionGuestRequest />} />
          <Route path="exhibition-conference-session-agenda" element={<ExhibitionConferenceSessionAgenda />} />
          <Route path="exhibition-extra-details" element={<ExhibitionPaidState />} />
          <Route path="exhibition-brochure" element={<ExhibitionBrochure />} />
          <Route path="exhibition-badge-setting" element={<ExhibitionBadgeSetting />} />
          
        </Route>



        {/* Conference dashboard wrapped with ConferenceProvider */}
        <Route path="/conference-dashboard/:workshop_unique_id" element={ <ConferenceProvider> 
          <ConferenceDashboard />
            </ConferenceProvider>
          }
        >
          <Route path="conference-basic-details" element={<ConferenceBasicDetails />} />
          <Route path="conference-additional-details" element={<ConferenceAdditionalDetails />} />
          <Route path="conference-time-details" element={<ConferenceTimeDetails />} />
          <Route path="conference-venue-details" element={<ConferenceVenueDetails />} />
          <Route path="conference-image-details" element={<ConferenceImageDetails />} />
          <Route path="conference-speaker-details" element={<ConferenceSpeakerDetails />} />
          <Route path="conference-sponser-details" element={<ConferenceSponserDetails />} />
          <Route path="conference-partner-details" element={<ConferencePartnerDetails />} />
          <Route path="conference-delegate-leads" element={<ConferenceDelegateLeads />}
          />
          <Route path="conference-speaker-leads" element={<ConferenceSpeakerLeads />} />
          <Route path="conference-sponsor-leads" element={<ConferenceSponsorLeads />} />
          <Route path="conference-partner-leads" element={<ConferencePartnerLeads />} />
           
          <Route path="conference-session-agenda" element={<ConferenceSessionAgenda />} />
          <Route path="conference-speaker-custom-form" element={<CustomSpeakerFormConference />} />
          <Route path="conference-deligate-custom-form" element={<CustomDeligateFormConference />} />
          <Route path="conference-sponsor-custom-form" element={<CustomSponsorFormConference />} />
          <Route path="conference-partner-custom-form" element={<CustomPartnerFormConference />} />

<Route path="conference-dashboard-stat" element={<ConferenceDashboardStat />} />
<Route path="conference-web-analytics" element={<ConferenceAnalyticReport />} />
<Route path="conference-rating" element={<ConferenceRating />} />
<Route path="conference-followers" element={<ConferenceFollowers />} />
<Route path="conference-request-query" element={<ConferenceRequestQuery />} />
 

        </Route>





        {/* Workshop dashboard wrapped with WorkshopProvider */}
        <Route path="/workshop-dashboard/:workshop_unique_id" element={ <WorkshopProvider> 
          <WorkshopDashboard />
            </WorkshopProvider>
          }
        >
          <Route path="workshop-basic-details" element={<WorkshopBasicDetails />} />  
          <Route path="workshop-additional-details" element={<WorkshopAdditionalDetails />} />  
          
        </Route>



        
      </Routes>
    </Router>
  );
}

export default App;
