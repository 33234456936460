import React, { useState, useEffect } from 'react';
import { format, startOfMonth, endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval, isSameDay, isSameMonth  } from 'date-fns';
import axios from 'axios';
import LogoutBtn from '../component/LogoutBtn';
import { baseurl, imageUrl } from "../component/Baseurl";
import { ChevronLeft, ChevronRight } from "react-feather";

// Calendar Component
const Calendar = () => {
  const orgId = localStorage.getItem("orgId");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [loadingExhibitions, setLoadingExhibitions] = useState(false);
  const [loadingConferences, setLoadingConferences] = useState(false);
  const [errorExhibitions, setErrorExhibitions] = useState('');
  const [selectedEvent, setSelectedEvent] = useState(null); // Track selected event details

  // Fetch exhibitions and conferences
  useEffect(() => {
    const fetchExhibitions = async () => {
      try {
        setLoadingExhibitions(true);
        const eventResponse = await axios.get(`${baseurl}AllExhibitions/${orgId}`);
        setEvents(eventResponse.data.exhibitions || []);
      } catch (err) {
        setErrorExhibitions(err.message || "Failed to fetch exhibitions");
      } finally {
        setLoadingExhibitions(false);
      }
    };

    const fetchConferences = async () => {
      try {
        setLoadingConferences(true);
        const conferenceResponse = await axios.get(`${baseurl}AllConferences/${orgId}`);
        // Handle conferences if needed
      } catch (err) {
        // Handle errors for conferences if needed
      } finally {
        setLoadingConferences(false);
      }
    };

    fetchExhibitions();
    fetchConferences();
  }, [orgId]);

  // Get the start and end dates of the current month
  const startDate = startOfMonth(currentDate);
  const endDate = endOfMonth(currentDate);

  // Get the start and end of the week for the displayed month
  const startWeek = startOfWeek(startDate);
  const endWeek = endOfWeek(endDate);

  // Get all days in the current month
  const daysInMonth = eachDayOfInterval({ start: startWeek, end: endWeek });

  const handlePrevMonth = () => {
    setCurrentDate((prev) => new Date(prev.setMonth(prev.getMonth() - 1)));
  };

  const handleNextMonth = () => {
    setCurrentDate((prev) => new Date(prev.setMonth(prev.getMonth() + 1)));
  };

  // Function to check if an event exists for a given date
  const isEventOnDate = (date) => {
    return events.some(event => isSameDay(new Date(event.exhibition_start_date), date));
  };

  // Function to display the next upcoming event
  const getNextEvent = () => {
    const upcomingEvent = events.find(event => new Date(event.exhibition_start_date) > new Date());
    return upcomingEvent || null;
  };

  const nextEvent = getNextEvent();

  // Function to handle date click
  const handleDateClick = (date) => {
    const clickedEvent = events.find(event => isSameDay(new Date(event.exhibition_start_date), date));
    if (clickedEvent) {
      setSelectedEvent(clickedEvent); // Set the event details for the selected date
    }
  };

  return (
    <>
      <div>
        <div className="d-flex tp-at align-items-center justify-content-between sticky-top">
          <div className="tp-h-tl">My Event Calendar</div>
          <div>
            <LogoutBtn />
          </div>
        </div>

        <div className="text-start">
          <h1 className="mt-3">Event Calander</h1>
        </div>

        <div className="container mt-4">
          <div className="row">
            <div className=" ">
              <div className="row g-0 mt-2 ro1">
                {/* Left side with upcoming event details */}
                <div className="col-md-4 bg11 py-3 px-2">
  {selectedEvent || nextEvent ? (
    <div>
      <div className="upc1 text-white"><strong>Upcoming Event</strong></div>
      <div className="upc2">
        {format(new Date((selectedEvent || nextEvent).exhibition_start_date), 'd')}
        <span className="upc3">{format(new Date((selectedEvent || nextEvent).exhibition_start_date), 'MMM, yyyy')}</span>
      </div>

      <div className="upc4">
        {format(new Date((selectedEvent || nextEvent).exhibition_start_date), 'EEEE')}
      </div>

      <div className="upc5">{(selectedEvent || nextEvent).exhibition_name}</div>

      <div className="upc6">Start Date : {format(new Date((selectedEvent || nextEvent).exhibition_start_date), 'MMMM dd, yyyy')}</div>
      <div className="upc6">End Date : {format(new Date((selectedEvent || nextEvent).exhibition_end_date), 'MMMM dd, yyyy')}</div>
    </div>
  ) : (
    <div>No upcoming events.</div>
  )}
</div>


                {/* Right side with calendar */}
                <div className="col-md-8">
                  <div className="calendar-container">
                    {/* Header with navigation */}
                    <div className="calendar-header">
                      <button className="clanderbtn" onClick={handlePrevMonth}><ChevronLeft className="text-dark" size={20}/></button>
                      <h2 className="upc1 text-white">{format(currentDate, 'MMMM yyyy')}</h2>
                      <button className="clanderbtn" onClick={handleNextMonth}><ChevronRight className="text-dark" size={20}/></button>
                    </div>

                    {/* Days of the week */}
                    <div className="calendar-weekdays">
                      {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
                        <div key={day} className="calendar-weekday">
                          {day}
                        </div>
                      ))}
                    </div>

                    {/* Days in the month */}
                    <div className="calendar-days">
                      {daysInMonth.map((date) => {
                        const isCurrentMonthAndYear = isSameMonth(date, currentDate) && date.getFullYear() === currentDate.getFullYear();
                        
                        return (
                          <div
  key={date}
  className={`calendar-day ${isEventOnDate(date) ? 'event-day' : ''} ${isSameDay(date, currentDate) ? 'current-day' : ''}`}
  style={
    isEventOnDate(date)
      ? { backgroundColor: '#2ecc70', color: 'white' }
      : isSameDay(date, currentDate) && isSameMonth(date, currentDate) // Only highlight the current day in the current month
      ? { backgroundColor: 'white', color: '#ff7976' }
      : {}
  }
  onClick={() => handleDateClick(date)} // Handle click on date
>
  {format(date, 'd')}
</div>

                        );
                      })}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
