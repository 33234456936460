// src/Organiser/PrePayment.js
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Link, Navigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { baseurl } from "../component/Baseurl";
import PayButton from "./PayButton";
import logo from "../component/img/logo.png";
import secure from "../component/img/secure.jpg";
import PackDetail from "./PageComp/PackDetail";

const PackageDetail = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Fetch and decrypt parameters from URL
  const decrypt = (data) => atob(data); // Example: base64 decoding

  const currency = queryParams.get("currency")
    ? decrypt(queryParams.get("currency"))
    : "INR";
  const packageId = queryParams.get("packageId")
    ? decrypt(queryParams.get("packageId"))
    : null;
  const amount = queryParams.get("amount")
    ? decrypt(queryParams.get("amount"))
    : null;
  const packageName = queryParams.get("packageName")
    ? decrypt(queryParams.get("packageName"))
    : null;
  const billingCycle = queryParams.get("billingCycle")
    ? decrypt(queryParams.get("billingCycle"))
    : "monthly"; // default to monthly

  useEffect(() => {
    const checkAuthentication = () => {
      const randomToken = localStorage.getItem("random_token");
      const organiserId = localStorage.getItem("organiser_id");
      setIsAuthenticated(randomToken && organiserId ? true : false);
    };
    checkAuthentication();
  }, []);

  useEffect(() => {
    const fetchPackageDetails = async () => {
      try {
        const response = await axios.get(`${baseurl}/fetch-package-details`);
        const foundPackage = response.data.data.find(
          (pkg) => pkg.packageId === packageId
        );
        setSelectedPackage(foundPackage);
      } catch (error) {
        console.error("Error fetching package details:", error);
      }
    };
    fetchPackageDetails();
  }, [packageId]);

  if (isAuthenticated === false) {
    // return <Navigate to="/Login" />;
  }
  const cleanedAmount =
    currency === "INR"
      ? amount.replace(/[^\d]/g, "")
      : amount.replace(/[^\d]/g, "");
  return (
    <>
      <ToastContainer />

      <div className="container-dash p-0 position-relative">
        <div className="sticky-top bg-white apal">
          <div className=" container py-1 d-flex justify-content-between align-items-center ">
            <div>
              <img
                src={logo}
                className="img-fluid logo"
                alt="Evventoz logo"
                title="Evventoz Logo"
              />
            </div>

            <div className="">
              <img
                src={secure}
                className="img-fluid logo"
                alt="Evventoz logo"
                title="Evventoz Logo"
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div className="container my-2">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <div className="card shadow-lg rounded nwbg-card bg-light">
                    <h2 class="text-center mb-3 heading-h2 mt-2 ">
                      <span class="price9">Complete Your Purchase</span>
                    </h2>

                    <div className="px-4">

                      
                      <div className="row mx-4 g-3">
                        <div className="col-md-6 ">
                          <div className="border p-2 rounded bg-white ">
                          <PackDetail  packageId={packageId} packageName={packageName} />
                          </div>
                          
                        </div>

                        <div className="col-md-6">
                          <div className="text-start mb-3 border p-2 rounded bg-white">

                          <h1 className="mb-4">Package Summary :</h1>


            <div className="d-flex justify-content-between total1">
              <div>Subtotal</div>
              <div> 
                
                {currency === '"INR"'
                                ? `₹${cleanedAmount}`
                                : `$${cleanedAmount}`}</div>
            </div>

            <div className="d-flex justify-content-between total1">
              <div>Tax/GST</div>
              <div>Included</div>
            </div>


<hr className="my-3"/>

            <div className="d-flex justify-content-between  total">
              <div>Total</div>
              <div>{currency === '"INR"'
                                ? `₹${cleanedAmount}`
                                : `$${cleanedAmount}`}</div>
            </div>

           
            <div className="text-center my-3">
                      <PayButton
                        packageId={packageId}
                        currency={currency}
                        amount={cleanedAmount}
                        billingCycle={billingCycle}
                        packageName={packageName}
                      />
                    </div>
                           
                           
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Checkout button */}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageDetail;
