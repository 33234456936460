import React, { useEffect, useState } from "react"; 
import { Link } from "react-router-dom";
import axios from "axios";
import { baseurl } from "../component/Baseurl";  
import { Edit, Trash2, CornerUpRight, Star } from "react-feather";


const Packages = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [packages, setPackages] = useState([]);
  const [currency, setCurrency] = useState("INR"); // Default currency
  const [billingCycle, setBillingCycle] = useState("monthly"); // Default billing cycle

  useEffect(() => {
    const checkAuthentication = () => {
      const randomToken = localStorage.getItem("random_token");
      const organiserId = localStorage.getItem("organiser_id");

      if (randomToken && organiserId) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  useEffect(() => {
    // Fetch package details from API
    const fetchPackageDetails = async () => {
      try {
        const response = await axios.get(`${baseurl}/fetch-package-details`);
        setPackages(response.data.data);
      } catch (error) {
        console.error("Error fetching package details:", error);
      }
    };

    fetchPackageDetails();
  }, []);

  const handleBillingCycleChange = (event) => {
    setBillingCycle(event.target.value);
  };

  // Define the gradient classes based on index
  const gradientClasses = [
    "bg-gradient-black",
    "bg-gradient-black",
    "bg-gradient-premium",
    "bg-gradient-primary",
    "bg-gradient-black",
  ];

  // Function to encrypt data (placeholder function)
  const encryptData = (data) => {
    return btoa(JSON.stringify(data)); // Example: simple base64 encoding
  };

  return (
    <>
      <div className="container-fluid p-0 position-relative">
        <div className="background-overlay"></div>

        <div className="d-flex justify-content-center align-items-center">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <div className="container-fluid my-3">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card shadow-lg rounded nwbg-card">
                    <h2 className="text-center mb-3 heading-h2 mt-2 ">
                      <span className="price9">Our Pricing</span>
                    </h2>

                    <div className="text-center text-muted">
                      Choose the package that suits you.
                    </div>
                    <hr/>

                    {/* Currency and Billing Cycle Switcher */}
                    <div className="px-4">
                      <div className="row bg-gradient-black p-2 justify-content-end rounded">
                        {/* Toggle Button for Currency */}
                        <div className="col-md-6">
                          <h1>Select Currency</h1>
                          <div className="toggle-currency">
                            <button
                              className={`billing-btn ${
                                currency === "INR" ? "active" : ""
                              }`}
                              onClick={() => setCurrency("INR")}
                            >
                              &#8377;{" "}INR
                            </button>
                            <button
                              className={`billing-btn ${
                                currency === "USD" ? "active" : ""
                              }`}
                              onClick={() => setCurrency("USD")}
                            >
                             &#x24;{" "}USD
                            </button>
                          </div>
                        </div>
                        {/* Billing Cycle */}
                        {/* Billing Cycle Toggle */}
<div className="col-md-6">
  <div className="text-end">
  <h1>Select Billing Cycle</h1>
  </div>
  
  <div className="toggle-billing">
    <button
      className={`billing-btn ${
        billingCycle === "monthly" ? "active" : ""
      }`}
      onClick={() => setBillingCycle("monthly")}
    >
      Monthly
    </button>
    <button
      className={`billing-btn ${
        billingCycle === "quarterly" ? "active" : ""
      }`}
      onClick={() => setBillingCycle("quarterly")}
    >
      Quarterly
    </button>
    <button
      className={`billing-btn ${
        billingCycle === "half-yearly" ? "active" : ""
      }`}
      onClick={() => setBillingCycle("half-yearly")}
    >
      Half-Yearly
    </button>
    <button
      className={`billing-btn ${
        billingCycle === "yearly" ? "active" : ""
      }`}
      onClick={() => setBillingCycle("yearly")}
    >
      Yearly
    </button>
  </div>
</div>

                      </div>
                    </div>

                    {/* Define Packages */}
                    <div className="row g-2 p-2 m-0 justify-content-center ">
                      {packages.map((pkg, index) => {
                        const amount =
                          currency === "INR"
                            ? billingCycle === "monthly"
                              ? pkg.packagePriceInr
                              : billingCycle === "quarterly"
                              ? pkg.packageQuarterlyInr
                              : billingCycle === "half-yearly"
                              ? pkg.packageHalfyearInr
                              : pkg.packageYearInr
                            : billingCycle === "monthly"
                            ? pkg.packagePriceUsd
                            : billingCycle === "quarterly"
                            ? pkg.packageQuarterlyUsd
                            : billingCycle === "half-yearly"
                            ? pkg.packageHalfyearUsd
                            : pkg.packageYearUsd;
                      
                        const offamt =
                          currency === "INR"
                            ? billingCycle === "monthly"
                              ? pkg.offamtInr
                              : billingCycle === "quarterly"
                              ? pkg.offamtQuarterlyInr
                              : billingCycle === "half-yearly"
                              ? pkg.offamtHalfyearInr
                              : pkg.offamtYearInr
                            : billingCycle === "monthly"
                            ? pkg.offamtUsd
                            : billingCycle === "quarterly"
                            ? pkg.offamtQuarterlyUsd
                            : billingCycle === "half-yearly"
                              ? pkg.offamtHalfyearUsd
                              : pkg.offamtYearUsd;
                      
                        const totalamt =
                          currency === "INR"
                            ? billingCycle === "monthly"
                              ? pkg.totalamtInr
                              : billingCycle === "quarterly"
                              ? pkg.totalamtQuarterlyInr
                              : billingCycle === "half-yearly"
                              ? pkg.totalamtHalfyearInr
                              : pkg.totalamtYearInr
                            : billingCycle === "monthly"
                            ? pkg.totalamtUsd
                            : billingCycle === "quarterly"
                              ? pkg.totalamtQuarterlyUsd
                              : billingCycle === "half-yearly"
                              ? pkg.totalamtHalfyearUsd
                              : pkg.totalamtYearUsd;
                      
                        return (
                          <div key={pkg.packageId} className="col-md-3">
                            <div className="crd3 okal1">
                              <div className={`${gradientClasses[index % gradientClasses.length]} packagedesign1 p-2`}>
                                <div className="text-center price1">
                                  {pkg.packageName} <br />
                                  <div className="price8">
                                    {currency === "INR" ? <>₹{amount}</> : <>${amount}</>}
                                  </div>
                      
                                  <div className="mt-2">
                                  <div className="strktou">
                                      {currency === "INR" ? <>₹{totalamt}</> : <>${totalamt}</>}
                                      
                                    </div>
                      
                                    <div className="ofamt">
                                      
                                      ({currency === "INR" ? <>₹{offamt}</> : <>${offamt}</>} Off)
                                    </div>
                                   
                                  </div>
                                </div>
                                <div className="text-center">
                                  {pkg.packageItems.map((item) => (
                                    <div key={item.id} className="txt8">
                                      {item.package_list_item}
                                      <hr className="my-2" />
                                    </div>
                                  ))}
                                </div>
                                <div className="text-center price1 mt-2">
                                  <Link
                                    to={{
                                      pathname: "/package-detail",
                                      search: `?currency=${encodeURIComponent(encryptData(currency))}&billingCycle=${encodeURIComponent(encryptData(billingCycle))}&packageId=${encryptData(pkg.packageId)}&amount=${encodeURIComponent(encryptData(amount))}&packageName=${encodeURIComponent(encryptData(pkg.packageName))}`,
                                    }}
                                    className="btnn4"
                                  >
                                    Buy Now
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Packages;
