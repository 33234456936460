import React from "react";
import { Link, useLocation } from "react-router-dom"; 
import { Search, LogOut as UsersIcon } from "react-feather"; 
import { useExhibition } from "../../context/ExhibitionContext"; 

const ExhibitionCommunicationNavbar = () => {
    const { exhibitionId } = useExhibition();
    const location = useLocation();  

    // Function to check if the current path is the same as the given path
    const isActive = (path) => location.pathname === path;

    return (
        <div className="fixedtp30 mt-3">
            {/* Search Box */}
            <div className="chaintop">
                <span>
                    <Link 
                        to={`/exhibition-dashboard/${exhibitionId}/exhibition-ratings`} 
                        className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/exhibition-ratings`) ? "active1" : ""}`}
                    >
                        Rating & Reviews
                    </Link>
                </span>
                <span>
                    <Link 
                        to={`/exhibition-dashboard/${exhibitionId}/exhibition-followers`} 
                        className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/exhibition-followers`) ? "active1" : ""}`}
                    >
                        Event Followers
                    </Link>
                </span>


                <span>
                    <Link 
                        to={`/exhibition-dashboard/${exhibitionId}/exhibition-request-query`} 
                        className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/exhibition-request-query`) ? "active1" : ""}`}
                    >
                        Guest Request/Query
                    </Link>
                </span>


                 
                 
            </div>
        </div>
    );
};

export default ExhibitionCommunicationNavbar;
