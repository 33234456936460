import React, { useState, useEffect } from "react";
import axios from "axios"; // Import axios
import { baseurl } from "../component/Baseurl"; // Import baseurl
import GoToDash from "../component/utils/GoToDash";
import { PlusSquare } from "react-feather";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../component/img/logo.png";

const MyAccount = () => {
  const orgId = localStorage.getItem("orgId"); // Get orgId from local storage
  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    mobile: "",
    designation: "",
    companyName: "",
    // companyAbout: "", 
    city: "",
    country: "",
    countryCode: "+91", // Default country code
    pincode: "",
  }); // Form data state

  const navigate = useNavigate();

  // Fetch organiser data by ID when the component mounts
  useEffect(() => {
    const fetchOrganiserData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/getOrganiserById/${orgId}`
        );
        const organiser = response.data.organiser;

        if (organiser) {
          setFormData({
            email: organiser.org_email || "",
            fullName: organiser.name || "",
            mobile: organiser.phone || "",
            designation: organiser.designation || "",
            companyName: organiser.company_name || "",
            // companyAbout: organiser.company_about || "",
            city: organiser.city || "",
            country: organiser.country || "",
            countryCode: organiser.country_code || "+91", // Default to +91 if not provided
            pincode: organiser.pincode || "",
          });
        }
      } catch (error) {
        console.error("Error fetching organiser data:", error);
        alert("An error occurred while fetching the profile.");
      }
    };

    fetchOrganiserData();
  }, [orgId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Prevent non-numeric characters in mobile input
    if (name === "mobile") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
      setFormData({
        ...formData,
        [name]: numericValue.slice(0, 10), // Ensure maximum 10 digits
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Prepare the data to send in the request (without the file field)
    const requestData = {
      id: orgId,
      ...formData, // Spread the form data
    };
  
    console.log("Data being sent to the server:", requestData);
  
    try {
      const response = await axios.post(
        `${baseurl}/updateOrganiserAccount`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json", // Changed to application/json for non-file data
          },
        }
      );
  
      if (response.status === 200) {
        console.log("Form submitted successfully:", response.data);
        Swal.fire({
          title: "Success!",
          text: "Profile updated successfully!",
          icon: "success",
          confirmButtonText: "Go Back",
        }).then(() => {
          navigate(-1);  
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Failed to update profile. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while updating the profile.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  

  return (
    <div className="">
      <div className="d-flex align-items-center justify-content-between sticky-top px-4 py-3 bg-1 bg-white">
        <img src={logo} className="img-fluid logo" /> 
        <div>{/* <GoToDash /> */}</div>
      </div>

      <div className="container mt-4 mb-5">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="crd1">
              <h1 className="text-capitalize d-flex align-items-center">
                <span>
                  <PlusSquare className="ic3" size={50} />
                </span>
                <span>My Profile</span>
              </h1>

              <hr />

              <div className="p-3">
                <form onSubmit={handleSubmit}>
                  <div className="row g-2">
                    <div className="col-md-6">
                      <label className="form-label">
                        Full Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="fullName"
                        className="form-control"
                        placeholder="Enter Full Name"
                        value={formData.fullName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="col-md-6">
                      <label className="form-label">
                        Email<span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        disabled
                      />
                    </div>

                    <div className="col-md-2">
                      <label className="form-label">
                        Country Code
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        name="countryCode"
                        className="form-control"
                        value={formData.countryCode}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="+91">+91</option>
                        <option value="+1">+1</option>
                        <option value="+44">+44</option>
                        <option value="+61">+61</option>
                        <option value="+81">+81</option>
                      </select>
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">
                        Mobile <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number" // Change this from 'number' to 'text' for more control over input
                        name="mobile"
                        className="form-control"
                        placeholder="Enter Mobile Number"
                        value={formData.mobile}
                        onChange={handleInputChange}
                        maxLength={10} // Restrict the input to 10 digits
                        pattern="\d{10}" // Allow only 10 digits (numeric values)
                        required
                      />
                    </div>

                    <div className="col-md-6">
                      <label className="form-label">
                        Designation<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="designation"
                        className="form-control"
                        placeholder="Enter Designation"
                        value={formData.designation}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Company Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="companyName"
                        className="form-control"
                        placeholder="Enter Company Name"
                        value={formData.companyName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    {/* <div className="col-md-12">
                      <label className="form-label">
                        About Company<span className="text-danger">*</span>
                      </label>
                      <textarea name="companyAbout" className="form-control"
                        placeholder="Write About Company / Organisation"
                        value={formData.companyAbout} rows={4}
                        onChange={handleInputChange} required
                        ></textarea>
                       
                    </div> */}

                    <div className="col-md-3">
                      <label className="form-label">
                        City<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="city"
                        className="form-control"
                        placeholder="Enter City"
                        value={formData.city}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="col-md-3">
                      <label className="form-label">
                        Country<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="country"
                        className="form-control"
                        placeholder="Enter Country"
                        value={formData.country}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="col-md-3">
                      <label className="form-label">
                        Pincode<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="pincode"
                        className="form-control"
                        placeholder="Enter Pincode"
                        value={formData.pincode}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="col-md-12 text-end">
                      <button type="submit" className="btnn">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default MyAccount;
