import React, { useEffect, useState } from "react";
import { useExhibition } from "../../context/ExhibitionContext";
import axios from "axios"; // Import axios for API calls
import { baseurl } from "../../component/Baseurl";
import { Home, ExternalLink } from "react-feather";
import { useLocation } from "react-router-dom"; // Import useLocation to access current path

const ExhiibitionBreadcrum = () => {
  const { exhibitionId } = useExhibition(); // Get exhibition ID from context
  const [exhibitionName, setExhibitionName] = useState("");
  const [exhibitionSlug, setExhibitionSlug] = useState("");
  const location = useLocation(); // Get the current location/path

  useEffect(() => {
    // Run the API call only if exhibitionId is available and it hasn't been called before
    if (exhibitionId) {
      const fetchExhibitionName = async () => {
        try {
          const response = await axios.get(
            `${baseurl}getExhibitionName/${exhibitionId}`
          );
          if (response.data.success) {
            setExhibitionName(response.data.data.exhibition_name);  
            setExhibitionSlug(response.data.data.exhibition_slug);  
          } else {
            console.error("Failed to fetch exhibition name");
          }
        } catch (error) {
          console.error("Error fetching exhibition name:", error);
        }
      };

      fetchExhibitionName();
    }
  }, [exhibitionId]);  

  // Function to get breadcrumb label based on the path
  const getBreadcrumbLabel = (path) => {
    // console.log("Current path:", path);  
    
    if (path.startsWith("/exhibition-dashboard/")) {
      // Extract the exhibition ID and page name dynamically
      const pathSegments = path.split('/');
      const pageName = pathSegments[pathSegments.length - 1]; // This will capture the last segment, e.g., "basic-details"
      
      switch (pageName) {
        case "basic-details":
          return "Basic Details";
        case "additional-details":
          return "Additional Details";
        case "time-details":
          return "Time Details";
        case "image-files":
          return "Upload Files";
        case "exhibitor-details":
          return "Exhibitor Details";
        case "sponser-details":
          return "Sponser Details";
        case "partner-details":
          return "Partner Details";
        case "conference-details":
          return "Conference Details";
        case "speaker-details":
          return "Speaker Details";

          case "exhibition-conference-session-agenda":
          return "Session & Agenda";


        default:
          return "Dashboard"; // Default if the path does not match any known segments
      }
    }

    return "Dashboard"; // Default label for the root or undefined paths
  };

  return (
    <div className="d-flex py-2 brdcrm justify-content-between align-items-center">
      <div className="brdcrmttl">{exhibitionName || "Loading..."}{" "}
        <span className="brdcrm "> 
          <span><a href={`https://visitor.evventoz.com/exhibition/${exhibitionSlug}`} target="_blank">Preview{" "}<ExternalLink size={15}/></a></span>
          </span></div>
      <div className="text-muted">
        <span>Dashboard</span> <span>/</span>{" "}
        <span>{getBreadcrumbLabel(location.pathname)}</span>
      </div>
    </div>
  );
};

export default ExhiibitionBreadcrum;
