import React, { useState, useEffect } from "react";
import { useWorkshop } from "../../context/WorkshopContext";  
import WorkshopContentNavbar from "../utils/WorkshopContentNavbar";
import axios from "axios"; // Import axios
import { baseurl } from "../../component/Baseurl"; // Import the base URL
import { Spinner } from "react-bootstrap"; // Import Bootstrap spinner
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom"; 
import { PlusSquare, Trash2, ArrowLeft, ArrowRight } from "react-feather"; 

const WorkshopAdditionalDetails = () => {
  const { workshopId } = useWorkshop(); // Use context to fetch the workshopId
  const navigate = useNavigate();

  const [highlights, setHighlights] = useState([]); // Initialize as an empty array
  const [workshopDescription, setWorkshopDescription] = useState(""); // Initialize as an empty string
  const [workshopWebsite, setWorkshopWebsite] = useState(""); // Initialize as an empty string
  const [formErrors, setFormErrors] = useState({}); // State for form validation errors
  const [websiteChecked, setWebsiteChecked] = useState(false); // State to track checkbox checked status
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [websiteRequired, setWebsiteRequired] = useState(true); // State for website field requirement

  useEffect(() => {
    const fetchWorkshopDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseurl}workshopAdditionalDetails/${workshopId}`);
        const { workshop_description, workshop_website, highlights } = response.data;

        setWorkshopDescription(workshop_description || ""); // Default to empty string
        setWorkshopWebsite(workshop_website || ""); // Default to empty string
        setHighlights(highlights || []); // Default to empty array
      } catch (error) {
        console.error("Error fetching workshop details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (workshopId) {
      fetchWorkshopDetails();
    }
  }, [workshopId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    const errors = {};

    // Validate website if required
    if (!websiteChecked && !workshopWebsite.trim()) {
      errors.workshopWebsite = "Workshop Website is required.";
    }

    // Validate description if not empty
    if (!workshopDescription.trim()) {
      errors.workshopDescription = "Workshop Description is required.";
    }

    // Set errors only if necessary
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Prepare form data
    const formData = {
      workshopWebsite,
      workshopDescription,
      highlights: highlights.filter((item) => item.trim() !== ""), // Exclude empty highlights
      workshopId,
    };

    try {
      const response = await axios.post(`${baseurl}Insert_Workshop_Highlights`, formData);
    
      if (response.status === 200) {
        // Navigate directly upon successful submission
        navigate(`/workshop-dashboard/${workshopId}/workshop-time-details`);
      }
    } catch (error) {
      console.error("Error sending data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error submitting the details. Please try again.",
      });
    } 
  };

  const handleAddHighlight = () => setHighlights([...highlights, ""]);

  const handleHighlightChange = (e, index) => {
    const updatedHighlights = [...highlights];
    updatedHighlights[index] = e.target.value;
    setHighlights(updatedHighlights);
  };

  const handleDeleteHighlight = (index) => {
    const updatedHighlights = highlights.filter((_, i) => i !== index);
    setHighlights(updatedHighlights);
  };

  const handleWorkshopDescriptionChange = (e) => setWorkshopDescription(e.target.value);

  const handleWorkshopWebsiteChange = (e) => setWorkshopWebsite(e.target.value);

  const handleWebsiteCheckboxChange = () => {
    setWebsiteChecked(!websiteChecked);
    setWorkshopWebsite(""); // Clear website input when checkbox is checked
    setWebsiteRequired(!websiteChecked); // Toggle the required field based on checkbox status
  };

  return (
    <div>
      <WorkshopContentNavbar />  
      <h1 className="mt-3">Workshop Additional Details</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-3">
            <form onSubmit={handleSubmit}>
              {/* Loading spinner */}
              {loading && (
                <div className="d-flex justify-content-center my-3">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
              
              <div className="row g-2">
                {/* Workshop Website Input */}
                <div className="col-md-12">
                  <label className="form-label">Workshop Website</label>
                  <input
                    type="url"
                    name="workshopWebsite"
                    className="form-control"
                    placeholder="Enter Workshop Website"
                    value={workshopWebsite}
                    onChange={handleWorkshopWebsiteChange}
                    required={websiteRequired}
                    disabled={websiteChecked}
                  />
                  {formErrors.workshopWebsite && (
                    <small className="text-danger">{formErrors.workshopWebsite}</small>
                  )}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={websiteChecked}
                      id="flexCheckDefault"
                      onChange={handleWebsiteCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Don't Have Website
                    </label>
                  </div>
                </div>

                {/* Workshop Description Textarea */}
                <div className="col-md-12">
                  <label className="form-label">
                    Workshop Description <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Workshop Description"
                    rows="6"
                    name="workshopDescription"
                    value={workshopDescription}
                    onChange={handleWorkshopDescriptionChange}
                    maxLength="1000"
                    required
                  ></textarea>
                  {formErrors.workshopDescription && (
                    <small className="text-danger">{formErrors.workshopDescription}</small>
                  )}
                  <div className="text-end">
                    <small>{workshopDescription.length} / 1000</small>
                  </div>
                </div>

                {/* Workshop Highlights Input Fields */}
                <div className="col-md-12">
                  <label className="form-label">
                    Workshop Highlights
                  </label>
                  <div>
                    {highlights.map((highlight, index) => (
                      <div key={index} className="d-flex mb-2">
                        <input
                          type="text"
                          name={`highlight-${index}`}
                          className="form-control w-75"
                          placeholder="Enter Workshop Highlight"
                          value={highlight}
                          onChange={(e) => handleHighlightChange(e, index)}
                          maxLength="250"
                          required
                        />
                        <span
                          className="addrowdiv mx-2"
                          onClick={() => handleDeleteHighlight(index)}
                        >
                          <Trash2 className="ic text-dark" /> Delete
                        </span>
                        <div className="text-end">
                          <small>{highlight.length} / 250</small>
                        </div>
                      </div>
                    ))}
                    <div className="mt-4">
                      <span
                        className="addrowdiv mx-2 mt-4"
                        onClick={handleAddHighlight}
                      >
                        <PlusSquare className="ic text-dark" /> Add Highlight
                      </span>
                    </div>
                  </div>
                  {formErrors.highlights && (
                    <small className="text-danger">{formErrors.highlights}</small>
                  )}
                </div>
              </div> 

              <div className="col-md-12 mt-5 d-flex justify-content-between align-items-center">
                <span className="">
                  <Link to={`/workshop-dashboard/${workshopId}/workshop-basic-details`} className="btnn mybtn">
                    <ArrowLeft className="ic text-white" />
                    Back
                  </Link>
                </span>
                <button type="submit" className="btnn mt-0">
                  Next <ArrowRight className="ic text-white" />
                </button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopAdditionalDetails;
