import React, { useState, useEffect } from "react";
import { useExhibition } from "../../context/ExhibitionContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { baseurl, imageUrl } from "../../component/Baseurl";
import { Link } from "react-router-dom";
import { ArrowRight, ArrowLeft } from "react-feather";
import ExhiibitionBreadcrum from "../ExhibitionComponent/ExhibitionBreadcrum";

const ExhibitionBrochure = () => {
  const { exhibitionId } = useExhibition();
  const navigate = useNavigate();
  const org_id = localStorage.getItem("orgId");

  const [brochure, setBrochure] = useState(null);
  const [fetchedBrochure, setFetchedBrochure] = useState(null);

  // Fetch event brochure details
  useEffect(() => {
    const fetchEventBrochureDetails = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/getEventBrochureDetails/${exhibitionId}`
        );


        console.log("response",response);
        if (response.data) {
          const { eventbrochure } = response.data;
          setFetchedBrochure(eventbrochure);
        }
      } catch (error) {
        console.error("Error fetching event brochure details:", error);
      }
    };

    if (exhibitionId) {
      fetchEventBrochureDetails();
    }
  }, [exhibitionId]);

  // Handle file drop for brochure
  const onDropBrochure = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (file.size > 5000000) {
        toast.error("Brochure file size exceeds 5MB!");
      } else {
        setBrochure(file);
      }
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!brochure) {
      toast.error("Please upload the brochure.");
      return;
    }

    const formData = new FormData();
    formData.append("brochure", brochure);
    formData.append("exhibitionId", exhibitionId);
    formData.append("orgId", org_id);

    try {
      const response = await axios.post(
        `${baseurl}/upload-brochure`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        navigate(`/exhibition-dashboard/${exhibitionId}/exhibitor-details`);
      } else {
        toast.error("Failed to upload brochure.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropBrochure,
    accept: ".pdf",
    maxSize: 5000000,
  });

  return (
    <div>
      <ExhiibitionBreadcrum />
      <ExhibitionContentNavbar />
      <h1 className="mt-3">Upload Exhibition Brochure</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-4 shadow-lg rounded-lg">
            <form onSubmit={handleSubmit}>
              <div className="row g-3">
                <div className="col-md-12">
                  <label className="form-label">
                    Exhibition Brochure{" "}
                    <small className="udrl">
                      [Max Size: 5MB, File Type: PDF ONLY]
                    </small>
                    <span className="text-danger">*</span>
                  </label>
                  <div
                    {...getRootProps()}
                    className="dropzone-container border border-primary p-3 rounded-lg"
                  >
                    <input {...getInputProps()} />
                    <p className="text-center text-muted">
                      Drag & drop your brochure here, or click to select
                    </p>
                    {brochure && (
                      <div className="mt-2 text-center">
                        <p>Brochure uploaded: {brochure.name}</p>
                      </div>
                    )}
                    {fetchedBrochure && !brochure && (
                      <div className="mt-2 text-center">
                        <a
                          href={`${imageUrl}brochureFiles/${fetchedBrochure}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Brochure
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-5 d-flex justify-content-between align-items-center">
                <Link
                  to={`/exhibition-dashboard/${exhibitionId}/image-files`}
                  className="btnn mybtn"
                >
                  <ArrowLeft className="ic text-white" />
                  Back
                </Link>
                <button type="submit" className="btnn mt-0">
                  Next <ArrowRight className="ic text-white" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ExhibitionBrochure;
